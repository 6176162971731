import React from "react";
import { isMobile } from "react-device-detect";
import { useHeaderInfoLinks, useHeaderNavMobLinks } from "../api/apiHooks";
import Header from "../../../components/organisms/Header";

const HeaderMobile = () => {
  const { data } = useHeaderNavMobLinks();
  return <Header links={data?.links} linksLoaded={!!data?.links} />;
};

const HeaderDesktop = () => {
  const { data } = useHeaderInfoLinks();
  return <Header links={data?.links} linksLoaded={!!data?.links} />;
};

const HeaderWrap = () => (isMobile ? <HeaderMobile /> : <HeaderDesktop />);

export default HeaderWrap;
