import React from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { isExternalURL } from "../../../system/helpers/urlHelper";

export interface BtnProps {
  className?: string;
  icon?: React.ReactNode;
  size?: string;
  loading?: boolean;
  disabled?: boolean;
  round?: boolean;
  block?: boolean;
  selected?: boolean;
  type?: string;
  label?: string | number;
  onClick?: () => any;
  linkTo?: string;
  hidden?: boolean;
  btnType?: "submit" | "reset" | "button";
}

const Btn: React.FC<BtnProps> = ({
  className,
  loading,
  disabled,
  block,
  type,
  icon,
  size,
  label,
  onClick,
  linkTo,
  btnType,
  selected,
  round,
  hidden,
}) => {
  const history = useHistory();
  const clickHandler = () => {
    if (linkTo) {
      if (isExternalURL(linkTo)) {
        window.location.assign(linkTo);
      } else {
        history.push(linkTo);
      }
    }
    onClick && onClick();
  };

  const btnLoader = (
    <svg className="btn__loader" viewBox="0 0 50 50">
      <circle
        className="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        stroke-width="5"
      />
    </svg>
  );

  if (hidden) {
    return null;
  }

  return (
    <button
      className={classNames(
        "btn",
        `btn-${size}`,
        `btn-${type}`,
        { "btn-block": block },
        { "is-loading": loading },
        selected && `btn-${type}--selected`,
        round && `btn-${type}--round`,
        className
      )}
      disabled={disabled}
      onClick={clickHandler}
      type={btnType}
    >
      {icon && <span className={"btn__icon"}>{icon}</span>}
      {label && <span className={"btn__label"}>{label}</span>}
      {loading && btnLoader}
    </button>
  );
};

Btn.defaultProps = {
  type: "default",
  size: "md",
  btnType: "button",
  selected: false,
};

export default Btn;
