import React, { useRef } from "react";
import classNames from "classnames";
import CustomSlider, { SliderProps } from "../../molecules/CustomSlider";
import SliderControl from "../../atoms/SliderControl";

export type GameSliderProps = {
  className?: string;
  title?: string;
  desc?: string;
  withoutPanel?: boolean;
  controlLabel?: string;
  controlLabelLink?: string;
  sliderProps: SliderProps;
};

const GameSlider = React.forwardRef<HTMLDivElement, GameSliderProps>(
  (
    {
      className,
      title,
      desc,
      withoutPanel,
      controlLabel,
      controlLabelLink,
      sliderProps,
    },
    ref
  ) => {
    const sliderRef = useRef(null);

    return (
      <div ref={ref} className={classNames("game-slider", className)}>
        {withoutPanel || (
          <div className={"game-slider__top-panel"}>
            <div className={"game-slider__header"}>
              {title && <div className={"game-slider__title"}>{title}</div>}
              {desc && <div className={"game-slider__desc"}>{desc}</div>}
            </div>
            <div className={"game-slider__control"}>
              <SliderControl
                btnPrevOnClick={() => {
                  sliderRef.current.slickPrev();
                }}
                btnNextOnClick={() => {
                  sliderRef.current.slickNext();
                }}
                label={controlLabel}
                labelLink={controlLabelLink}
                showControlsBtn={false}
              />
            </div>
          </div>
        )}
        <CustomSlider forwardedRef={sliderRef} settings={sliderProps.settings}>
          {sliderProps.children}
        </CustomSlider>
      </div>
    );
  }
);

GameSlider.defaultProps = {
  withoutPanel: false,
};

export default GameSlider;
