import React, { useContext } from "react";
import Btn from "../../../components/atoms/Btn";
import LazyImg from "../../../components/atoms/LazyImg";
import BgImg from "../../../images/bg/pwa-promo-modal-bg.svg";
import BgImgGrp from "../../../images/bg/pwa-promo-modal-bg-grp.png";
import InstallAppContext from "./InstallAppContext";
import { useTrans } from "../../../system/translations/hooks";

const PwaPromoModal: React.FC = () => {
  const { _t } = useTrans();
  const { getApp } = useContext(InstallAppContext);

  return (
    <div className={"pwa-promo-modal-body"}>
      <LazyImg className={"pwa-promo-modal-body__bg-img"} imgSrc={BgImg} />
      <LazyImg
        className={"pwa-promo-modal-body__bg-img-grp"}
        imgSrc={BgImgGrp}
      />
      <div className={"pwa-promo-modal-body__text"}>
        <span className={"pwa-promo-modal-body__label"}>
          {_t("pwa_promo_modal_label")}
        </span>
        <h3 className={"pwa-promo-modal-body__title"}>
          {_t("pwa_promo_modal_title")}
        </h3>
        <p className={"pwa-promo-modal-body__desc"}>
          {_t("pwa_promo_modal_desc")}
        </p>
        <Btn
          className={"pwa-promo-modal-body__btn"}
          type={"primary"}
          label={_t("pwa_promo_modal_btn")}
          onClick={getApp}
        />
      </div>
    </div>
  );
};

export default PwaPromoModal;
