import { useCallback } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { apiErrors } from "../../../system/helpers/apiErrorHelper";
import { updateTfaMethodsAction } from "../actions";
import {
  regRequestKeys,
  loginRequestKeys,
  tfaRequestKeys,
  USER_CLAIMS_LIST,
  USER_INFO_URL,
  userInfoData,
  LOGIN_URL,
  REG_URL,
  LOGOUT_URL,
  CHANGE_PASSWORD_URL,
  KYC_URL,
  PLAY_HISTORY_URL,
  PAYMENT_HISTORY_URL,
  betDetailsPayloadData,
  BET_DETAILS_URL,
  PENDING_WITHDRAWALS_URL,
  CANCEL_WITHDRAWAL_URL,
  bonusesPayloadData,
  BONUSES_URL,
  SEND_BONUS_CODE_URL,
  cashInLoyaltyData,
  SEND_CASH_IN_LOYALTY_URL,
  FILL_OUT_PROFILE_URL,
  VERIFICATION_PHONE_URL,
  RESEND_PHONE_VERIFICATION_CODE_URL,
  FORGOT_PASSWORD_URL,
  FORGOT_PASSWORD_FORM_URL,
  RESET_PASSWORD_URL,
  RESET_PASSWORD_FORM_URL,
  PROFILE_EDIT_URL,
  PROFILE_EDIT_FORM_URL,
  UPDATE_BALANCE_URL,
  TFA_FORM_URL,
  TFA_URL,
  TFA_PROCESS_URL,
  TFA_TOKEN_URL,
  TFA_RESEND_CODE_URL,
  USER_CLAIMS_URL,
  USER_CLAIMS_UPDATE_URL,
} from "../constants";
import { addToast } from "../../../system/helpers/toast";
import { removeCookie, setCookie } from "../../../system/helpers/cookie";
import { history } from "../../../system/store";
import { useHideModal, useShowModal } from "../../modals/hooks";
import {
  loginModal,
  tfaProcessModal,
  fillOutProfileModalSuccess,
  depositModal,
  forgotPasswordModalSuccess,
  changePasswordModalSuccess,
  resetPasswordSuccessModal,
  afterLoginModal,
} from "../../modals";
import {
  dataLayerLoginSuccess,
  dataLayerRegSuccess,
} from "../../../system/dataLayer";
import { isExternalURL } from "../../../system/helpers/urlHelper";
import { getLanguage } from "../../../system/translations/helper";
import config from "../../../system/config";
import { useLocation, useRouteMatch } from "react-router-dom";
import { APP_ROUTES } from "../../../system/router/constants";
import { ApplicationState } from "../../../system/reducers";
import { useTrans } from "../../../system/translations/hooks";
import { generatePath } from "react-router";
import { useApiRequest } from "../../../system/hooks/useApiRequest";
import { isMobile } from "react-device-detect";

export const useOnSuccessLogin = () => {
  const [apiRequest] = useApiRequest();
  const showAfterLoginModal = useShowModal(afterLoginModal);
  const hideModal = useHideModal();
  const matchRoute = useRouteMatch<{ lang: string }>(APP_ROUTES.signIn);
  const lang = getLanguage();

  return useCallback(
    (
      data: any,
      redirectTo: string = "",
      isHiddenAfterLoginModal: boolean = false
    ) => {
      const redirect = redirectTo || history.location.pathname;
      setCookie("isLoggedIn", true);
      apiRequest({
        type: "USER_INFO",
        request: {
          url: generatePath(USER_INFO_URL, { lang }),
          method: "GET",
        },
      });
      if (isExternalURL(redirect)) {
        window.location.assign(redirect);
      } else {
        if (matchRoute) {
          history.push(`/${lang}/`);
        } else {
          history.push(redirect);
        }
      }
      if (isHiddenAfterLoginModal) {
        hideModal();
      } else {
        showAfterLoginModal();
      }

      dataLayerLoginSuccess(data.user.username);
    },
    [lang, hideModal, showAfterLoginModal, matchRoute]
  );
};

export const useLogin = (
  redirectTo: string = "",
  isHiddenAfterLoginModal: boolean = false
) => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();
  const onSuccessLogin = useOnSuccessLogin();
  const showtTfaProcessModal = useShowModal(tfaProcessModal);

  return useMutation(
    (data: any): Promise<userInfoData> =>
      apiRequest({
        type: "LOGIN",
        request: {
          url: generatePath(LOGIN_URL, { lang }),
          method: "POST",
          data: data,
          headers: {
            "content-type":
              "multipart/form-data; boundary=----WebKitFormBoundaryNBmJ1kyVqlEfHqJ8",
          },
        },
      }),
    {
      onSuccess: (data) => {
        if (!!data.twoFactorAuth) {
          showtTfaProcessModal();
        } else {
          onSuccessLogin(data, redirectTo, isHiddenAfterLoginModal);
        }
      },
      onError: (err: any) => {
        let message = "useLogin error message!";
        try {
          apiErrors(err, "notify");
        } catch (e) {
          addToast(message, {
            type: "error",
          });
        }
      },
    }
  );
};

export const useReg = (redirectTo: string = "") => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();
  const { mutate: loginHandler } = useLogin(redirectTo);

  return useMutation(
    (data: any): Promise<any> =>
      apiRequest({
        type: "REGISTRATION",
        request: {
          url: generatePath(REG_URL, { lang }),
          method: "POST",
          data: data,
          headers: {
            "content-type":
              "multipart/form-data; boundary=----WebKitFormBoundaryNBmJ1kyVqlEfHqJ8",
          },
        },
      }),
    {
      onSuccess: (data, requestData) => {
        window.parent && window.parent.postMessage("userRegistered", "*");
        const formData = new FormData();
        formData.append(
          loginRequestKeys.username,
          requestData.get(regRequestKeys.username)
        );
        formData.append(
          loginRequestKeys.password,
          requestData.get(regRequestKeys.password)
        );
        dataLayerRegSuccess();
        loginHandler(formData);
      },
      onError: (err: any) => {
        let message = "Registration undefined error";
        try {
          if (err?.error.data.systemMessages.hasOwnProperty("errors")) {
            message = err?.error.data.systemMessages?.errors[0].message;
          } else if (
            err?.error.data.systemMessages.hasOwnProperty("registration")
          ) {
            message = err?.error.data.systemMessages?.registration[0].message;
          } else {
            try {
              throw new Error("reg error");
            } catch (e) {
              throw e;
            }
          }
          addToast(message, {
            type: "error",
          });
        } catch (e) {
          addToast(message, {
            type: "error",
          });
        }
      },
    }
  );
};

export const useLogOut = () => {
  const [apiRequest] = useApiRequest();
  return useMutation((redirectTo: string = config.redirectAfterLogout) => {
    removeCookie("isLoggedIn");
    const lang = getLanguage();
    const redirect = redirectTo || history.location.pathname;
    history.push(redirect);

    return apiRequest({
      type: "LOGOUT",
      request: {
        url: generatePath(LOGOUT_URL, { lang }),
        method: "GET",
      },
    });
  });
};

export const useChangePassword = () => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();

  const hideModal = useHideModal();
  const showChangePasswordSuccessModal = useShowModal(
    changePasswordModalSuccess({
      handleClick: hideModal,
    })
  );

  return useMutation(
    (data: any): Promise<any> =>
      apiRequest({
        type: "CHANGE_PASSWORD",
        request: {
          url: generatePath(CHANGE_PASSWORD_URL, { lang }),
          method: "POST",
          data: data,
        },
      }),
    {
      onSuccess: () => showChangePasswordSuccessModal(),
      onError: (err: any) => apiErrors(err, "notify"),
    }
  );
};

export const useGetUserInfo = () => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();

  return useMutation(
    (): Promise<userInfoData> =>
      apiRequest({
        type: "USER_INFO",
        request: {
          url: generatePath(USER_INFO_URL, { lang }),
          method: "GET",
        },
      })
  );
};

export const useKyc = () => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();

  return useMutation(
    (data: any): Promise<any> =>
      apiRequest({
        type: "KYC",
        request: {
          url: generatePath(KYC_URL, { lang }),
          method: "POST",
          data: data,
          headers: {
            "content-type":
              "multipart/form-data; boundary=----WebKitFormBoundaryNBmJ1kyVqlEfHqJ8",
          },
        },
      }),
    {
      onSuccess: (data) => {
        try {
          const message = data?.systemMessages.accountVerification[0].message;
          addToast(message, {
            type: "success",
          });
        } catch (e) {}
      },
      onError: (err: any) => {
        try {
          apiErrors(err, "notify");
        } catch (e) {
          addToast("kyc some error", {
            type: "error",
          });
        }
      },
    }
  );
};

export const usePlayHistory = () => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();

  return useMutation(
    (data: any): Promise<any> =>
      apiRequest({
        type: "PLAY_HISTORY",
        request: {
          url: generatePath(PLAY_HISTORY_URL, { lang }),
          method: "POST",
          data: data,
        },
      }),
    {
      onError: (err: any) => {
        try {
          apiErrors(err, "notify");
        } catch (e) {
          addToast("Some error occurred", {
            type: "error",
          });
        }
      },
    }
  );
};

export const usePaymentHistory = () => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();

  return useMutation(
    (data: any): Promise<any> =>
      apiRequest({
        type: "PAYMENT_HISTORY",
        request: {
          url: generatePath(PAYMENT_HISTORY_URL, { lang }),
          method: "POST",
          data: data,
        },
      }),
    {
      onError: (err: any) => {
        try {
          apiErrors(err, "notify");
        } catch (e) {
          addToast("Some error occurred", {
            type: "error",
          });
        }
      },
    }
  );
};

export const usePaymentHistoryQuery = (data) => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();

  return useQuery(
    ["payment_history_list", lang],
    (): Promise<any> =>
      apiRequest({
        type: "PAYMENT_HISTORY",
        request: {
          url: generatePath(PAYMENT_HISTORY_URL, { lang }),
          method: "POST",
          data: data,
        },
      }),
    {
      onError: (err: any) => {
        try {
          apiErrors(err, "notify");
        } catch (e) {
          addToast("Some error occurred", {
            type: "error",
          });
        }
      },
    }
  );
};

export const useGetBetDetails = () => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();

  return useMutation(
    (data: any): Promise<betDetailsPayloadData> =>
      apiRequest({
        type: "BET_DETAILS",
        request: {
          url: generatePath(BET_DETAILS_URL, {
            platformCode: data.platformCode,
            gameTranId: data.gameTranId,
            lang,
          }),
          withPrefix: false,
          method: "GET",
        },
      }),
    {
      onError: (err: any) => {
        try {
          apiErrors(err, "notify");
        } catch (e) {
          addToast("Some error occurred", {
            type: "error",
          });
        }
      },
    }
  );
};

export const usePendingWithdrawals = () => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();

  return useQuery(
    ["pending_withdrawals", lang],
    (): Promise<any> =>
      apiRequest({
        type: "PENDING_WITHDRAWALS",
        request: {
          url: generatePath(PENDING_WITHDRAWALS_URL, { lang }),
          method: "GET",
          notifyMessage: {
            error: "ERROR_PENDING_WITHDRAWALS",
          },
        },
      }),
    {
      staleTime: 0,
      onError: (err: any) => {
        try {
          apiErrors(err, "notify");
        } catch (e) {
          addToast("Some error occurred", {
            type: "error",
          });
        }
      },
    }
  );
};

export const useCancelWithdrawal = (historyFormRef) => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const lang = getLanguage();

  return useMutation(
    (data: any): Promise<any> =>
      apiRequest({
        type: "CANCEL_WITHDRAWAL",
        request: {
          url: generatePath(CANCEL_WITHDRAWAL_URL, { lang, paymentId: data }),
          method: "POST",
        },
      }),
    {
      onSuccess: (data) => {
        try {
          const message = data?.systemMessages.withdrawal[0].message;
          addToast(message, {
            type: "success",
          });
          historyFormRef?.current?.dispatchEvent(new Event("submit"));
          queryClient.invalidateQueries("pending_withdrawals");
        } catch (e) {}
      },
      onError: (err: any) => {
        try {
          apiErrors(err, "notify");
        } catch (e) {
          addToast("Some error occurred", {
            type: "error",
          });
        }
      },
    }
  );
};

export const useGetKycForm = () => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();

  return useQuery(
    ["kyc_form", lang],
    (): Promise<any> =>
      apiRequest({
        type: "GET_KYC_FORM",
        request: {
          url: generatePath(KYC_URL, { lang }),
          method: "GET",
        },
      }),
    {
      onError: (err: any) => {
        try {
          apiErrors(err, "notify");
        } catch (e) {
          addToast("Some error occurred", {
            type: "error",
          });
        }
      },
    }
  );
};

export const useBonusList = () => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();

  return useQuery(
    ["bonus_list", lang],
    (): bonusesPayloadData =>
      apiRequest({
        type: "GET_BONUS_LIST",
        request: {
          url: generatePath(BONUSES_URL, { lang }),
          method: "GET",
        },
      }),
    {
      onError: (err: any) => {
        try {
          apiErrors(err, "notify");
        } catch (e) {
          addToast("Some error occurred", {
            type: "error",
          });
        }
      },
    }
  );
};

export const useBonusCode = () => {
  const { _t } = useTrans();
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();

  const message = _t("redeem_bonus_success");
  return useMutation(
    (data: any): Promise<any> =>
      apiRequest({
        type: "SEND_BONUS_CODE",
        request: {
          url: generatePath(SEND_BONUS_CODE_URL, { lang }),
          method: "POST",
          data: data,
        },
      }),
    {
      onSuccess: () => {
        addToast(message, {
          type: "success",
        });
      },
      onError: (err: any) => {
        try {
          apiErrors(err, "notify");
        } catch (e) {
          addToast("Some error occurred", {
            type: "error",
          });
        }
      },
    }
  );
};

export const useCashInLoyaltyPoints = () => {
  const hideModal = useHideModal();
  const { mutate: updateUserBalance } = useUpdateBalance();
  const { _t } = useTrans();
  const [apiRequest] = useApiRequest();
  const message = _t("loyalty_point_cash_in_successful");
  const lang = getLanguage();

  return useMutation(
    (data: any): Promise<cashInLoyaltyData> =>
      apiRequest({
        type: "SEND_CASH_IN_LOYALTY",
        request: {
          url: generatePath(SEND_CASH_IN_LOYALTY_URL, { lang }),
          method: "POST",
          data: data,
        },
      }),
    {
      onSuccess: (data) => {
        if (data?.status === "SUCCESS") {
          updateUserBalance();
          addToast(message, {
            type: "success",
          });
          hideModal();
        }
      },
      onError: (err: any) => {
        try {
          apiErrors(err, "notify");
        } catch (e) {
          addToast("Some error occurred", {
            type: "error",
          });
        }
      },
    }
  );
};

export const useFillOutProfile = () => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();
  const { mutate: getUserInfo } = useGetUserInfo();
  const showFillOutSuccessModal = useShowModal(
    fillOutProfileModalSuccess({
      handleClick: useShowModal(depositModal),
    })
  );

  return useMutation(
    (data: any): Promise<any> =>
      apiRequest({
        type: "SEND_PROFILE_DATA",
        request: {
          url: generatePath(FILL_OUT_PROFILE_URL, { lang }),
          method: "POST",
          data: data,
        },
      }),
    {
      onSuccess: () => {
        getUserInfo();
        showFillOutSuccessModal();
      },
      onError: (err: any) => {
        try {
          apiErrors(err, "notify");
        } catch (e) {
          addToast("Some error occurred", {
            type: "error",
          });
        }
      },
    }
  );
};

export const usePhoneVerify = () => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();

  return useMutation(
    (data: any): Promise<any> =>
      apiRequest({
        type: "SEND_PHONE_VERIFICATION",
        request: {
          url: generatePath(VERIFICATION_PHONE_URL, { lang }),
          method: "POST",
          data: data,
        },
      })
  );
};

export const useResendCodePhoneVerify = () => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();

  return useMutation(
    (data: any): Promise<any> =>
      apiRequest({
        type: "RESEND_PHONE_VERIFICATION_CODE",
        request: {
          url: generatePath(RESEND_PHONE_VERIFICATION_CODE_URL, { lang }),
          method: "POST",
          data: data,
        },
      })
  );
};

export const useForgotPassword = () => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();
  const hideModal = useHideModal();
  const handleClick = () => {
    history.push("/");
    hideModal();
  };
  const showSuccessModal = useShowModal(
    forgotPasswordModalSuccess({
      handleClick,
    })
  );

  return useMutation(
    (data: any): Promise<any> =>
      apiRequest({
        type: "FORGOT_PASSWORD",
        request: {
          url: generatePath(FORGOT_PASSWORD_URL, { lang }),
          method: "POST",
          data: data,
        },
      }),
    {
      onSuccess: () => {
        showSuccessModal();
      },
      onError: (err: any) => {
        try {
          apiErrors(err, "notify");
        } catch (e) {
          addToast("Some error occurred", {
            type: "error",
          });
        }
      },
    }
  );
};

export const useGetForgotPasswordForm = () => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();
  return useQuery(
    ["forgot_password_form", lang],
    (): Promise<any> =>
      apiRequest({
        type: "FORGOT_PASSWORD_FORM",
        request: {
          url: generatePath(FORGOT_PASSWORD_FORM_URL, { lang }),
          method: "GET",
        },
      })
  );
};

export const useResetPassword = () => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();
  const hideModal = useHideModal();
  const handleClick = () => {
    history.push("/");
    hideModal();
  };
  const showSuccessModal = useShowModal(
    resetPasswordSuccessModal({
      handleClick,
    })
  );

  return useMutation(
    (data: any): Promise<any> =>
      apiRequest({
        type: "RESET_PASSWORD",
        request: {
          url: generatePath(RESET_PASSWORD_URL, { lang }),
          method: "POST",
          data: data,
        },
      }),
    {
      onSuccess: () => showSuccessModal(),
      onError: (err: any) => {
        try {
          apiErrors(err, "notify");
        } catch (e) {
          addToast("Some error occurred", {
            type: "error",
          });
        }
      },
    }
  );
};

export const useGetResetPasswordForm = (email: string, resetCode: string) => {
  const [apiRequest] = useApiRequest();
  const hideModal = useHideModal();
  const lang = getLanguage();

  return useQuery(
    ["reset_password_form", lang],
    () =>
      apiRequest({
        type: "RESET_PASSWORD_FORM",
        request: {
          url: generatePath(RESET_PASSWORD_FORM_URL, {
            lang,
            email,
            resetCode,
          }),
          method: "GET",
        },
      }),
    {
      onSuccess: (data: any) => {
        if (data?.systemMessages?.errors?.length) {
          history.push("/");
          apiErrors({ error: { data } }, "notify");
          hideModal();
        }
      },
      onError: (err: any) => {
        try {
          apiErrors(err, "notify");
        } catch (e) {
          addToast("Some error occurred", {
            type: "error",
          });
        }
      },
    }
  );
};

export const useProfileEdit = (successMsg: string) => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();

  return useMutation(
    (data: any): Promise<any> =>
      apiRequest({
        type: "PROFILE_EDIT",
        request: {
          url: generatePath(PROFILE_EDIT_URL, { lang }),
          method: "POST",
          data: data,
        },
      }),
    {
      onSuccess: () => {
        addToast(successMsg, {
          type: "success",
        });
      },
      onError: (err: any) => {
        try {
          apiErrors(err, "notify");
        } catch (e) {
          addToast("editing some error", {
            type: "error",
          });
        }
      },
    }
  );
};

export const useGetProfileEditForm = () => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();

  return useQuery(
    ["profile_edit_form", lang],
    (): Promise<any> =>
      apiRequest({
        type: "PROFILE_EDIT_FORM",
        request: {
          url: generatePath(PROFILE_EDIT_FORM_URL, { lang }),
          method: "GET",
        },
      })
  );
};

export const useUpdateBalance = () => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();

  return useMutation(
    (): Promise<any> =>
      apiRequest({
        type: "UPDATE_BALANCE",
        request: {
          url: generatePath(UPDATE_BALANCE_URL, { lang }),
          method: "GET",
        },
      })
  );
};

export const useGetTfa = () => {
  const [apiRequest] = useApiRequest();
  const dispatch = useDispatch();
  const lang = getLanguage();

  return useMutation(
    (): Promise<any> =>
      apiRequest({
        type: "TFA_FORM",
        request: {
          url: generatePath(TFA_FORM_URL, { lang }),
          method: "GET",
        },
      }),
    {
      onSuccess: (data) => {
        const methods = data?._form?.methods;
        updateTfaMethodsAction({
          none: methods[0],
          sms: methods[1],
          email: methods[2],
        })(dispatch);
      },
    }
  );
};

export const useSetTfa = () => {
  const [apiRequest] = useApiRequest();
  const dispatch = useDispatch();
  const lang = getLanguage();

  return useMutation(
    (data: any): Promise<any> =>
      apiRequest({
        type: "SET_TFA",
        request: {
          url: generatePath(TFA_URL, { lang }),
          method: "POST",
          data: data,
        },
      }),
    {
      onSuccess: (data, requestData) => {
        const methods = requestData.getAll(tfaRequestKeys.methods);
        updateTfaMethodsAction({
          none: methods.includes("none"),
          sms: methods.includes("sms"),
          email: methods.includes("email"),
        })(dispatch);
      },
      onError: (err: any) => {
        try {
          apiErrors(err, "notify");
        } catch (e) {
          addToast("Some error occurred", {
            type: "error",
          });
        }
      },
    }
  );
};

export const useTfaProcess = (redirectTo: string = "") => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();
  const onSuccessLogin = useOnSuccessLogin();
  const showLoginModal = useShowModal(loginModal());

  return useMutation(
    (data: any): Promise<any> =>
      apiRequest({
        type: "TFA_PROCESS",
        request: {
          url: generatePath(TFA_PROCESS_URL, { lang }),
          method: "POST",
          data: data,
        },
      }),
    {
      onSuccess: (data) => {
        if (data.user) {
          onSuccessLogin(data, redirectTo);
        }
        if (data.twoFactorAuthFail) {
          showLoginModal();
        }
      },
      onError: (err: any) => {
        try {
          apiErrors(err, "notify");
        } catch (e) {
          addToast("Some error occurred", {
            type: "error",
          });
        }
      },
    }
  );
};

export const useTfaResendCode = () => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();

  return useMutation(
    (): Promise<any> =>
      apiRequest({
        type: "GET_TFA_TOKEN",
        request: {
          url: generatePath(TFA_TOKEN_URL, { lang }),
          method: "GET",
        },
      }),
    {
      onSuccess: (data) => {
        const formData = new FormData();
        formData.append("_token", data?._token);

        return apiRequest({
          type: "TFA_RESEND_CODE",
          request: {
            url: generatePath(TFA_RESEND_CODE_URL, { lang }),
            method: "POST",
            data: formData,
          },
        });
      },
      onError: (err: any) => {
        try {
          apiErrors(err, "notify");
        } catch (e) {
          addToast("Some error occurred", {
            type: "error",
          });
        }
      },
    }
  );
};

export const useGetUserClaims = () => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();
  const location = useLocation();

  const { sessionKey } = useSelector(
    (state: ApplicationState) => state.user.info.user,
    shallowEqual
  );

  return useQuery(
    [USER_CLAIMS_LIST, location, lang],
    (): Promise<any> =>
      apiRequest({
        type: "GET_USER_CLAIMS",
        request: {
          url: USER_CLAIMS_URL,
          method: "GET",
          headers: {
            "X-User-Outside": sessionKey,
            "X-Locale": lang,
            "X-Device": !isMobile ? "desktop" : "mobile",
          },
          withPrefix: false,
        },
      }),
    {
      onError: (err: any) => {
        try {
          apiErrors(err, "notify");
        } catch (e) {
          addToast("Some error occurred", {
            type: "error",
          });
        }
      },
      enabled: !!sessionKey,
    }
  );
};

export const useCreateUserClaim = () => {
  const queryClient = useQueryClient();
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();
  const { _t } = useTrans();

  const { sessionKey } = useSelector(
    (state: ApplicationState) => state.user.info.user,
    shallowEqual
  );

  const messSelectBonus = _t("bonus_request__select_bonus_notify");
  const messBonusRequested = _t("bonus_request__already_requested");

  return useMutation(
    (data: any): Promise<any> =>
      apiRequest({
        type: "CREATE_USER_CLAIM",
        request: {
          url: USER_CLAIMS_URL,
          method: "POST",
          headers: {
            "X-User-Outside": sessionKey,
            "X-Locale": lang,
            "X-Device": !isMobile ? "desktop" : "mobile",
          },
          withPrefix: false,
          data: data,
        },
      }),
    {
      onSuccess: () => queryClient.invalidateQueries(USER_CLAIMS_LIST),
      onError: (err: any) => {
        if (!!err?.error.data.errors?.subject[0]) {
          addToast(messSelectBonus, {
            type: "error",
          });
        }
        if (err?.error.data.message === "duplicate.exists") {
          addToast(messBonusRequested, {
            type: "error",
          });
        }
      },
    }
  );
};

export const useUpdateUserClaim = () => {
  const queryClient = useQueryClient();
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();

  const { sessionKey } = useSelector(
    (state: ApplicationState) => state.user.info.user,
    shallowEqual
  );

  return useMutation(
    (data: any): Promise<any> => {
      const { info, claimId } = data;
      return apiRequest({
        type: "UPDATE_USER_CLAIM",
        request: {
          url: generatePath(USER_CLAIMS_UPDATE_URL, { uuid: claimId }),
          method: "PUT",
          headers: {
            "X-User-Outside": sessionKey,
            "X-Locale": lang,
            "X-Device": !isMobile ? "desktop" : "mobile",
          },
          withPrefix: false,
          data: info,
        },
      });
    },
    {
      onSuccess: () => queryClient.invalidateQueries(USER_CLAIMS_LIST),
      onError: (err: any) => {
        try {
          apiErrors(err, "notify");
        } catch (e) {
          addToast("Some error occurred", {
            type: "error",
          });
        }
      },
    }
  );
};
