import React from "react";
import LazyImg from "../../../../components/atoms/LazyImg";
import MiniLogoImg from "../../../../images/logos/main-logo.svg";
import IosSharePointerImg from "../../../../images/icons/ios-share-pointer.svg";
import { ReactComponent as IosShareIcon } from "../../../../images/icons/ios-share.svg";
import { useTrans } from "../../../../system/translations/hooks";

const PwaAddToHomeModal: React.FC = () => {
  const { _t } = useTrans();

  return (
    <div className={"pwa-add-to-home-modal-body"}>
      <LazyImg
        imgSrc={MiniLogoImg}
        className={"pwa-add-to-home-modal-body__img"}
        alt={""}
      />
      <h3 className={"pwa-add-to-home-modal-body__title"}>
        {_t("get_app_installer")}
      </h3>
      <p className={"pwa-add-to-home-modal-body__desc"}>
        {_t("browser_install")}
      </p>
      <p
        className={"pwa-add-to-home-modal-body__text"}
        dangerouslySetInnerHTML={{
          __html: _t("how_add_to_home_{icon}", {
            icon: <IosShareIcon />,
          }),
        }}
      />
      <LazyImg
        imgSrc={IosSharePointerImg}
        className={"pwa-add-to-home-modal-body__pointer"}
        alt={""}
      />
    </div>
  );
};

export default PwaAddToHomeModal;
