import React from "react";
import MainLogoImage from "../../../images/logos/main-logo.svg";
import { useHistory } from "react-router-dom";

type MainLogoProps = {
  linkTo?: string;
  onClick?: () => void;
};

const MainLogo: React.FC<MainLogoProps> = ({ linkTo, onClick }) => {
  const history = useHistory();
  const imgClick = () => {
    linkTo && history.push(linkTo);
    onClick && onClick();
  };
  return (
    <img
      className={"main-logo"}
      src={MainLogoImage}
      alt="logo"
      onClick={imgClick}
    />
  );
};

export default MainLogo;
