import React, { useState } from "react";
import Loader from "../../../../components/atoms/Loader";

const ProcessModal = ({ url }) => {
  const [isLoadingIframe, setLoad] = useState(true);
  const onLoadIframe = () => {
    setLoad(false);
  };
  return (
    <>
      {isLoadingIframe && <Loader center overflow />}
      <iframe
        title={"process-frame"}
        src={url}
        width={"100%"}
        height={"100%"}
        onLoad={onLoadIframe}
      />
    </>
  );
};

export default ProcessModal;
