import React, { useContext, createContext, useMemo } from "react";
import defaultTranslations from "./translations";
import { modifyTrans } from "./helper";
import { InjectTransContextProps, TransType } from "./types";

export const TransContext = createContext<InjectTransContextProps | null>(null);

export const InjectTransContext: React.FC<InjectTransContextProps> = ({
  locale,
  messages,
  children,
}) =>
  useMemo(
    () => (
      <TransContext.Provider value={{ locale, messages }}>
        {children}
      </TransContext.Provider>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale]
  );

export const makeKey = (...keys: string[]) =>
  keys.reduce((array, key) => (key ? [...array, key] : array), []).join("_");

export const _t: TransType = (key = "", params = {}) => {
  const { messages = {} } = useContext(TransContext);
  const dictionary = Object.keys(messages).length
    ? messages
    : defaultTranslations;

  return dictionary[key] ? modifyTrans(dictionary[key], params) : `#${key}#`;
};
