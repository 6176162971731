import React from "react";
import classNames from "classnames";
import TextLink from "../../atoms/TextLink";
import { ModalParams } from "../../organisms/Modal";
import { useShowModal } from "../../../modules/modals/hooks";
import { useTrans } from "../../../system/translations/hooks";

type OpenModalLinkProps = {
  classname?: string;
  label: string;
  modal: ModalParams;
  position?: string;
  text?: string;
  onClick?: () => void;
};

const OpenModalLink: React.FC<OpenModalLinkProps> = ({
  classname,
  label,
  modal,
  position,
  text,
  onClick,
}) => {
  const { _t } = useTrans();

  const showModal = useShowModal(modal);
  const handleClick = () => {
    showModal();
    onClick && onClick();
  };

  return (
    <div
      className={classNames(
        "modal-link",
        position && `modal-link--${position}`,
        classname
      )}
    >
      {text && <span>{_t(text) + "\u00A0"}</span>}
      <TextLink onClick={handleClick} label={_t(label)} />
    </div>
  );
};

export default OpenModalLink;
