import React, { Suspense, lazy } from "react";
import { useReg } from "../../api/apiHooks";
import { regRequestKeys, DEFAULT_CURRENCY } from "../../constants";
import { shallowEqual, useSelector } from "react-redux";
import { ApplicationState } from "../../../../system/reducers";
import { apiErrors } from "../../../../system/helpers/apiErrorHelper";
import Loader from "../../../../components/atoms/Loader";
import { dataLayerRegCreateAccount } from "../../../../system/dataLayer";
const RegForm = lazy(() => import("../forms/RegForm"));

type RegModalProps = {
  redirectTo?: string;
  staticPlaceholder?: boolean;
};

const RegModal: React.FC<RegModalProps> = ({
  redirectTo,
  staticPlaceholder,
}) => {
  const { isLoading, error, mutate: regHandler } = useReg(redirectTo);
  const responseError = apiErrors(error);
  const { info } = useSelector(
    (state: ApplicationState) => state.user,
    shallowEqual
  );
  const { currencyInfo, countryInfo } = useSelector(
    (state: ApplicationState) => state.general,
    shallowEqual
  );

  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append(regRequestKeys.username, values.username);
    formData.append(regRequestKeys.phone, values.phone.replace(/[^\d]/g, ""));
    formData.append(regRequestKeys.email, values.email);
    formData.append(regRequestKeys.password, values.password);
    formData.append(regRequestKeys.country, countryInfo.iso2Code);
    formData.append(regRequestKeys.currency, DEFAULT_CURRENCY);
    formData.append(regRequestKeys.receiveEmail, "1");
    formData.append(
      regRequestKeys.agreeTermAndConditions,
      `${+values.agreeTermAndConditions}`
    );
    formData.append(regRequestKeys.recaptcha, values.recaptcha);
    regHandler(formData);
    dataLayerRegCreateAccount();
  };

  return (
    <Suspense fallback={<Loader center fullHeight minHeight={200} />}>
      <RegForm
        defaultCurrency={currencyInfo.isoCode}
        responseError={responseError}
        onSubmit={onSubmit}
        isLoading={isLoading || info.isLoading}
        staticPlaceholder={staticPlaceholder}
      />
    </Suspense>
  );
};

export default RegModal;
