import BTCCryptoCurrency from "../../../images/icons/cryptocurrencies/Bitcoin.svg";
import LTCCryptoCurrency from "../../../images/icons/cryptocurrencies/Litecoin.svg";
import ETHCryptoCurrency from "../../../images/icons/cryptocurrencies/Ether.svg";
import XRPCryptoCurrency from "../../../images/icons/cryptocurrencies/XRP.svg";
import BCHCryptoCurrency from "../../../images/icons/cryptocurrencies/Bitcoin Cash.svg";
import USDTCryptoCurrency from "../../../images/icons/cryptocurrencies/USDT.svg";
import React from "react";

export const listCrypto = [
  { title: "BTC", img: BTCCryptoCurrency },
  { title: "LTC", img: LTCCryptoCurrency },
  { title: "ETH", img: ETHCryptoCurrency },
  { title: "XRP", img: XRPCryptoCurrency },
  { title: "BCH", img: BCHCryptoCurrency },
  { title: "USDT", img: USDTCryptoCurrency },
];
