import { useEffect, useState } from "react";
import { useShowModal } from "../modals/hooks";
import {
  disablePopupBlockerModal,
  paymentCompletedModal,
  processIframeModal,
} from "../modals";
import { isPopupBlocked } from "../../system/helpers/popupBlockerChecker";
import { isExternalURL } from "../../system/helpers/urlHelper";
import {
  methodsWithCompletedModal,
  OPEN_IN_IFRAME,
  CashboxVersion,
} from "./constants";
import { isDeposit } from "./helper";
import {
  dataLayerDepositResponse,
  dataLayerWithdrawalResponse,
} from "../../system/dataLayer";

type SelectCashboxMethodType = {
  selectedMethodId: string;
  selectMethod: (id) => any;
};

export const useSelectCashboxMethod = (defaultMethod = "") => {
  const [selectedMethodId, selectMethod] = useState("");

  return { selectedMethodId, selectMethod };
};

const iframeModalStrategy = (method) => OPEN_IN_IFRAME.includes(method);

export const useOpenProcess = (
  processResponceData,
  method,
  completedModalTexts,
  onBackClick,
  successfulModal,
  paymentType
) => {
  const confirmation = processResponceData?.confirmation;
  const { modalWithParams } = useShowModal({}, true);
  const successModal = useShowModal(successfulModal);
  const completedModal = (arg) => paymentCompletedModal(arg);
  const processModal = (arg) => processIframeModal(arg);
  const isWebApp = localStorage.getItem("isWebApp");
  useEffect(() => {
    if (!processResponceData) {
      return;
    }
    if (!!confirmation?.continueUrl) {
      if (iframeModalStrategy(method.paymentMethod)) {
        modalWithParams(processModal({ url: confirmation?.continueUrl }));
      } else {
        if (isWebApp) {
          window.location.assign(confirmation?.continueUrl);
          return;
        }
        const newWindow = window.open(confirmation?.continueUrl);
        if (isPopupBlocked(newWindow)) {
          const link = isExternalURL(confirmation?.continueUrl)
            ? confirmation?.continueUrl
            : window.location.origin + confirmation?.continueUrl;
          modalWithParams(disablePopupBlockerModal({ link }));
        }
      }
      onBackClick(method);
    } else if (!!confirmation?.paymentId) {
      if (methodsWithCompletedModal.includes(method?.paymentMethod)) {
        modalWithParams(
          completedModal({
            paymentId: confirmation?.paymentId,
            ...completedModalTexts,
          })
        );
      } else {
        successModal();
      }
      onBackClick(method);
    }
    if (isDeposit(paymentType)) {
      dataLayerDepositResponse();
    } else {
      dataLayerWithdrawalResponse();
    }
  }, [processResponceData]);
};

export const useCashboxVersion = (): CashboxVersion => {
  return CashboxVersion.V1;
};
