import React from "react";
import ModalBanner from "../../../components/organisms/Modal/ModalBanner";
import { useTrans } from "../../../system/translations/hooks";
import { useGetBanner } from "../api/apiHooks";

const SignUpModalBanner: React.FC = () => {
  const { _t } = useTrans();
  const { isLoading, data } = useGetBanner("sign_up_img");
  const banner = data?.banners[0];

  return (
    <ModalBanner
      className={"sign-up-modal-banner"}
      img={banner?.imageUrl}
      title={_t("reg_banner_title")}
      desc={_t("reg_banner_description")}
      linkTo={banner?.link}
      btnText={banner?.buttonName}
      isLoading={isLoading}
    />
  );
};

export default SignUpModalBanner;
