import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLoggedIn } from "./userHelper";
import { getLanguage } from "../translations/helper";
import { isPublicDomains } from "./urlHelper";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const lang = getLanguage() || "en";
  const publicDomains = isPublicDomains();
  const checkProfile = rest.profile && !isLoggedIn();
  const checkRestriction = publicDomains ? !checkProfile : isLoggedIn();
  const redirectTo = publicDomains ? "/#login" : `/${lang}/sign-in`;
  return (
    <Route
      {...rest}
      render={(props) =>
        checkRestriction ? (
          <Component {...props} />
        ) : (
          <Redirect to={redirectTo} />
        )
      }
    />
  );
};

export default PrivateRoute;
