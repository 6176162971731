import { generatePath } from "react-router";
import { getLanguage } from "../../system/translations/helper";
import { APP_ROUTES } from "../../system/router/constants";
import { PLAY_FOR_FUN, PLAY_FOR_REAL } from "../game/constants";
import { gameItem } from "./constants";

export const gameLink = (item: gameItem) => {
  const lang = getLanguage();
  const slug = item?.v3?.game?.slug;
  const platform =
    item?.v3?.game?.platform?.gameLaunchPlatformCode ||
    "404page?gameProvider=error";

  return {
    real: item?.v3?.links?.real
      ? generatePath(APP_ROUTES.gamePage, {
          gameSlug: slug,
          playType: PLAY_FOR_REAL,
          gameProvider: platform,
          lang,
        })
      : "",
    fun: item?.v3?.links?.fun
      ? generatePath(APP_ROUTES.gamePage, {
          gameSlug: slug,
          playType: PLAY_FOR_FUN,
          gameProvider: platform,
          lang,
        })
      : "",
  };
};
