import SportIcon from "../../../images/icons/navbar/sport.svg";
import SportIconActive from "../../../images/icons/navbar/sport-active.svg";
import InPlayIcon from "../../../images/icons/navbar/play.svg";
import InPlayIconActive from "../../../images/icons/navbar/play-active.svg";
import CasinoIcon from "../../../images/icons/navbar/casino.svg";
import CasinoIconActive from "../../../images/icons/navbar/casino-active.svg";
import Promo from "../../../images/icons/navbar/bonus.svg";
import PromoActive from "../../../images/icons/navbar/bonus-active.svg";
import LiveCasino from "../../../images/icons/navbar/live-casino.svg";
import LiveCasinoActive from "../../../images/icons/navbar/live-casino-active.svg";
import VirtualSports from "../../../images/icons/navbar/virtual.svg";
import VirtualSportsActive from "../../../images/icons/navbar/virtual-active.svg";
import Euro2020Icon from "../../../images/icons/categories/euro2020.svg";
import { path } from "../../../system/helpers/pathHelper";
import React from "react";

export const navbarItems = [
  { name: "header_sport", url: path("/sportsbook") },
  { name: "header_in_play", url: path("/in-play") },
  /* { name: "Betgames", url: "/betgames" }, */
  { name: "header_casino", url: path("/casino") },
  { name: "header_live_casino", url: path("/casino/live-casino") },
  { name: "header_promotions", url: path("/bonuses") },
];

export const footerNavItemsMobile = {
  menuSports: { icon: SportIcon, iconActive: SportIconActive },
  liveBets: { icon: InPlayIcon, iconActive: InPlayIconActive },
  menuCasino: { icon: CasinoIcon, iconActive: CasinoIconActive },
  Bonuses: { icon: Promo, iconActive: PromoActive },
  ["live-casino"]: { icon: LiveCasino, iconActive: LiveCasinoActive },
  virtualSports: { icon: VirtualSports, iconActive: VirtualSportsActive },
};

export const mobNavbarDepositItem = {
  classLink: "deposit",
  desktop: false,
  mobile: true,
  name: "",
  target: "_self",
  url: "/profile/wallet/deposit",
  icon: (
    <div className={"nav-item__deposit-icon"}>
      <div className={"nav-item__deposit-icon-inner"}></div>
    </div>
  ),
  iconActive: (
    <div className={"nav-item__deposit-icon"}>
      <div className={"nav-item__deposit-icon-inner"}></div>
    </div>
  ),
};

export const headerNavItems = {
  euro2020: { icon: Euro2020Icon },
};
