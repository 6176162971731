export const casinoSliderSettings = {
  slidesToShow: 1,
  slidesToScroll: 4,
  centerMode: false,
  infinite: true,
  arrows: true,
  variableWidth: true,
  swipeToSlide: true,
  responsive: [
    {
      breakpoint: 1300,
      settings: {
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1100,
      settings: {
        slidesToScroll: 2,
      },
    },
  ],
};

export const casinoMostPopularSliderSettings = {
  slidesToShow: 1,
  slidesToScroll: 3,
  centerMode: false,
  infinite: true,
  arrows: true,
  variableWidth: true,
  swipeToSlide: true,
  responsive: [
    {
      breakpoint: 1300,
      settings: {
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1100,
      settings: {
        slidesToScroll: 2,
      },
    },
  ],
};
