import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import {
  GamesFeedbackAction,
  GAMES_FEEDBACK_URL,
  GAMES_FEEDBACK,
} from "../constants";
import { useApiRequest } from "../../../../system/hooks/useApiRequest";
import { jsonReplacer } from "../helpers";
import { generatePath } from "react-router-dom";
import { getLanguage } from "../../../../system/translations/helper";

export const useSendGamesFeedback = () => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();
  return useMutation((data: GamesFeedbackAction[]) =>
    apiRequest({
      type: GAMES_FEEDBACK,
      request: {
        url: generatePath(GAMES_FEEDBACK_URL, { lang }),
        method: "POST",
        data: JSON.stringify(data, jsonReplacer),
        headers: {
          "content-type": "application/json",
        },
      },
    })
  );
};
