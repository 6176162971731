import React, { Suspense, lazy } from "react";
import { useRouteMatch } from "react-router-dom";
import MainTemplate from "../../templates/Main";
import Loader from "../../components/atoms/Loader";
import { _t, makeKey } from "../../system/translations/InjectTransContext";
import { Helmet } from "react-helmet";
import { pageTitleKey } from "../../system/helpers/urlHelper";
const SportTemplate = lazy(() => import("./SportTemplate"));

const SportPage = () => {
  const matchRoute = useRouteMatch<{ slug: string }>("/:lang/:slug");
  const matchSlug = matchRoute?.params?.slug;
  const slug = matchSlug !== "sportsbook" && matchSlug;

  return (
    <>
      <Helmet>
        <title>{_t(makeKey("sport_page_title", slug, pageTitleKey))}</title>
        <meta
          name="description"
          content={_t(makeKey("sport_page_description", slug))}
        />
      </Helmet>
      <MainTemplate
        prefixClass={"main-template--sport"}
        seoPanelProps={{ keySlug: makeKey("sport", slug) }}
        withoutFooter
        withoutSeoPanel
      >
        <Suspense fallback={<Loader fullHeight minHeight={1000} />}>
          <SportTemplate />
        </Suspense>
      </MainTemplate>
    </>
  );
};

export default SportPage;
