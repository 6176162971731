import React, { ReactElement } from "react";
import classNames from "classnames";
import TextLink from "../TextLink";

export type sliderControlProps = {
  className?: string;
  label?: string | ReactElement;
  labelLink?: string;
  showControlsBtn?: boolean;
  btnNextOnClick: () => void;
  btnPrevOnClick: () => void;
};

const SliderControl: React.FC<sliderControlProps> = (props) => {
  const {
    className,
    label,
    labelLink,
    btnNextOnClick,
    btnPrevOnClick,
    showControlsBtn,
  } = props;

  return (
    <div className={classNames("slider-control", className)}>
      <div className="slider-control__label">
        {typeof label === "object" && label !== null ? (
          <div className="slider-control__label-content">{label}</div>
        ) : (
          <TextLink linkTo={labelLink} label={`${label}`} />
        )}
      </div>
      <div className="slider-control__button-wrap">
        {showControlsBtn && (
          <>
            <button
              className="slider-control__button-prev"
              onClick={btnPrevOnClick}
            />
            <button
              className="slider-control__button-next"
              onClick={btnNextOnClick}
            />
          </>
        )}
      </div>
    </div>
  );
};

SliderControl.defaultProps = {
  showControlsBtn: true,
};

export default SliderControl;
