import {
  ADD_ACTION_TO_STACK,
  CLEAR_ACTION_STACK,
  ZingReducerType,
  ZingReducerAction,
} from "./constants";

const initialState: ZingReducerType = {
  actionStack: [],
};

const reducer = (
  state: ZingReducerType = initialState,
  action: ZingReducerAction
) => {
  switch (action.type) {
    case ADD_ACTION_TO_STACK:
      return {
        ...state,
        actionStack: [...state.actionStack, action.payload],
      };
    case CLEAR_ACTION_STACK:
      return {
        ...state,
        actionStack: initialState.actionStack,
      };
    default:
      return state;
  }
};

export default reducer;
