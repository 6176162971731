import React, { useMemo } from "react";
import SportLinks from "../../../components/molecules/SportLinks";
import { useGetSportLinks } from "../api/apiHooks";
import { path } from "../../../system/helpers/pathHelper";

const SportLinksContainer: React.FC = () => {
  const { data, isLoading } = useGetSportLinks();
  const links = useMemo(
    () =>
      data?.links
        ?.sort((a, b) => a.position - b.position)
        ?.map((item) => ({
          url: path(item.url),
          name: item.name,
          img: item.imageName,
        })),
    [data]
  );

  return <SportLinks items={links} isLoading={isLoading} />;
};

export default SportLinksContainer;
