import React, { useMemo } from "react";
import classNames from "classnames";
import useInfiniteScroll from "react-infinite-scroll-hook";
import GameCardContainer from "../../../modules/casino/components/GameCardContainer";
import { gameItem } from "../../../modules/casino/constants";
import Loader from "../../atoms/Loader";
import { jsonReplacer } from "../../../modules/casino/zing/helpers";

type GameListProps = {
  games: gameItem[];
  type?: string;
  isLoading: boolean;
  loadMore: () => void;
  hasMore: boolean;
  blockUid?: string;
  slug?: string;
  searchValue?: string;
  providers?: string[];
};

const GameList: React.FC<GameListProps> = ({
  games = [],
  isLoading,
  type,
  loadMore,
  hasMore,
  blockUid,
  slug,
  searchValue,
  providers,
}) => {
  const [loading, setLoading] = React.useState(false);
  const infiniteRef = useInfiniteScroll<HTMLDivElement>({
    loading: loading,
    hasNextPage: hasMore,
    onLoadMore: () => {
      setLoading(true);
      loadMore();
      setLoading(false);
    },
    scrollContainer: "window",
  });
  const filters = useMemo(
    () => ({
      filterProducers: JSON.stringify(providers || [], jsonReplacer),
      search: searchValue || "",
    }),
    [providers, searchValue]
  );

  if (isLoading) {
    return <Loader fullHeight minHeight={500} />;
  }

  return (
    <div
      className={classNames("game-list", `game-list--${type}`)}
      ref={infiniteRef}
    >
      {games.map((item, index) => (
        <GameCardContainer
          key={item?.imageUrl}
          item={item}
          category={slug}
          position={index}
          blockUid={blockUid}
          filters={filters}
        />
      ))}
    </div>
  );
};

GameList.defaultProps = {
  type: "grid",
};

export default GameList;
