import { useQuery, useMutation } from "react-query";
import { addToast } from "../../../system/helpers/toast";
import { apiErrors } from "../../../system/helpers/apiErrorHelper";
import { getLanguage } from "../../../system/translations/helper";
import { useApiRequest } from "../../../system/hooks/useApiRequest";
import {
  GET_CASHBOX_METHODS_URL,
  getAmountPayload,
  methodsPayloadData,
  processDetailsPayload,
  GET_METHOD_AMOUNT_DETAILS,
  bonusesPayloadData,
  GET_METHOD_BONUSES,
  postMethodAmountPayload,
  POST_METHOD_AMOUNT_DETAILS,
  methodProcessPayload,
  POST_METHOD_BONUSES,
  GET_WITHDRAWAL_PROCESS,
  methodWithdrawalProcessPayload,
  POST_WITHDRAWAL_PROCESS,
} from "../constants";
import { generatePath } from "react-router-dom";

export const useGetMethods = (type: string) => {
  const [apiRequest] = useApiRequest();

  const lang = getLanguage();
  return useQuery(
    [`cahsbox_methos_${type}`, lang],
    (): Promise<methodsPayloadData> =>
      apiRequest({
        type: "GET_CASHBOX_METHODS",
        request: {
          url: generatePath(GET_CASHBOX_METHODS_URL, {
            type,
            lang,
          }),
          method: "GET",
          withCredentials: false,
          transformRequest: (data, headers) => {
            delete headers["X-Requested-With"];
          },
        },
      })
  );
};

export const useGetMethodAmount = (method: string, type: string) => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();

  return useMutation(
    (): Promise<getAmountPayload> =>
      apiRequest({
        type: "GET_METHOD_AMOUNT",
        request: {
          url: generatePath(GET_METHOD_AMOUNT_DETAILS, {
            method,
            type,
            lang,
          }),
          method: "GET",
        },
      })
  );
};

export const useGetMethodProcess = () => {
  const [apiRequest] = useApiRequest();

  return useMutation(
    (processUrl: any): Promise<processDetailsPayload> => {
      const url = processUrl.split("/backend");

      return apiRequest({
        type: "GET_METHOD_PROCESS",
        request: {
          url: url[1],
          method: "GET",
        },
      });
    }
  );
};

export const useGetMethodBonuses = (method: string) => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();

  return useMutation(
    (): Promise<bonusesPayloadData> =>
      apiRequest({
        type: "GET_METHOD_BONUSES",
        request: {
          url: generatePath(GET_METHOD_BONUSES, {
            method,
            lang,
          }),
          method: "GET",
        },
      })
  );
};

export const usePostMethodAmount = (method: string, type: string) => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();

  return useMutation(
    (data: any): Promise<postMethodAmountPayload> =>
      apiRequest({
        type: "POST_METHOD_AMOUNT",
        request: {
          url: generatePath(POST_METHOD_AMOUNT_DETAILS, {
            method,
            type,
            lang,
          }),
          method: "POST",
          data: data,
          headers: {
            "content-type":
              "multipart/form-data; boundary=----WebKitFormBoundaryNBmJ1kyVqlEfHqJ8",
          },
        },
      })
  );
};

export const usePostMethodProcess = () => {
  const [apiRequest] = useApiRequest();
  return useMutation(
    (data: any): Promise<methodProcessPayload> => {
      const url = data?.submitUrl.split("/backend");
      return apiRequest({
        type: "POST_METHOD_AMOUNT",
        request: {
          url: url[1],
          method: "POST",
          data: data?.requestData,
          headers: {
            "content-type":
              "multipart/form-data; boundary=----WebKitFormBoundaryNBmJ1kyVqlEfHqJ8",
          },
        },
      });
    },
    {
      onSuccess: (data: any) => {
        const errors = data?.systemMessages?.errors;
        if (!!errors && errors.length) {
          errors.forEach((item) => {
            if (item?.message) {
              addToast(item.message, {
                type: "error",
              });
            }
          });
        }
      },
      onError: (err: any) => {
        apiErrors(err, "notify");
      },
    }
  );
};

export const usePostMethodBonuses = (method: string) => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();

  return useMutation((bonusId: string) =>
    apiRequest({
      type: "POST_METHOD_AMOUNT",
      request: {
        url: generatePath(POST_METHOD_BONUSES, {
          method,
          bonusId,
          lang,
        }),
        method: "POST",
        headers: {
          "content-type":
            "multipart/form-data; boundary=----WebKitFormBoundaryNBmJ1kyVqlEfHqJ8",
            "isspa": true,
        },
      },
    })
  );
};

export const useGetWithdrawal = (method: string) => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();

  return useMutation(
    (): Promise<processDetailsPayload> =>
      apiRequest({
        type: "GET_WITHDRAWAL_PROCESS",
        request: {
          url: generatePath(GET_WITHDRAWAL_PROCESS, {
            method,
            lang,
          }),
          method: "GET",
        },
      })
  );
};

export const usePostWithdrawalProcess = (method) => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();

  return useMutation(
    (data: any): Promise<methodWithdrawalProcessPayload> =>
      apiRequest({
        type: "POST_WITHDRAWAL_PROCESS",
        request: {
          url: generatePath(POST_WITHDRAWAL_PROCESS, {
            method,
            lang,
          }),
          method: "POST",
          data: data,
          headers: {
            "content-type":
              "multipart/form-data; boundary=----WebKitFormBoundaryNBmJ1kyVqlEfHqJ8",
          },
        },
      }),
    {
      onError: (err: any) => {
        apiErrors(err, "notify");
      },
    }
  );
};
