import React from "react";
import { Route, Switch } from "react-router-dom";
import { APP_ROUTES } from "./constants";
import PrivateRoute from "../helpers/PrivateRoute";
import PageNotFound from "../../pages/errorPages/404";
import StartPage from "../../pages/startPage";
import Promo from "../../pages/promo";
import CasinoPage from "../../pages/casino";
import LiveGames from "../../pages/liveGames";
import InfoPage from "../../pages/info";
import GamePage from "../../pages/game";
import UserPage from "../../pages/user";
import SportPage from "../../pages/sportPage";
import pwaLandingPage from "../../pages/pwaLanding";
import Euro2020Page from "../../pages/euro2020";
import SignUpPage from "../../pages/signUpPage";
import SignInPage from "../../pages/signInPage";

export default (
  <Switch>
    <PrivateRoute exact path={APP_ROUTES.startPage} component={StartPage} />
    <PrivateRoute exact path={APP_ROUTES.resetPassword} component={StartPage} />
    <PrivateRoute exact path={APP_ROUTES.sportsbook} component={SportPage} />
    <PrivateRoute exact path={APP_ROUTES.inPlay} component={SportPage} />
    <PrivateRoute exact path={APP_ROUTES.euro2020} component={Euro2020Page} />
    <PrivateRoute
      exact
      path={APP_ROUTES.pwaLanding}
      component={pwaLandingPage}
    />
    <PrivateRoute exact path={APP_ROUTES.promo} component={Promo} />
    <PrivateRoute
      exact
      path={APP_ROUTES.liveGames.gamesPage}
      component={LiveGames}
    />
    <PrivateRoute exact path={APP_ROUTES.infoPages} component={InfoPage} />
    <PrivateRoute
      path={APP_ROUTES.userPage.profilePage}
      component={UserPage}
      profile
    />
    <PrivateRoute
      path={APP_ROUTES.casinoPage.allGames}
      component={CasinoPage}
    />
    <PrivateRoute path={"/:lang/games"} component={CasinoPage} />
    <PrivateRoute path={APP_ROUTES.gamePage} component={GamePage} />
    <Route path={APP_ROUTES.signUp} component={SignUpPage} />
    <Route path={APP_ROUTES.signIn} component={SignInPage} />
    <Route component={PageNotFound} />
  </Switch>
);
