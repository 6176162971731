import { getCookie } from "../helpers/cookie";

export const useDetectDomain = () => {
  let isLoading = true;
  const currentDomain = window.location.host;
  const currentOrigin = window.location.origin;
  const currentDomainPrefix = currentDomain.split(".")[0];
  const pinnedDomain = getCookie("gtptd") || currentDomainPrefix;
  const isDifferent = pinnedDomain !== currentDomainPrefix;
  const allowedDomainPrefixes = [
    "www",
    "www2",
    "www3",
    "stg",
    "stg3",
    "tst",
    "tst3",
  ];
  if (allowedDomainPrefixes.includes(pinnedDomain) && isDifferent) {
    const newUrl = currentOrigin.replace(currentDomainPrefix, pinnedDomain);
    window.location.assign(newUrl);
  } else {
    isLoading = false;
  }

  return [isLoading];
};
