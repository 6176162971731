import React from "react";
import { isMobile } from "react-device-detect";
import { useHideModal } from "../../../modules/modals/hooks";
import modalImg from "../../../images/elements/old-site-info-modal-img.png";
import modalImgMobile from "../../../images/elements/old-site-info-modal-img-mob.png";
import DescriptionModal from "./DescriptionModal";
import { useTrans } from "../../../system/translations/hooks";

const OldSiteInfoModal: React.FC = () => {
  const { _t } = useTrans();
  const hideModal = useHideModal();

  const image = isMobile ? modalImgMobile : modalImg;
  const description = _t("old_site_info_modal_description");
  const btnText = _t("old_site_info_modal_btn_label");
  const title = _t("old_site_info_modal_title");

  return (
    <DescriptionModal
      title={title}
      text={description}
      img={image}
      btnText={btnText}
      handleClick={hideModal}
    />
  );
};

export default OldSiteInfoModal;
