import React from "react";
import { Link } from "react-router-dom";
import LazyImg from "../LazyImg";
import { useHideModal } from "../../../modules/modals/hooks";
import { useTrans } from "../../../system/translations/hooks";

export type SportLinkProps = {
  url: string;
  name: string;
  img: string;
};

const SportLink: React.FC<SportLinkProps> = ({ url, name, img }) => {
  const { _t } = useTrans();

  const hideModal = useHideModal();
  return (
    <Link to={url} className={"sport-link"} onClick={hideModal}>
      <LazyImg className={"sport-link__img"} imgSrc={img} />
      <span className={"sport-link__name"}>{name}</span>
      <span className={"sport-link__button"}>{_t("sport_link_button")}</span>
    </Link>
  );
};

export default SportLink;
