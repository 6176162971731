const defaultTranslations = {
  main_href_domain: "stg3.bahsegel.com",
  load_more: "Load more",
  required: "Required",
  profile: "Profile",
  firstName_required: "First name is required",
  lastName_required: "Last name is required",
  gender_required: "Gender is required",
  birthDateDay_required: "Birth day is required",
  birthDateMonth_required: "Birth mont is required",
  birthDateYear_required: "Birth year is required",
  country_required: "Country is required",
  city_required: "City is required",
  postalCode_required: "Postal code is required",
  address_required: "Address is required",
  apply: "Apply",
  from: "From",
  email: "E-mail",
  username: "Username",
  first_name: "First Name",
  last_name: "Last Name",
  mobile_phone: "Mobile Phone",
  birth_date: "Date of birth",
  address: "Address",
  postal_code: "Postal Code",
  city: "City",
  country: "Country",
  save_changes: "Save Changes",
  change_password: "Change password",
  submit_kyc_docs: "Submit",
  to: "To",
  not_found_title: "Not found",
  no_data: "No data",
  drop_zone_text: "Drop file here or",
  drop_zone_button: "browse",
  upload: "Upload",
  prize_places: "Prize places",
  details: "Details",
  hide: "Hide",
  hour: "Hour",
  time_period: "Time period",
  min: "min",
  max: "max",
  play_for_fun: "Play for fun",
  play_for_real: "Play for real",
  play_now: "Play now",
  casino_search_input_placeholder: "Search",
  "casino_load_more_text_{shown}_{total}":
    "You've viewed {shown} of {total} games",
  casino_favorites_title: "Favorites",
  casino_providers_title: "Provider",
  "casino_providers_show_{count}_games": "Show ({count}) games",
  casino_providers_select_all: "Select all",
  casino_providers_clear_all: "Clear all",
  casino_providers_all: "All",
  casino_providers_more: "More providers",
  casino_providers_less: "Less providers",
  how_to_play: "How to play",
  user_total_balance: "Total balance",
  user_real_balance_exceeded: "Real balance exceeded",
  user_info_deposit: "Deposit",
  user_bonus_balance: "Bonus balance",
  user_real_balance: "Real balance",
  user_log_out: "Log Out",
  user_balance: "Balance",
  user_current_level: "VIP level:",
  user_progres_level_completed: "completed",
  user_next_level: "to next level",
  sidebar_sign_up: "Sign Up",
  sidebar_login: "Login",
  header_sport: "Sport",
  header_in_play: "In play",
  header_virtual_sports: "Virtual sports",
  header_casino: "Casino",
  header_live_casino: "Live casino",
  header_tournaments: "Tournaments",
  header_promotions: "Promotions",
  header_support: "Support",
  header_public_chat: "Public chat",
  header_install_app: "Install app",
  casino_recommended: "Recommended",
  casino_editors_choice: "Editors Choice",
  casino_new_product: "New Product",
  casino_most_popular: "Most Popular",
  casino_features: "Feature games",
  casino_poker: "Poker",
  casino_blackjack: "Blackjack",
  casino_live: "Live Casino",
  casino_other: "Other",
  casino_poker_description:
    "A curated list of the best games in our editorial opinion",
  casino_show_all: "Show All",
  show_more: "Show More",
  casino_jackpot: "Jackpot",
  casino_favorites: "Favorites",
  casino_search_title: "Search",
  casino_search_not_found_desc:
    "Make sure the spelling is correct or try some different keyword.",
  footer_site_desc_title: "bahsegel.com - online sports betting casino",
  footer_site_desc:
    "bahsegel.com is operated by Abudantia B.V., a company registered under the laws of Curacao under registration number 126608 and having its registered address at Heelsumstraat 51 E-Commerce Park, Curacao. Abudantia B.V. holds a sublicense # 8048/JAZ2014-034 issued by Antillephone N.V., which has been established under the laws of Curaçao and holds a Gaming License # 8048/JAZ. Payment agent is Striking Stingray Cyprus Limited which is a subsidiary of Abudantia N.V. and has been established under the laws of Cyprus under registration number HE 398271 and having its registered address at Chytron, 30, 2nd floor, Flat/Office A22, 1075, Nicosia, Cyprus.",
  modal_login_desc: "Login with credentials",
  modal_registration_title: "Sign Up",
  modal_reset_password_title: "Enter your new password",
  login_form_username: "username",
  login_form_password: "password",
  login_form_log_in_btn: "Login",
  after_login_modal_hello: "Hello",
  after_login_modal_welcome: "Welcome back to the bahsegel Family",
  login_modal_welcome: "Welcome back to Bahsegel",
  after_login_modal_recently_played: "Recently Played",
  after_login_modal_recommends: "bahsegel Recommends",
  after_login_modal_to_games: "To our games",
  modal_change_password: "Change Password",
  change_password_form_old_password: "Old password",
  change_password_form_new_password: "New password",
  change_password_form_repeat_password: "Confirm new password",
  change_password_form_btn: "Change password",
  change_password_form_success_password_updated: "Password updated",
  change_password_form_success_content:
    "Your password was successfully changed. Your can now use your new password \n" +
    "to sign in to your account.",
  change_password_form_success_ok_btn: "OK",
  modal_fill_out_profile_title: "Fill out profile",
  modal_fill_out_profile_address_and_phone_title: "Address & phone",
  modal_fill_out_profile_content:
    "You need to fill out your profile in order to make deposits.",
  modal_fill_out_profile_first_name: "First Name",
  modal_fill_out_profile_last_name: "Last Name",
  modal_fill_out_profile_select_gender: "Select gender",
  modal_fill_out_profile_gender_male: "Male",
  modal_fill_out_profile_gender_female: "Female",
  modal_fill_out_profile_date_of_birth_label: "Date of birth",
  modal_fill_out_profile_back_btn: "Back",
  modal_fill_out_profile_confirm_btn: "Confirm",
  modal_fill_out_profile_phone: "Phone",
  fill_out_profile_country_placeholder: "Country",
  fill_out_profile_city_placeholder: "City",
  fill_out_profile_address_placeholder: "Address",
  fill_out_profile_postal_code_placeholder: "Postal Code",
  modal_fill_out_profile_continue_btn: "Continue",
  modal_fill_out_profile_profile_completed: "Profile completed",
  modal_fill_out_profile_info_saved:
    "All information has been saved. Now you are able to play games for real money.",
  modal_fill_out_profile_make_deposit_btn: "Make a Deposit",
  modal_forgot_password_title: "Forgot password?",
  modal_forgot_password_submit_button: "Send reset link",
  modal_forgot_password_desc:
    "Enter the email registered to your account and we will send you a password reset link.",
  modal_forgot_password_reset_link_send: "Reset link is sent",
  modal_forgot_password_content:
    "Please check your email and follow the confirmation link.",
  modal_forgot_password_content_cant_find_email:
    "Can’t find the email in your inbox? Check your spam/ junk folder or contact our support team.",
  modal_forgot_password_success_ok_btn: "OK",
  modal_reset_success: "Success",
  modal_reset_success_content: "You can try to login",
  modal_reset_success_btn: "Ok",
  error_404_page_status_code: "404",
  error_404_page_text: "Page not found",
  error_404_page_description:
    "Oops, it looks like the page you request could not be found",
  error_404_btn_text: "Main Page",
  some_error_page_text: "Sorry.. there was an error",
  some_error_page_description: "Please reload page or contact with support",
  promo_page_title: "Promotions",
  kyc_file_limit: "Maximum file size 5 MB, JPG or PNG",
  kyc_ccv_title: "Credit card",
  kyc_ccv_note:
    "This may be a photo from the payment method you use to fund your casino account.",
  kyc_ccv_front_label: "Front side",
  kyc_ccv_front_desc: "Upload front side",
  kyc_ccv_back_label: "Back side",
  kyc_ccv_back_desc: "Upload back side",
  kyc_ccv_upload_image: "Upload your credit card image",
  kyc_idv_title: "Identification",
  kyc_idv_label: "Select ID type:",
  kyc_idv_identity_card: "Identity Card",
  kyc_idv_passport: "Passport",
  kyc_idv_driving: "Driving License",
  kyc_doc_title: "Address",
  kyc_doc_label: "Any confirmation of your current place of residence:",
  kyc_doc_bill: "Utility bill",
  kyc_doc_bank: "Bank statement",
  kyc_doc_certificate: "Certificate of residence",
  kyc_status_pass: "Your identity and address document is approved",
  kyc_not_verified: "Not verified",
  security_settings: "Security settings",
  tfa_title: "Two-Factor Authentication",
  tfa_default_message:
    "For successful authorization, you will need to enter a code that will be sent to your phone or email.",
  "tfa_sms_{phone}_message":
    "For successful authorization, you will need to enter a code that will be sent to your phone {phone}.",
  "tfa_{email}_message":
    "For successful authorization, you will need to enter a code that will be sent to your email {email}.",
  tfa_sms_code: "Sms Code",
  tfa_email_code: "Email Code",
  tfa_process_form_code: "Code",
  "tfa_process_{value}_form_timer": "Resend the code after {value} seconds",
  tfa_process_form_resend_code: "Resend Code",
  tfa_process_form_submit: "Confirm",
  change_password_title: "Change password",
  tournaments: "Tournaments",
  tournament_finished: "tournament finished",
  rules_and_conditions: "Rules and Conditions",
  time_left_before_finish: "time left before finish",
  back: "back",
  seo_panel_title_sportsbook: "Welcome to a new way of getting entertained",
  seo_panel_subtitle_sportsbook:
    "bahsegel an online casino is operated by Abudantia B.V, a company registered under the laws of Curacao",
  seo_panel_text_sportsbook:
    "Registration number 126608 and its registered address at E-Commerce Park Vredenberg, Curacao. Abudantia B.V. holds a sub-license number 8048 / JAZ2014-034 issued by Antillephone N.V., which has been established under the Curaçao regulations and has the main license number 8048 / JAZ. Ver.92.86.\n" +
    "\n" +
    "⦿     «Games» are Casino, Live Casino, Sportsbook, cards, and other games as may from time to time become available on the Website. \n" +
    "\n" +
    "⦿     «Account» is an account opened by You on the website after accepting and agreeing to this «Terms».\n" +
    "\n" +
    "⦿     «Website» is the website available at the URL http://www.bahsegel.com/ and/or other related URLs operated by bahsegel.\n" +
    "\n" +
    "⦿     «You» or «User» or «Customer» is Yourself or any other person who, after reading these «Terms» opens an Account or makes use of the Website in any way.",
  seo_panel_title_promo: "Welcome to a new way of getting entertained",
  seo_panel_subtitle_promo:
    "bahsegel an online casino is operated by Abudantia B.V, a company registered under the laws of Curacao",
  seo_panel_text_promo:
    "Registration number 126608 and its registered address at E-Commerce Park Vredenberg, Curacao. Abudantia B.V. holds a sub-license number 8048 / JAZ2014-034 issued by Antillephone N.V., which has been established under the Curaçao regulations and has the main license number 8048 / JAZ. Ver.92.86.\n" +
    "\n" +
    "⦿     «Games» are Casino, Live Casino, Sportsbook, cards, and other games as may from time to time become available on the Website. \n" +
    "\n" +
    "⦿     «Account» is an account opened by You on the website after accepting and agreeing to this «Terms».\n" +
    "\n" +
    "⦿     «Website» is the website available at the URL http://www.bahsegel.com/ and/or other related URLs operated by bahsegel.\n" +
    "\n" +
    "⦿     «You» or «User» or «Customer» is Yourself or any other person who, after reading these «Terms» opens an Account or makes use of the Website in any way.",
  history_filter_title: "Filter",
  forgot_your_password: "Forgot your password?",
  password_updated: "Password updated",
  password_changed:
    "Your password was successfully changed. Your can now use your new password to sign in to your account.",
  reset_new_password: "New password",
  repeat_reset_new_password: "Confirm your new password",
  submit_reset_password: "Update your password",
  reset_link_sent: "Reset link is sent",
  check_your_email: "Please check your email and follow the confirmation link.",
  check_your_spam:
    "Can’t find the email in your inbox? Check your spam/ junk folder or contact our support team.",
  no: "No",
  yes: "Yes",
  ok: "OK",
  request_created: "Request created",
  request_submitted: "Your request was successfully submitted for processing.",
  transactions_tab: "Transactions",
  deposit_successful: "Deposit successful",
  transaction_successfully_completed:
    "Your transaction has been successfully completed",
  top_games: "Top Games",
  top_matches: "Top Matches",
  recently_played: "Recently Played",
  favorites: "Favorites",
  play_and_bet: "Play and Bet",
  profile_completed: "Profile completed",
  info_saved:
    "All information has been saved. Now you are able to play games for real money.",
  make_a_deposit: "Make a Deposit",
  verify_account: "Verify your account",
  acc_needs_verification:
    "We need to verify your account for you to be able to make withdrawals.",
  upload_docs: "Upload documents",
  bonuses_my_bonuses_title: "My Bonuses",
  bonuses_bonus_code_label: "Bonus Code",
  bonuses_loyalty_points_label: "Loyalty Points",
  bonuses_available_for_exchange: "Available for exchange",
  bonuses_exchange_btn: "Exchange",
  bonuses_send_code_btn: "Send",
  bonuses_enter_bonus_code_placeholder: "Enter bonus code",
  bonuses_active_bonuses_title: "Active Bonuses",
  bonuses_available_bonuses_title: "Available Bonuses",
  bonuses_bonus_history_title: "Bonus History",
  bonus_table_bonus: "Bonus",
  bonus_table_status: "Status",
  bonus_table_expire: "Expire",
  bonus_table_amount: "Amount",
  bonus_table_wagering_requirement: "Wagering requirement",
  bonus_table_amount_wagered: "Amount wagered",
  bonuses_type_a_promo_code_and_get_discount:
    "Type a promo code and get discount",
  bonuses_exchange_your_loyalty_bonuses_for_real_money:
    "Exchange your loyalty bonuses for real money",
  bonuses_active_bonuses_table_empty_content: "No active bonuses found",
  bonuses_available_bonuses_table_empty_content: "No available bonuses found",
  bonuses_bonus_history_table_empty_content: "No old bonuses found",
  my_bonuses__general: "General",
  my_bonuses__bonus_request: "Bonus Request",
  bonus_request__label: "Bonus Request",
  bonus_request__select_type_of_bonus:
    "Select the type of bonus you want to request",
  bonus_request__select_bonus_placeholder: "Select bonus",
  bonus_request__option_cashback: "Cashback",
  bonus_request__option_freebet: "Freebet",
  bonus_request__option_freespin: "Freespin",
  bonus_send_request_btn: "Send request",
  bonus_request_history_table_title: "Bonus Request History",
  bonus_request__bonus_type: "Bonus type",
  bonus_request__comment: "Comment",
  bonus_request__status: "Status",
  bonus_request__date_and_time: "Date and Time",
  bonus_request_history__empty_table: "No bonuses found",
  bonus_request_history__btn_cancel_label: "Cancel",
  bonus_request_history__btn_play_now_label: "Play now",
  bonus_request__select_bonus_notify: "Select bonus you want to request!",
  bonus_request__already_requested: "This type of bonus already requested!",
  status_label__approved: "Approved",
  status_label__declined: "Declined",
  status_label__processing: "Processing",
  invalid_username: "Invalid username",
  required_username: "Required username",
  login_must_be_longer: "Login must be longer",
  login_must_be_shorter: "Login must be shorter",
  invalid_password: "Invalid password",
  required_password: "Required password",
  password_must_be_longer: "Password must be longer",
  password_must_be_shorter: "Password must be shorter",
  passwords_must_match: "Passwords must match",
  passwords_must_not_match: "Passwords must not match",
  password_must_contain_at_least_one_number:
    "Password must contain at least one number",
  should_not_match_cyrillic_characters: "Should not match cyrillic characters",
  time: "Time",
  ref_number: "Ref#",
  type: "Type",
  payment_method: "Payment Method",
  payment_methods: "Payment Methods",
  or_use_another: "or use another",
  user_bonus_request: "Bonus Request",
  status: "Status",
  sum: "Sum",
  date_and_time: "Date and Time",
  bet_id: "Bet ID",
  bet_type: "Bet Type",
  bet_amount: "Bet Amount",
  game_name: "Game Name",
  win_amount: "Win Amount",
  bonus_amount: "Bonus Amount",
  amount: "Amount",
  invalid_email: "Invalid email",
  required_email: "Required email",
  terms_and_conditions_link: "Terms and conditions",
  reg_form_username: "reg_form_username",
  invalid_phone: "Invalid phone",
  required_phone: "Required phone",
  reg_form_email: "reg_form_email",
  reg_form_phone: "reg_form_phone",
  reg_form_password: "reg_form_password",
  reg_form_submit: "Create Account",
  reg_form_currency_placeholder: "Currency",
  "i_agree_to_the_{thermsLink}_label": "I agree to the {thermsLink}",
  pending_withdrawals: "Pending Withdrawals",
  transactions_history: "Transactions History",
  table_details_winner_status: "Winner Status",
  table_details_order_status: "Order Status",
  table_details_date: "Date",
  table_details_event_name: "Event Name",
  table_details_amount: "Amount",
  table_details_win_amount: "Win Amount",
  table_details_max_win_amount: "Max Win Amount",
  table_details_factor: "Factor",
  table_details_win_status_finished: "finished",
  table_details_win_status_pending: "pending",
  table_details_win_status_canceled: "canceled",
  table_details_win_status_won: "won",
  table_details_result: "Result",
  table_details_win_status: "Win status",
  table_details_order_amount: "Order amount",
  table_details_no_details_available: "No details available",
  thematic_categories: "Thematic Categories",
  cancel: "Cancel",
  dropdown_select_date_of_birth_year: "Year",
  dropdown_select_date_of_birth_month: "Month",
  dropdown_select_date_of_birth_day: "Day",
  error_dont_accept_terms: "Must Accept Terms and Conditions",
  must_be_a_number: "Must be a number",
  big_win_with: "big win with",
  jackpots: "jackpots",
  your_favourite: "your favourite",
  online_casino: "online casino",
  go_bet_my_friend: "Go bet my friend",
  on_sports: "on sports!",
  get_it_on: "Get it on",
  only_chrome: "Chrome browser only",
  pwa_application: "Bahsegel application",
  get_it_now: "Get it now on your phone",
  ios: "iOS",
  android: "Android",
  desktop: "Desktop",
  chrome_browser_only: "Chrome browser only",
  more_advantages: "More advantages in mobile app",
  secure_data: "Secure data",
  permanent_access: "Permanent access",
  no_space_req: "No space requirement",
  make_a_bet: "Make a bet right now",
  get_access_to_pwa:
    "Get to App quickly and easily by adding it to your Home Screen.",
  get_it_on_android: "Get it on Android",
  install_app: "Play anytime, anywhere! Ease of installation and use.",
  install_app_mob: "Ease of installation and use",
  install: "Install",
  install_now: "Install now",
  modal_bonus_exchange_title: "Exchange",
  exchange_rate: "Exchange Rate",
  exchange_lp_btn: "Exchange",
  "exchange_form_footer_{minAmount}_message": "Minimum amount {minAmount} LP",
  not_enough_loyalty_points: "Not enough Loyalty Points",
  "min_amount_exchange_{minAmount}_error":
    "Minimum exchange amount is {minAmount} LP",
  verification_phone_modal_title: "We just sent an SMS",
  verification_phone_modal_placeholder: "Enter the 4-digit code",
  verification_phone_modal_content:
    "A text message with a verification code was just sent to ",
  verification_phone_modal_verify_btn: "Verify",
  verification_phone_modal_resend_code_btn: "Resend Code",
  verify_phone_require_four_digit_code: "Code should be 4-digit",
  verify_phone_code_required: "Code is required",
  sign_up: "Sign Up",
  dont_have_an_account: "Don't have an account?",
  no_bonus: "No bonus",
  take_no_bonuses: "Take no bonuses",
  available_bonuses: "available bonuses",
  scan_qr: "Scan QR code to Install App",
  use_camera: "Use your phone’s camera to scan",
  browser_install:
    "App Store is not allowing casino apps, so we use an innovative installation right from your browser.",
  "how_add_to_home_{icon}": "Just tap {icon} then «Add to Home Screen»",
  get_app_installer: "Get App Installer",
  get_it_on_ios: "Get it on IOS",
  slides_not_loaded: "Slides not loaded",
  website_under_maintenance: "The website is currently under maintenance",
  wont_be_long:
    "We are working towards creating something better. We won't be long.",
  unavailable_in_your_country:
    "Sorry, but bahsegel is not available in your country",
  got_questions: "Should you have any questions please address them to",
  seo_panel_title_landing: "Welcome to a new way of getting entertained",
  seo_panel_subtitle_landing:
    "Bahsegel an online casino is operated by Abudantia B.V, a company registered under the laws of Curacao",
  seo_panel_text_landing:
    "Registration number 126608 and its registered address at E-Commerce Park Vredenberg, Curacao. Abudantia B.V. holds a sub-license number 8048 / JAZ2014-034 issued by Antillephone N.V., which has been established under the Curaçao regulations and has the main license number 8048 / JAZ. Ver.92.86. «Games» are Casino, Live Casino, Sportsbook, cards, and other games as may from time to time become available on the Website. «Account» is an account opened by You on the website after accepting and agreeing to this «Terms». «Website» is the website available at the URL http://www.bahsegel.com/ and/or other related URLs operated by Bahsegel. «You» or «User» or «Customer» is Yourself or any other person who, after reading these «Terms» opens an Account or makes use of the Website in any way.",
  seo_panel_title_pwalanding: "Welcome to a new way of getting entertained",
  seo_panel_subtitle_pwalanding:
    "Bahsegel an online casino is operated by Abudantia B.V, a company registered under the laws of Curacao",
  seo_panel_text_pwalanding:
    "Registration number 126608 and its registered address at E-Commerce Park Vredenberg, Curacao. Abudantia B.V. holds a sub-license number 8048 / JAZ2014-034 issued by Antillephone N.V., which has been established under the Curaçao regulations and has the main license number 8048 / JAZ. Ver.92.86. «Games» are Casino, Live Casino, Sportsbook, cards, and other games as may from time to time become available on the Website. «Account» is an account opened by You on the website after accepting and agreeing to this «Terms». «Website» is the website available at the URL http://www.bahsegel.com/ and/or other related URLs operated by Bahsegel. «You» or «User» or «Customer» is Yourself or any other person who, after reading these «Terms» opens an Account or makes use of the Website in any way.",
  seo_panel_title_sport: "Welcome to a new way of getting entertained",
  seo_panel_subtitle_sport:
    "Bahsegel an online casino is operated by Abudantia B.V, a company registered under the laws of Curacao",
  seo_panel_text_sport:
    "Registration number 126608 and its registered address at E-Commerce Park Vredenberg, Curacao. Abudantia B.V. holds a sub-license number 8048 / JAZ2014-034 issued by Antillephone N.V., which has been established under the Curaçao regulations and has the main license number 8048 / JAZ. Ver.92.86. «Games» are Casino, Live Casino, Sportsbook, cards, and other games as may from time to time become available on the Website. «Account» is an account opened by You on the website after accepting and agreeing to this «Terms». «Website» is the website available at the URL http://www.bahsegel.com/ and/or other related URLs operated by Bahsegel. «You» or «User» or «Customer» is Yourself or any other person who, after reading these «Terms» opens an Account or makes use of the Website in any way.",
  seo_panel_title_casino: "Welcome to a new way of getting entertained",
  seo_panel_subtitle_casino:
    "Bahsegel an online casino is operated by Abudantia B.V, a company registered under the laws of Curacao",
  seo_panel_text_casino:
    "Registration number 126608 and its registered address at E-Commerce Park Vredenberg, Curacao. Abudantia B.V. holds a sub-license number 8048 / JAZ2014-034 issued by Antillephone N.V., which has been established under the Curaçao regulations and has the main license number 8048 / JAZ. Ver.92.86. «Games» are Casino, Live Casino, Sportsbook, cards, and other games as may from time to time become available on the Website. «Account» is an account opened by You on the website after accepting and agreeing to this «Terms». «Website» is the website available at the URL http://www.bahsegel.com/ and/or other related URLs operated by Bahsegel. «You» or «User» or «Customer» is Yourself or any other person who, after reading these «Terms» opens an Account or makes use of the Website in any way.",
  seo_panel_title_tournaments: "Welcome to a new way of getting entertained",
  seo_panel_subtitle_tournaments:
    "Bahsegel an online casino is operated by Abudantia B.V, a company registered under the laws of Curacao",
  seo_panel_text_tournaments:
    "Registration number 126608 and its registered address at E-Commerce Park Vredenberg, Curacao. Abudantia B.V. holds a sub-license number 8048 / JAZ2014-034 issued by Antillephone N.V., which has been established under the Curaçao regulations and has the main license number 8048 / JAZ. Ver.92.86. «Games» are Casino, Live Casino, Sportsbook, cards, and other games as may from time to time become available on the Website. «Account» is an account opened by You on the website after accepting and agreeing to this «Terms». «Website» is the website available at the URL http://www.bahsegel.com/ and/or other related URLs operated by Bahsegel. «You» or «User» or «Customer» is Yourself or any other person who, after reading these «Terms» opens an Account or makes use of the Website in any way.",
  cashbox_methods_title: "Choose deposit method",
  recently_used_method: "Recently used method",
  wallet: "Wallet",
  betting_history: "Betting history",
  my_bonuses: "My bonuses",
  verification: "Verification",
  personal_information: "Personal information",
  deposit_tab: "Deposit",
  withdrawal_tab: "Withdrawal",
  sport: "Sport",
  casino: "Casino",
  file_too_large: "File too large",
  unsupported_format: "Unsupported format",
  all_games: "All Games",
  "amount_{minVal}_error": "Amount {minVal} error",
  "amount_{maxVal}_error": "Amount {maxVal} error",
  already_have_an_account: "Already have an account?",
  log_in: "Log In",
  modal_deposit_title: "Deposit",
  cahsbox_sum: "Deposit sum",
  cashbox_change_method: "Change method",
  cashbox_details: "Details",
  cashbox_next: "Next",
  cashbox_deposit: "Deposit",
  tran_type: "Transaction type",
  profile_update_successful: "Profile updated successfuly.",
  no_casino_link_to_show: "Casino link failed to load",
  loading: "Loading",
  install_app_title: "bahsegel app",
  cashbox_sum: "Amount Sum",
  available_to_cashout: "Available to cash out",
  the_lowest_amount: "The lowest amount to withdraw is",
  reject_active_bonuses_title: "reject_active_bonuses_title",
  reject_active_bonuses_description: "reject_active_bonuses_description",
  reject_active_bonuses_btn: "reject_active_bonuses_btn",
  cashbox_withdrawal: "cashbox_withdrawal",
  modal_login_title: "Sign In",
  modal_tfa_process_title: "Enter code",
  modal_tfa_process_desc:
    "We’ve sent you with the code to your phone or email.",
  header_login: "Login",
  header_sign_up: "Sign Up",
  withdrawal_successful_title: "Withdrawal successful",
  withdrawal_successfully_completed: "Withdrawal successfully completed",
  withdrawal_successful_btn_ok: "Ok",
  deposit_successful_btn_ok: "Ok",
  deposit_now: "Deposit Now",
  read_more: "Read More",
  bankName: "Bank name",
  GarantiBankasi: "GarantiBankasi",
  AkbankHavale: "AkbankHavale",
  IsBankasiHavale: "IsBankasiHavale",
  YapikrediHavale: "YapikrediHavale",
  VakifBankHavale: "VakifBankHavale",
  ZiraatbankHavale: "ZiraatbankHavale",
  FinansBank: "FinansBank",
  DenizBankHavale: "DenizBankHavale",
  INGBankHavale: "INGBankHavale",
  TEBBankHavale: "TEBBankHavale",
  HalkBankHavale: "HalkBankHavale",
  DigerBankHavale: "DigerBankHavale",
  Papara: "Papara",
  "Kuveyt Türk": "Kuveyt Türk",
  accountNumber: "accountNumber",
  cellPhone: "cellPhone",
  ibanNumber: "ibanNumber",
  tcIdentNumber: "tcIdentNumber",
  footer_menu_title: "Menu",
  footer_info_title: "Info",
  menu: "menu",
  info: "info",
  casino_favorites_description: "casino_favorites_description",
  casino_new_product_description: "casino_new_product_description",
  casino_most_popular_description: "casino_most_popular_description",
  casino_features_description: "casino_features_description",
  casino_live_casino_description: "casino_live_casino_description",
  casino_jackpot_description: "casino_jackpot_description",
  casino_blackjack_description: "casino_blackjack_description",
  swift: "swift",
  iban: "iban",
  branchCode: "branchCode",
  bankAddress: "bankAddress",
  deposit_completed_btn_ok: "Continue",
  deposit_completed_description: "Deposit completed description",
  deposit_completed_title: "Request created",
  deposit_completed_text: "Your transaction number",
  january: "January",
  february: "February",
  march: "March",
  april: "April",
  may: "May",
  june: "June",
  july: "July",
  august: "August",
  september: "September",
  october: "October",
  november: "November",
  december: "December",
  monday_s: "mon",
  tuesday_s: "tues",
  wednesday_s: "wed",
  thursday_s: "thurs",
  friday_s: "fri",
  saturday_s: "sat",
  sunday_s: "sun",
  tran_type_all: "All",
  tran_type_game_bet: "Game Bet",
  tran_type_game_win: "Game Win",
  accountId: "accountId",
  error_bankName_to_short: "error_bankName_to_short",
  error_bankName_to_long: "error_bankName_to_long",
  error_bankAddress_to_short: "error_bankAddress_to_short",
  error_bankAddress_to_long: "error_bankAddress_to_long",
  error_branchCode_must_be_number: "error_branchCode_must_be_number",
  error_swift_no_match_pattern: "error_swift_no_match_pattern",
  error_iban_invalid: "error_iban_invalid",
  reg_banner_title: "title",
  reg_banner_description: "Description",
  "birth_date_{min_years_old}_limit":
    "You must be over {min_years_old} years old",
  install_pwa_error_title: "install_pwa_error_title",
  install_pwa_error_text: "install_pwa_error_text",
  install_pwa_error_bottom_text: "install_pwa_error_bottom_text",
  install_pwa_error_btn_text: "install_pwa_error_btn_text",
  sport_page_title: "sport_page_title",
  landing_page_title: "landing_page_title",
  landing_page_title_without_locale: "landing_page_title_without_locale",
  euro2020_header_title: "EURO 2020",
  euro2020_header_desc:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis etiam ultricies turpis sit pellentesque eget lectus tortor.",
  euro2020_header_join: "How to join",
  euro2020_header_leaderboard: "Leaderboard",
  euro2020_how_to_join_title: "Become a participant of this promotion",
  euro2020_how_to_join_btn: "Join now",
  euro2020_item_1_title: "Congue suspendisse tortor.",
  euro2020_item_1_desc:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing magna libero at id eget placerat rhoncus donec sed pretium.",
  euro2020_item_2_title: "Massa hendrerit sagittis eu.",
  euro2020_item_2_desc:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Blandit turpis adipiscing.",
  euro2020_item_3_title: "Bibendum felis id dui.",
  euro2020_item_3_desc:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing magna libero at id eget placerat rhoncus donec sed pretium.",
  euro2020_leaderboard_title: "Leaderboard",
  "euro2020_leaderboard_{value}_points": "{value} points",
  euro2020_leaderboard_place: "place",
  euro2020_leaderboard_show_more: "Show more",
  euro2020_prize_1_title: "20,000 TRY",
  euro2020_prize_2_title: "15,000 TRY",
  euro2020_prize_3_title: "10,000 TRY",
  euro2020_prize_4_title: "7,500 TRY",
  euro2020_prize_5_title: "5,000 TRY",
  euro2020_prize_6_title: "2,500 TRY",
  euro2020_prize_7_title: "1000 TRY",
  euro2020_prize_8_title: "750 TRY",
  euro2020_prize_9_title: "500 TRY",
  euro2020_prize_10_title: "250 TRY",
  winners_title: "Latest big wins",
  "winners_item_text_{playerName}_{amount}_{gameName}":
    "{playerName} just won {amount} in {gameName}",
  "winners_item_text_{playerName}_{amount}_{gameName}_short":
    "{playerName} {amount} in {gameName}",
  disable_popup_blocker_modal_hero_title:
    "You will be redirected to the payment system website",
  disable_popup_blocker_modal_hero_btn: "Continue",
  disable_popup_blocker_modal_title: "Allow your browser to open popups",
  disable_popup_blocker_modal_desc:
    "for stable operation of deposit and withdrawal",
  disable_popup_blocker_modal_ios: "iOS (iPhone)",
  disable_popup_blocker_modal_android: "Android",
  disable_popup_blocker_modal_chrome: "Google Chrome",
  disable_popup_blocker_modal_safari: "Safari",
  disable_popup_blocker_modal_tutorial_safari_title:
    "Disable the pop-up blocker in the Safari web browser on your iPhone",
  disable_popup_blocker_modal_tutorial_safari_step_1: "Launch Settings",
  disable_popup_blocker_modal_tutorial_safari_step_2: "Tap Safari",
  disable_popup_blocker_modal_tutorial_safari_step_3:
    "Under the General section, click the toggle next to Block Pop-ups to disable the pop-up blocker",
  disable_popup_blocker_modal_tutorial_chrome_ios_title:
    "Allow pop-ups in Chrome on your iPhone",
  disable_popup_blocker_modal_tutorial_chrome_ios_step_1: "Open the Chrome app",
  disable_popup_blocker_modal_tutorial_chrome_ios_step_2:
    "To the right of the address bar, tap More > Settings",
  disable_popup_blocker_modal_tutorial_chrome_ios_step_3:
    "Tap Content Settings and then Block Pop-ups. Turn Block Pop-ups off",
  disable_popup_blocker_modal_tutorial_chrome_android_title:
    "Allow pop-ups in Chrome on your Android device",
  disable_popup_blocker_modal_tutorial_chrome_android_step_1:
    "Open the Chrome app",
  disable_popup_blocker_modal_tutorial_chrome_android_step_2:
    "To the right of the address bar, tap More",
  disable_popup_blocker_modal_tutorial_chrome_android_step_3:
    "Tap Site settings and then Pop-ups and redirects. Turn Pop-ups and redirects on",
  bet_games_nav_name: "Betgames.tv",
  kiron_nav_name: "Kiron",
  "cryptopay_buy_rate_{value}": "buy_rate: {value}",
  "cryptopay_sell_rate_{value}": "sell_rate: {value}",
  pwa_promo_modal_label: "Make a bet right now",
  pwa_promo_modal_title: "bahsegel Application",
  pwa_promo_modal_desc: "Get access to bahsegel quickly and easily.",
  pwa_promo_modal_btn: "Install now",
  bet_state_new: "New",
  bet_state_won: "Won",
  bet_state_lost: "Lost",
  bet_state_canceled: "Canceled",
  bet_state_cashout: "Cashout",
  bet_state_cashback: "Cashback",
  bet_state_expressBonus: "ExpressBonus",
  wrong_city_format: "Wrong city format",
  wrong_name_format: "Wrong name format",
  wrong_postal_code_format: "Wrong postal code format",
  wrong_address_format: "Wrong address format",
  not_now: "Not now",
  reg_section__total_jackpot: "Total Jackpot",
  reg_section_sub_title: "Ready to get started?",
  reg_section_title: "Welcome bonus Eur 200 +125 fs",
  footer_nav_support: "Support",
  public_chat_title: "New messages in Public chat!",
  providers_section_title: "The best games from the best providers",
  show_more_providers_btn: "More providers",
  sidebar_get_app_btn: "Bahsegel App",
  sidebar_get_app_btn_additional: "Ease of installation and use",
  "choose_{paymentType}_method": "Choose payment method",
  redeem_bonus_success: "Bonus redeemed successfully",
  nav_old_website: "Switch to classic Bahsegel",
  nav_old_website_description:
    "Go back to previous Bahsegel design at any time.",
  old_site_info_modal_btn_label: "Continue",
  old_site_info_modal_title: "Welcome to a Fresh, Simpler Bahsegel",
  old_site_info_modal_description:
    "We’re still working on making this experience better so we’d love to hear you feedback. \n\nYou can also easily go back to previous Bahsegel design at any time.",
  old_site_link: "https://bahsegel45.com", //TODO: placeholder
  public_chat_enable: "1",
  sport_links_title: "Sports Quick Links",
  sport_link_button: "Play",
  game_header_deposit: "Deposit",
  game_header_support: "Support",
};

const devMode = process.env.NODE_ENV === "development";
const trans = devMode ? defaultTranslations : {};

export default trans;
