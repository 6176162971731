import React, { Suspense, lazy } from "react";
import MainTemplate from "../../templates/Main";
import Loader from "../../components/atoms/Loader";
import { _t, makeKey } from "../../system/translations/InjectTransContext";
import { pageTitleKey } from "../../system/helpers/urlHelper";

import { Helmet } from "react-helmet";

const LiveGamesPageTemplate = lazy(() => import("./LiveGamesPageTemplate"));

const LiveGamesPage = () => {
  return (
    <>
      <Helmet>
        <title>{_t(makeKey("live_games_title", pageTitleKey))}</title>
        <meta
          name="description"
          content={_t(makeKey("live_games_description"))}
        />
      </Helmet>
      <MainTemplate
        prefixClass={"main-template--live-games"}
        fixedHeader={false}
        withoutFooter
        withoutSeoPanel
        withoutInstallApp
      >
        <Suspense fallback={<Loader center fullHeight minHeight={1000} />}>
          <LiveGamesPageTemplate />
        </Suspense>
      </MainTemplate>
    </>
  );
};

export default LiveGamesPage;
