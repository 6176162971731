import React, { Suspense, lazy } from "react";
import MainTemplate from "../../templates/Main";
import Loader from "../../components/atoms/Loader";
import { _t, makeKey } from "../../system/translations/InjectTransContext";
import { Helmet } from "react-helmet";
import { pageTitleKey } from "../../system/helpers/urlHelper";
const PwaLandingTemplate = lazy(() => import("./PwaLandingTemplate"));

const PwaLandingPage = () => {
  return (
    <>
      <Helmet>
        <title>{_t(makeKey("pwa_landing_page_title", pageTitleKey))}</title>
        <meta name="description" content={_t("pwa_landing_page_description")} />
      </Helmet>
      <MainTemplate
        prefixClass={"main-template--pwa-landing"}
        seoPanelProps={{ keySlug: "pwalanding" }}
        withoutInstallApp
        withoutSeoPanel
      >
        <Suspense fallback={<Loader center fullHeight minHeight={1000} />}>
          <PwaLandingTemplate />
        </Suspense>
      </MainTemplate>
    </>
  );
};

export default PwaLandingPage;
