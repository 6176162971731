import React, { Suspense, lazy } from "react";
import { initialValuesType } from "../forms/ForgotPasswordForm";
import { forgotPasswordRequestKeys } from "../../constants";
import { useForgotPassword } from "../../api/apiHooks";
import { apiErrors } from "../../../../system/helpers/apiErrorHelper";
import Loader from "../../../../components/atoms/Loader";

const ForgotPasswordForm = lazy(() => import("../forms/ForgotPasswordForm"));

const ForgotPasswordModal = () => {
  const {
    isLoading,
    error,
    mutate: forgotPasswordHandler,
  } = useForgotPassword();
  const responseError = apiErrors(error);

  const onSubmit = (values: initialValuesType) => {
    const formData = new FormData();
    formData.append(forgotPasswordRequestKeys.email, values.email);
    forgotPasswordHandler(formData);
  };

  return (
    <Suspense fallback={<Loader center fullHeight minHeight={200} />}>
      <ForgotPasswordForm
        responseError={responseError}
        onSubmit={onSubmit}
        isLoading={isLoading}
      />
    </Suspense>
  );
};

export default ForgotPasswordModal;
