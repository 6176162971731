import React, { Suspense, lazy } from "react";
import { useLogin } from "../../api/apiHooks";
import { loginRequestKeys } from "../../constants";
import config from "../../../../system/config";
import Loader from "../../../../components/atoms/Loader";
import { dataLayerLoginAuthClick } from "../../../../system/dataLayer";
import { useFingerprint } from "../../../../system/hooks/useFingerprint";
const LoginForm = lazy(() => import("../forms/LoginForm"));

type LoginModalProps = {
  redirectTo?: string;
  isHiddenAfterLoginModal?: boolean;
};

const LoginModal: React.FC<LoginModalProps> = ({
  redirectTo,
  isHiddenAfterLoginModal = false,
}) => {
  const { isLoading, mutate: loginHandler } = useLogin(
    redirectTo || config.user.redirectAfterLogin,
    isHiddenAfterLoginModal
  );
  const { isLoading: isFpLoading, visitorId } = useFingerprint();

  const onSubmit = (values) => {
    const formData = new FormData();
    //shlnn347
    //qwerty123
    formData.append(loginRequestKeys.username, values.username);
    formData.append(loginRequestKeys.password, values.password);
    formData.append(loginRequestKeys.fingerprintId, visitorId);
    loginHandler(formData);
    dataLayerLoginAuthClick();
  };

  return (
    <Suspense fallback={<Loader center fullHeight minHeight={200} />}>
      <LoginForm onSubmit={onSubmit} isLoading={isLoading || isFpLoading} />
    </Suspense>
  );
};

export default LoginModal;
