import React, { useRef, useMemo } from "react";
import { isMobile } from "react-device-detect";
import GameSlider from "../../../components/organisms/GameSlider";
import SliderControl from "../../../components/atoms/SliderControl";
import CustomSlider from "../../../components/molecules/CustomSlider";
import { casinoSliderSettings } from "../../../components/organisms/GameSlider/constatns";
import { gameItem, SLIDER_GAMES_LIMIT } from "../constants";
import { useGetTagGames, useGetRecommendedGames } from "../api/apiHooks";
import GameCardContainer from "./GameCardContainer";
import { isLoggedIn } from "../../../system/helpers/userHelper";
import CasinoGames from "../../../components/organisms/CasinoGames";
import { useHideModal } from "../../modals/hooks";

const RECOMMENDED_SLUG = "recommended-bg";

const sliderSettings = {
  slidesToShow: isMobile ? 1.5 : 2.5,
  slidesToScroll: 1,
  infinite: false,
  arrows: !isMobile,
  className: "welcome-modal-slider",
  draggable: false,
};

type RecommendedGamesProps = {
  className?: string;
  variant: "after-login" | "casino" | "casino-recommended";
  title: string;
  controlLabel?: string;
  controlLabelLink?: string;
};

const RecommendedGames: React.FC<RecommendedGamesProps> = ({
  className,
  variant,
  title,
  controlLabel,
  controlLabelLink,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const sliderRef = useRef<any>(null);
  const hideModal = useHideModal();
  const { data: editorGamesData = { casino: [] } } = useGetTagGames(
    RECOMMENDED_SLUG
  );
  const isUser = isLoggedIn();
  const {
    data: userGamesData,
    isLoading: isLoadingRecommended,
  } = useGetRecommendedGames(isUser);
  const gamesData = useMemo(
    () =>
      userGamesData?.casino?.length > 0
        ? userGamesData
        : isLoadingRecommended
        ? { casino: [] }
        : editorGamesData,
    [editorGamesData, userGamesData, isLoadingRecommended]
  );
  const sortedGames: gameItem[] = gamesData?.casino || [];

  if (variant === "casino-recommended") {
    return (
      <CasinoGames
        title={"Recommended"}
        games={sortedGames}
        isLoading={isLoadingRecommended}
        showBackButton
      />
    );
  }

  if (variant === "after-login") {
    return (
      <div ref={containerRef}>
        <SliderControl
          btnNextOnClick={() => sliderRef.current.slickNext()}
          btnPrevOnClick={() => sliderRef.current.slickPrev()}
          showControlsBtn={sortedGames?.length > sliderSettings.slidesToShow}
          className={"slider__recommends"}
          label={<span className={"recommends__block-title"}>{title}</span>}
        />
        <div className={"custom-slider-container"}>
          <CustomSlider
            isLoading={isLoadingRecommended}
            forwardedRef={sliderRef}
            settings={sliderSettings}
          >
            {sortedGames?.map((item, index) => (
              <GameCardContainer
                item={item}
                key={item.imageUrl}
                position={index}
                category={RECOMMENDED_SLUG}
                afterClick={hideModal}
              />
            ))}
          </CustomSlider>
        </div>
      </div>
    );
  }

  const casinoSliderProps = {
    settings: casinoSliderSettings,
    children: sortedGames
      ?.slice(0, SLIDER_GAMES_LIMIT)
      ?.map((item, index) => (
        <GameCardContainer
          item={item}
          key={item.imageUrl}
          position={index}
          category={RECOMMENDED_SLUG}
        />
      )),
  };

  return casinoSliderProps?.children?.length === 0 ? null : (
    <GameSlider
      ref={containerRef}
      className={className}
      title={title}
      sliderProps={casinoSliderProps}
      controlLabel={controlLabel}
      controlLabelLink={controlLabelLink}
    />
  );
};

export default RecommendedGames;
