import { useMutation, useQuery } from "react-query";
import config from "../../../system/config";
import { getLanguage } from "../../../system/translations/helper";
import {
  CountryInfoList,
  LanguagesList,
  PwaDomain,
  translationResponse,
  TRANSLATION_URL,
  languagesResponse,
  AVAILABLE_LANGUAGES_URL,
  countryInfoResponse,
  COUNTRY_INFO_URL,
} from "../constants";
import { useApiRequest } from "../../../system/hooks/useApiRequest";

export const useGetTranslations = () => {
  const [apiRequest] = useApiRequest();
  return useMutation(
    (locale: string = config.defaultLang): Promise<translationResponse> =>
      apiRequest({
        type: "TRANSLATION",
        request: {
          url: TRANSLATION_URL(locale),
          method: "GET",
          withCredentials: false,
          transformRequest: (data: any, headers: object) => {
            for (let key in headers) {
              delete headers[key];
            }
          },
        },
      })
  );
};

export const useGetLanguages = () => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();

  return useQuery(
    [LanguagesList, lang],
    (): languagesResponse =>
      apiRequest({
        type: "GET_AVAILABLE_LANGUAGES",
        request: {
          url: AVAILABLE_LANGUAGES_URL,
          method: "GET",
        },
      })
  );
};

export const useGetCountryInfo = () => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();
  return useQuery(
    [CountryInfoList, lang],
    (): Promise<countryInfoResponse> =>
      apiRequest({
        type: "GET_COUNTRY_INFO",
        request: {
          url: COUNTRY_INFO_URL,
          method: "GET",
          withCredentials: false,
          transformRequest: (data: any, headers: object) => {
            for (let key in headers) {
              delete headers[key];
            }
          },
        },
      })
  );
};

export const useGetPwaDomain = () => {
  const [apiRequest] = useApiRequest();
  return useQuery(PwaDomain, () => {
    const { getPwaUrl } = config.pwa;
    return apiRequest({
      type: "GET_PWA_DOMAIN",
      request: {
        url: getPwaUrl,
        method: "GET",
        responseType: "text",
        withCredentials: false,
        transformRequest: (data: any, headers: object) => {
          for (let key in headers) {
            delete headers[key];
          }
        },
      },
    });
  });
};
