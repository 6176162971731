import React from "react";
import LazyImg from "../LazyImg";

export type cryptoItemProps = {
  item: { title: string; img: any };
};

const CryptoItem: React.FC<cryptoItemProps> = (props) => {
  const { item } = props;
  return (
    <div className={"cryptopay-btc__list-item"}>
      <LazyImg imgSrc={item.img} alt={item.title} />
      <div className={"cryptopay-btc__list-item-title"}>{item.title}</div>
    </div>
  );
};

export default CryptoItem;
