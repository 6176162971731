import { SHOW_MODAL, HIDE_MODAL, modalReducerType } from "./constants";

type actionType = {
  type: string;
  payload: modalReducerType;
};

const initialState = {
  show: false,
};

const reducer = (
  state = initialState,
  action: actionType
): modalReducerType => {
  const { type, payload } = action;

  switch (type) {
    case SHOW_MODAL:
      return {
        show: true,
        ...payload,
      };
    case HIDE_MODAL:
      return {
        show: false,
      };
    default:
      return state;
  }
};

export default reducer;
