import React, { useCallback, useMemo, useState, useRef } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { gameItem, GAMES_PER_STEP } from "../../../modules/casino/constants";
import Btn from "../../atoms/Btn";
import NotFound from "../../atoms/NotFound";
import GameList from "../../molecules/GameList";
import { ReactComponent as ArrowLeftIcon } from "../../../images/icons/arrow-left.svg";
import { APP_ROUTES } from "../../../system/router/constants";
import { path } from "../../../system/helpers/pathHelper";
import { useTrans } from "../../../system/translations/hooks";

interface CasinoGamesProps {
  className?: string;
  games?: gameItem[];
  selectedProviders?: string[];
  isLoading?: boolean;
  title?: string;
  notFoundDesc?: string;
  showBackButton?: boolean;
  slug?: string;
  searchValue?: string;
}

const CasinoGames: React.FC<CasinoGamesProps> = ({
  className,
  games,
  selectedProviders,
  isLoading,
  title,
  notFoundDesc,
  showBackButton,
  slug,
  searchValue,
}) => {
  const { _t } = useTrans();
  const ref = useRef<HTMLDivElement | null>(null);
  const history = useHistory();
  const [step, setStep] = useState(1);
  const loadMore = useCallback(() => {
    setStep(step + 1);
  }, [step, setStep]);

  const filteredGames = useMemo(
    () =>
      selectedProviders?.length
        ? selectedProviders
            .map((platformCode) =>
              games?.filter(
                (item: gameItem) =>
                  item?.v3?.game?.platform?.filterPlatformCode === platformCode
              )
            )
            .flat(1)
        : games,
    [selectedProviders, games]
  );

  const slicedGames = useMemo(
    () => filteredGames?.slice(0, GAMES_PER_STEP * step),
    [filteredGames, step]
  );

  const goBack = () => {
    history.push(path(APP_ROUTES.casinoPage.allGames));
  };

  return (
    <div ref={ref} className={classNames("casino-games", className)}>
      <div className={"casino-games__header"}>
        {showBackButton && (
          <Btn
            className={"casino-games__back-button"}
            onClick={goBack}
            type={"back-circle"}
            icon={<ArrowLeftIcon />}
          />
        )}
        {title && (
          <h2 className={"casino-games__title"}>
            {_t(`game_tag_category_${title}`)}
          </h2>
        )}
      </div>
      <div className={"casino-games__list"}>
        <GameList
          isLoading={isLoading}
          games={slicedGames}
          loadMore={loadMore}
          hasMore={slicedGames?.length < filteredGames?.length}
          slug={slug}
          searchValue={searchValue}
          providers={selectedProviders}
        />
      </div>
      {!isLoading && filteredGames?.length === 0 && (
        <NotFound className={"casino-games__not-found"} desc={notFoundDesc} />
      )}
    </div>
  );
};

export default CasinoGames;
