import axiosClient from "../api/axiosConfig";
import {
  requestStatuses,
  validateRequest,
  requestConfig,
} from "../api/requestHelper";
import { Dispatch, Action } from "redux";
import { isLoggedIn } from "../helpers/userHelper";
import { addToast } from "../helpers/toast";
import { dataLayerRequestData } from "../dataLayer";
import { logOutAction } from "../../modules/user/actions";

export type requestTypes = {
  request?: requestConfig;
  types?: string[] | undefined;
  meta?: {};
};

export type requestMiddlewareAction = Action & requestTypes;

export default ({ dispatch }) => (next: Dispatch) => (
  action: requestMiddlewareAction
) => {
  const actReq = action.request;
  if (!actReq) {
    return next(action);
  }
  const statuses = requestStatuses(action.type, action.types);
  validateRequest(actReq);

  next({ meta: action.meta, type: statuses.start }); //start request

  return new Promise((resolve, reject) => {
    let startDate = new Date().getTime();
    axiosClient(actReq.withPrefix)
      .request(actReq)
      .then(
        (response: any) => {
          const endDate = new Date().getTime();
          const successAction = {
            meta: action.meta,
            type: statuses.success,
            payload: response.data,
            resHeaders: response.headers,
          };
          const requestStackData = {
            apiUrl: action?.request?.url,
            responseTime: endDate - startDate,
          };
          dataLayerRequestData(requestStackData);
          resolve(successAction);

          return next(successAction); //success request
        },
        (error: any) => {
          const endDate = new Date();
          const errorAction = {
            meta: action.meta,
            type: statuses.error,
            error: error.response,
          };
          /*          const requestStackData = {
            domain: document.location.hostname,
            apiUrl: action?.request?.url,
            dateTime: new Date().toUTCString(),
            responseTime: endDate.getTime() - startDate.getTime(),
            responseStatus: error?.response?.status,
            userAgent: getUA,
          };*/
          if (error?.response?.status === 401) {
            isLoggedIn() && logOutAction()(dispatch);
          }
          if (actReq?.notifyMessage?.error) {
            addToast(actReq.notifyMessage.error, {
              type: "error",
            }); // responce error message
          }
          reject(errorAction);

          return next(errorAction); //error request
        }
      );
  });
};
