import React, { lazy, Suspense } from "react";
import { _t, makeKey } from "../../system/translations/InjectTransContext";
import { pageTitleKey } from "../../system/helpers/urlHelper";
import { Helmet } from "react-helmet";
import Loader from "../../components/atoms/Loader";
import MainTemplate from "../../templates/Main";

const RegistrationSection = lazy(
  () => import("../../components/organisms/RegistrationSection")
);

const SignUpPage = () => {
  return (
    <>
      <Helmet>
        <title>{_t(makeKey("sign_up_page_title", pageTitleKey))}</title>
        <meta
          name="description"
          content={_t(makeKey("sign_up_page_description"))}
        />
      </Helmet>
      <MainTemplate withoutFooter withoutSeoPanel>
        <div className={"sign-up"}>
          <Suspense fallback={<Loader center fullHeight minHeight={1000} />}>
            <RegistrationSection />
          </Suspense>
        </div>
      </MainTemplate>
    </>
  );
};

export default SignUpPage;
