import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as Sentry from "@sentry/react";
import config from "./system/config/index";
const projectEnv: string = process.env.NODE_ENV || "";
const platformEnv: string = process.env.REACT_APP_PLATFORM_ENV || "";

if (projectEnv !== "development") {
  /*  Sentry.init({
    ignoreErrors: ["ChunkLoadError"],
    denyUrls: ["bt-renderer.min.js", "email-decode.min.js"],
    dsn: config.sentry_dsn,
    environment: platformEnv,
    beforeSend(event, hint) {
      const err = hint?.originalException;
      if (!!err && err.toString().indexOf("ChunkLoadError") > -1) {
        return null;
      }
      return event;
    },
  });*/
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
/*serviceWorker.unregister();*/
