import { path } from "./pathHelper";

export const isExternalURL = (url: string) => url.match(/http[s]?\:\/\//i);
export const getQueryParams = (query: string) => new URLSearchParams(query);
export const isPublicDomains = () => {
  const host = window.location.hostname;
  return (
    host.includes("bahsegel") ||
    host.includes("pwa") ||
    host.includes("webcache") ||
    host.includes("localhost")
  );
};
export const isWebCache = () => {
  const host = window.location.hostname;
  return host.includes("webcache");
};

export const pageTitleKey = isPublicDomains() ? "" : "custom";

/*
  Function `linkClick` serves to redirect the user
  to the specified `url` with the transfer of
  a certain `state` for further manipulations
  on the updated route.
*/

export const linkClick = (url, event, history, state, targetRouteCondition) => {
  if (targetRouteCondition) {
    event.preventDefault();
    history.push(path(url), state);
  }
};
