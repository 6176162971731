import React from "react";
import classNames from "classnames";
import Loader from "../../../components/atoms/Loader";
import SportLink, { SportLinkProps } from "../../atoms/SportLink";
import CustomSlider from "../../../components/molecules/CustomSlider";
import { ReactComponent as SportLinksIcon } from "../../../images/icons/sport-links.svg";
import { sliderSettings } from "./constants";
import { useTrans } from "../../../system/translations/hooks";

type SportLinksProps = {
  className?: string;
  isLoading?: boolean;
  items: SportLinkProps[];
};

const SportLinks: React.FC<SportLinksProps> = ({
  className,
  isLoading,
  items,
}) => {
  const { _t } = useTrans();

  return (
    <div className={classNames("sport-links", className)}>
      <div className={"sport-links__header"}>
        <span className={"sport-links__icon"}>
          <SportLinksIcon />
        </span>
        <h3 className={"sport-links__title"}>{_t("sport_links_title")}</h3>
      </div>
      <div className={"sport-links__body"}>
        {isLoading && <Loader />}
        <CustomSlider settings={sliderSettings}>
          {items?.map((item) => (
            <SportLink
              name={item.name}
              img={item.img}
              url={item.url}
              key={item.name}
            />
          ))}
        </CustomSlider>
      </div>
    </div>
  );
};

export default SportLinks;
