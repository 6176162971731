import {
  UPDATE_TFA_METHODS,
  updateTfaMethodPayload,
  LOGOUT_URL,
} from "./constants";
import config from "../../system/config";
import { removeCookie } from "../../system/helpers/cookie";
import { getLanguage } from "../../system/translations/helper";
import { history } from "../../system/store";
import { generatePath } from "react-router";

export const updateTfaMethodsAction = (payload: updateTfaMethodPayload) => (
  dispatch: any
) => dispatch({ type: UPDATE_TFA_METHODS, payload });

export const logOutAction = (
  redirectTo: string = config.redirectAfterLogout
) => (dispatch: any): Promise<any> => {
  removeCookie("isLoggedIn");
  const lang = getLanguage();
  const redirect = redirectTo || history.location.pathname;
  history.push(redirect);
  return dispatch({
    type: "LOGOUT",
    request: {
      url: generatePath(LOGOUT_URL, { lang }),
      method: "GET",
    },
  }).then((data: any) => {
    return data.payload;
  });
};
