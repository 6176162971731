export const ADD_ACTION_TO_STACK = "ADD_ACTION_TO_STACK";
export const CLEAR_ACTION_STACK = "CLEAR_ACTION_STACK";
export const GAMES_FEEDBACK = "GAMES_FEEDBACK";
export const GAMES_FEEDBACK_URL = "/:lang/games/feedback";

export type ZingReducerType = {
  actionStack: GamesFeedbackAction[];
};

export type ZingReducerAction = {
  type: string;
  payload?: GamesFeedbackAction;
};

export enum RecommendationType {
  CasinoGame = "casino:::game_recommendation",
}

export enum FeedbackAction {
  BlockShown = "casino-game-block-shown",
  CardShown = "casino-game-card-shown",
  GameOpen = "casino-game-open",
}

export type FeedbackFilterProducers = string[];

export type FeedbackFilters = {
  filterProducers?: string;
  search?: string;
};

export type BlockShownParams = {
  blockName: string;
  uid: string;
  sessionKey: string;
  playerId: string;
  timestamp: number;
};

export type CardShownParams = {
  gameId: string;
  gameProviderId: string;
  sessionKey: string;
  playerId: string;
  isRecommended: boolean;
  category: string;
  position: number;
  timestamp: number;
  uid: string;
  prevUid: string;
  filters: string;
};

export type GameOpenParams = {
  gameId: string;
  gameProviderId: string;
  sessionKey: string;
  playerId: string;
  category: string;
  timestamp: number;
  uid?: string;
  prevUid?: string;
  position?: number;
};

export type GamesFeedbackUri = {
  recommendationId: string;
  recommendationType?: RecommendationType | "";
};

export type GamesFeedbackParams =
  | BlockShownParams
  | CardShownParams
  | GameOpenParams;

export type GamesFeedbackAction = {
  action: FeedbackAction;
  uri: GamesFeedbackUri;
  parameters: GamesFeedbackParams;
};

export type GamesFeedbackResponse = {
  status: string;
};
