import React, { Suspense, lazy } from "react";
import MainTemplate from "../../templates/Main";
import Loader from "../../components/atoms/Loader";
import { _t, makeKey } from "../../system/translations/InjectTransContext";
import { Helmet } from "react-helmet";
import { isMobile } from "react-device-detect";
import { pageTitleKey } from "../../system/helpers/urlHelper";
const GameTemplate = lazy(() => import("./GameTemplate"));

const GamePage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>{_t(makeKey("game_page_title", pageTitleKey))}</title>
        <meta name="description" content={_t("game_page_description")} />
      </Helmet>
      <MainTemplate
        withoutHeader={isMobile}
        prefixClass={"main-template--game"}
        fixedHeader={false}
        withoutFooter
        withoutSeoPanel
        withoutInstallApp
      >
        <Suspense fallback={<Loader center fullHeight minHeight={1000} />}>
          <GameTemplate />
        </Suspense>
      </MainTemplate>
    </>
  );
};

export default GamePage;
