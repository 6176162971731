import React from "react";
import classNames from "classnames";
import { ReactComponent as NotFoundIcon } from "../../../images/icons/not-found.svg";
import { useTrans } from "../../../system/translations/hooks";

export type NotFoundProps = {
  className?: string;
  title?: string;
  desc?: string;
  withoutIcon?: boolean;
};

const NotFound: React.FC<NotFoundProps> = ({
  className,
  title,
  desc,
  withoutIcon,
}) => {
  const { _t } = useTrans();
  return (
    <div className={classNames("not-found", className)}>
      {withoutIcon || (
        <span className={"not-found__icon"}>
          <NotFoundIcon />
        </span>
      )}
      <h4 className={"not-found__title"}>{title || _t("not_found_title")}</h4>
      {desc && <p className={"not-found__desc"}>{desc}</p>}
    </div>
  );
};

NotFound.defaultProps = {
  withoutIcon: false,
};

export default NotFound;
