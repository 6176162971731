import React from "react";
import { _t, makeKey } from "../../system/translations/InjectTransContext";
import LoginModal from "../../modules/user/components/modals/LoginModal";
import OpenModalLink from "../../components/molecules/OpenModalLink";
import { forgotPasswordModal } from "../../modules/modals";
import { pageTitleKey } from "../../system/helpers/urlHelper";
import { Helmet } from "react-helmet";

const SignInPage = () => {
  return (
    <>
      <Helmet>
        <title>{_t(makeKey("sign_in_page_title", pageTitleKey))}</title>
        <meta
          name="description"
          content={_t(makeKey("sign_in_page_description"))}
        />
      </Helmet>
      <div className={"sign-in"}>
        <div className={"sign-in__form"}>
          <div className={"sign-in__form__title"}>{_t("sign_in_welcome")}</div>
          <div className={"sign-in__form__description"}>
            {_t("sign_in_description")}
          </div>
          <LoginModal />
          <OpenModalLink
            label={"forgot_your_password"}
            modal={forgotPasswordModal}
            position={"left"}
            classname={"sign-in__form__forgot-password"}
          />
        </div>
      </div>
    </>
  );
};

export default SignInPage;
