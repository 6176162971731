import { useQuery } from "react-query";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../system/reducers";
import { getLanguage } from "../../../system/translations/helper";
import { isLoggedIn } from "../../../system/helpers/userHelper";
import { useApiRequest } from "../../../system/hooks/useApiRequest";
import {
  categoryGamesPayloadData,
  categoryMorePopularGamesPayloadData,
  GamesList,
  gamesPayloadData,
  GET_GAMES,
  GET_GAMES_URL,
  GET_MORE_POPULAR_GAMES,
  GET_MOST_POPULAR_GAMES_URL,
  MostPopularGamesList,
  SearchGamesList,
  GET_SEARCH_GAMES,
  GET_SEARCH_GAMES_URL,
  ProvidersList,
  providersPayloadData,
  GET_PROVIDERS,
  GET_PROVIDERS_URL,
  CategoriesList,
  categoriesPayloadData,
  GET_CATEGORIES,
  GET_CATEGORIES_URL,
  GametagCategoriesList,
  gameTagCategoriesPayloadData,
  TagGamesList,
  GET_GAMETAG_CATEGORIES_URL,
  GET_GAMETAG_CATEGORIES,
  RecentlyPlayedGamesList,
  GET_TAG_GAMES_URL,
  GET_TAG_GAMES,
  profileGamesPayloadData,
  GET_RECENTLY_PLAYED_GAMES,
  GET_RECENTLY_PLAYED_GAMES_URL,
  LandingGameProviders,
  landingGameProvidersData,
  GET_LANDING_GAME_PROVIDERS_URL,
  GET_LANDING_GAME_PROVIDERS,
  RecommendedGames,
  GET_RECOMMENDED_GAMES,
  RECOMMENDED_GAMES,
} from "../constants";
import { generatePath } from "react-router";

export const useGetGames = (categorySlug: string, enabled = true) => {
  const [apiRequest] = useApiRequest();
  const isUser = isLoggedIn();
  const lang = getLanguage();
  const { countryInfo } = useSelector(
    (state: ApplicationState) => state.general,
    shallowEqual
  );

  return useQuery(
    [GamesList, categorySlug, lang, isUser],
    (): categoryGamesPayloadData =>
      apiRequest({
        type: GET_GAMES,
        request: {
          url: generatePath(GET_GAMES_URL, {
            limit: "9999",
            offset: "0",
            country: countryInfo.iso2Code,
            lang,
            categorySlug,
          }),
          method: "GET",
        },
      }),
    {
      enabled: enabled,
    }
  );
};

export const useGetMostPopularGames = (
  categorySlug: string,
  enabled = true
) => {
  const [apiRequest] = useApiRequest();
  const isUser = isLoggedIn();
  const lang = getLanguage();
  const { countryInfo } = useSelector(
    (state: ApplicationState) => state.general,
    shallowEqual
  );

  return useQuery(
    [MostPopularGamesList, lang, isUser],
    (): categoryMorePopularGamesPayloadData =>
      apiRequest({
        type: GET_MORE_POPULAR_GAMES,
        request: {
          url: generatePath(GET_MOST_POPULAR_GAMES_URL, {
            days: "10",
            size: "30",
            country: countryInfo.iso2Code,
            lang,
          }),
          method: "GET",
        },
      }),
    {
      enabled: enabled,
    }
  );
};

export const useGetSearchGames = (value: string) => {
  const [apiRequest] = useApiRequest();
  const isUser = isLoggedIn();
  const lang = getLanguage();
  const { countryInfo } = useSelector(
    (state: ApplicationState) => state.general,
    shallowEqual
  );
  return useQuery(
    [SearchGamesList, value, lang, isUser],
    (): gamesPayloadData =>
      apiRequest({
        type: GET_SEARCH_GAMES,
        request: {
          url: generatePath(GET_SEARCH_GAMES_URL, {
            country: countryInfo.iso2Code,
            name: value,
            lang,
          }),
          method: "GET",
        },
      })
  );
};

export const useGetProviders = () => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();
  return useQuery(
    [ProvidersList, lang],
    (): providersPayloadData =>
      apiRequest({
        type: GET_PROVIDERS,
        request: {
          url: generatePath(GET_PROVIDERS_URL, { lang }),
          method: "GET",
        },
      })
  );
};

export const useGetCategories = () => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();
  return useQuery(
    [CategoriesList, lang],
    (): categoriesPayloadData =>
      apiRequest({
        type: GET_CATEGORIES,
        request: {
          url: generatePath(GET_CATEGORIES_URL, { lang }),
          method: "GET",
        },
      })
  );
};

export const useGetGametagCategories = () => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();
  return useQuery(
    [GametagCategoriesList, lang],
    (): gameTagCategoriesPayloadData =>
      apiRequest({
        type: GET_GAMETAG_CATEGORIES,
        request: {
          url: generatePath(GET_GAMETAG_CATEGORIES_URL, { lang }),
          method: "GET",
          notifyMessage: {
            error: "ERROR_GET_GAMETAG_CATEGORIES",
          },
        },
      })
  );
};

export const useGetTagGames = (gameTag: string) => {
  const [apiRequest] = useApiRequest();
  const isUser = isLoggedIn();
  const lang = getLanguage();
  return useQuery(
    [TagGamesList, gameTag, lang, isUser],
    (): gamesPayloadData =>
      apiRequest({
        type: GET_TAG_GAMES,
        request: {
          url: generatePath(GET_TAG_GAMES_URL, { lang, gameTag }),
          method: "GET",
          notifyMessage: {
            error: "ERROR_GET_TAG_GAMES",
          },
        },
      })
  );
};

export const useGetRecentlyPlayedGames = () => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();
  return useQuery(
    [RecentlyPlayedGamesList, lang],
    (): profileGamesPayloadData =>
      apiRequest({
        type: GET_RECENTLY_PLAYED_GAMES,
        request: {
          url: generatePath(GET_RECENTLY_PLAYED_GAMES_URL, { lang }),
          method: "GET",
          notifyMessage: {
            error: "error recently games",
          },
        },
      })
  );
};

export const useGetLandingGameProviders = () => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();
  return useQuery(
    [LandingGameProviders, lang],
    (): landingGameProvidersData =>
      apiRequest({
        type: GET_LANDING_GAME_PROVIDERS,
        request: {
          url: generatePath(GET_LANDING_GAME_PROVIDERS_URL, { lang }),
          method: "GET",
          notifyMessage: {
            error: "ERROR_GET_LANDING_GAME_PROVIDERS",
          },
        },
      })
  );
};

export const useGetRecommendedGames = (isUser) => {
  const lang = getLanguage();
  const [apiRequest] = useApiRequest();

  return useQuery(
    [RecommendedGames, lang],
    () =>
      apiRequest({
        type: RECOMMENDED_GAMES,
        request: {
          url: generatePath(GET_RECOMMENDED_GAMES, { lang }),
          method: "GET",
          notifyMessage: {
            error: "ERROR_GET_LANDING_GAME_PROVIDERS",
          },
        },
      }),
    {
      enabled: isUser,
    }
  );
};
