import {
  BannerList,
  bannersPayloadData,
  bannerTypes,
  BANNER_URL,
} from "../constants";
import { getLanguage } from "../../../system/translations/helper";
import { useQuery } from "react-query";
import { useApiRequest } from "../../../system/hooks/useApiRequest";
import { generatePath } from "react-router";
import { isMobile } from "react-device-detect";

export const useGetBanner = (banner: bannerTypes) => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();

  return useQuery(
    [BannerList, banner, lang],
    () =>
      apiRequest({
        type: "GET_BANNERS",
        request: {
          url: generatePath(BANNER_URL, { lang, banner }),
          method: "GET",
        },
      }),
    {
      select: (data): bannersPayloadData => {
        const { banners, ...rest } = data;

        return {
          banners: banners?.filter((banner) =>
            isMobile ? banner.mobile : banner.desktop
          ),
          ...rest,
        };
      },
    }
  );
};
