import React, { Suspense, lazy } from "react";
import MainTemplate from "../../templates/Main";
import Loader from "../../components/atoms/Loader";
const UserPageTemplate = lazy(() => import("./UserPageTemplate"));

const UserPage = () => {
  return (
    <MainTemplate
      prefixClass={"main-template--user"}
      withoutFooter
      withoutSeoPanel
    >
      <Suspense fallback={<Loader center fullHeight minHeight={1000} />}>
        <UserPageTemplate />
      </Suspense>
    </MainTemplate>
  );
};

export default UserPage;
