export const full_width_games = [
  {
    gameSlug: "keno-1",
    gameProvider: "ORYX_CAS",
  },
  {
    gameSlug: "krn-table-tennis",
    gameProvider: "ORYX_CAS",
  },
  {
    gameSlug: "krn-football",
    gameProvider: "ORYX_CAS",
  },
  {
    gameSlug: "krn-football-league",
    gameProvider: "ORYX_CAS",
  },
  {
    gameSlug: "krn-football-league-match",
    gameProvider: "ORYX_CAS",
  },
  {
    gameSlug: "krn-horses",
    gameProvider: "ORYX_CAS",
  },
  {
    gameSlug: "krn-hounds",
    gameProvider: "ORYX_CAS",
  },
  {
    gameSlug: "krn-motor-racing",
    gameProvider: "ORYX_CAS",
  },
  {
    gameSlug: "aviator",
    gameProvider: "ORYX_CAS",
  },
  {
    gameSlug: "spribe-dice",
    gameProvider: "ORYX_CAS",
  },
  {
    gameSlug: "spribe-goal",
    gameProvider: "ORYX_CAS",
  },
  {
    gameSlug: "spribe-keno",
    gameProvider: "ORYX_CAS",
  },
  {
    gameSlug: "spribe-mines",
    gameProvider: "ORYX_CAS",
  },
  {
    gameSlug: "spribe-hi-lo",
    gameProvider: "ORYX_CAS",
  },
  {
    gameSlug: "spribe-mini-roulette",
    gameProvider: "ORYX_CAS",
  },
  {
    gameSlug: "spribe-plinko",
    gameProvider: "ORYX_CAS",
  },
  {
    gameSlug: "hilo",
    gameProvider: "ORYX_CAS",
  },
  {
    gameSlug: "dice",
    gameProvider: "ORYX_CAS",
  },
  {
    gameSlug: "football-2",
    gameProvider: "ORYX_CAS",
  },
  {
    gameSlug: "horse-racing-roulette",
    gameProvider: "ORYX_CAS",
  },
  {
    gameSlug: "hounds",
    gameProvider: "ORYX_CAS",
  },
  {
    gameSlug: "steeple-chase",
    gameProvider: "ORYX_CAS",
  },
  {
    gameSlug: "table-tennis",
    gameProvider: "ORYX_CAS",
  },
  {
    gameSlug: "horses",
    gameProvider: "ORYX_CAS",
  },
  {
    gameSlug: "motor-racing",
    gameProvider: "ORYX_CAS",
  },
  {
    gameSlug: "mines",
    gameProvider: "ORYX_CAS",
  },
  {
    gameSlug: "goal",
    gameProvider: "ORYX_CAS",
  },
  {
    gameSlug: "keno-2",
    gameProvider: "ORYX_CAS",
  },
  {
    gameSlug: "mini-roulette",
    gameProvider: "ORYX_CAS",
  },
  {
    gameSlug: "plinko",
    gameProvider: "ORYX_CAS",
  },
  {
    gameSlug: "tvbet",
    gameProvider: "TVBET",
  },
  {
    gameSlug: "zeppelin",
    gameProvider: "BETSOLUTIONS",
  },
];
