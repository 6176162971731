import {
  loginModal,
  regModal,
  forgotPasswordModal,
  pwaPromoModal,
  oldSiteInfoModal,
} from "./index";

export const routes = {
  "#login": loginModal(),
  "#registration": regModal,
  "#forgot-password": forgotPasswordModal,
  "#get-app": pwaPromoModal,
  "#new-version": oldSiteInfoModal,
};
