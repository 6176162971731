import React, { useRef, useCallback } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { ApplicationState } from "../../../system/reducers";
import GameCard from "../../../components/atoms/GameCard";
import { hasFun } from "../../game/helpers";
import { gameItem } from "../constants";
import { gameLink } from "../helpers";
import { FeedbackFilters } from "../zing/constants";
import { useGameFeedback } from "../zing/hooks";

type GameCardContainerProps = {
  item: gameItem;
  withContent?: boolean;
  afterClick?: () => void;
  blockUid?: string;
  position?: number;
  category?: string;
  filters?: FeedbackFilters;
};

const GameCardContainer: React.FC<GameCardContainerProps> = ({
  item,
  withContent,
  afterClick,
  blockUid,
  position,
  category,
  filters,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [onGameOpen] = useGameFeedback(
    ref,
    blockUid,
    item,
    category,
    position,
    filters
  );
  const { isFullProfile } = useSelector(
    (state: ApplicationState) => state.user.info.user,
    shallowEqual
  );
  const handleAfterClick = useCallback(() => {
    onGameOpen();
    afterClick && afterClick();
  }, [afterClick, onGameOpen]);

  return (
    <div ref={ref}>
      <GameCard
        key={item.imageUrl}
        image={item.imageUrl}
        name={item.name}
        status={item.status}
        jackpot={item.jackpotAmount}
        playForRealLink={gameLink(item).real}
        playForFunLink={hasFun(item) && gameLink(item).fun}
        isFullProfile={isFullProfile}
        withContent={withContent}
        desc={withContent && item.platformName}
        title={withContent && item.name}
        afterClick={handleAfterClick}
      />
    </div>
  );
};

export default GameCardContainer;
