import React from "react";
import OpenModalLink from "../../components/molecules/OpenModalLink";
import { dataLayerRegModal, dataLayerLoginModal } from "../../system/dataLayer";
import { regModal, loginModal, forgotPasswordModal } from "./index";

export const DontHaveAnAccountModalLink = () => (
  <OpenModalLink
    label={"sign_up"}
    modal={regModal}
    position={"center"}
    text={"dont_have_an_account"}
    onClick={() => dataLayerRegModal("login_modal")}
  />
);

export const AlreadyHaveAnAccountModalLink = () => (
  <OpenModalLink
    classname={"already-have-an-acc-link"}
    label={"log_in"}
    modal={loginModal()}
    position={"center"}
    text={"already_have_an_account"}
    onClick={() => dataLayerLoginModal("reg_modal")}
  />
);

export const ForgotYourPasswordModalLink = () => (
  <>
    <OpenModalLink
      classname={"forgot-pass-link"}
      label={"forgot_your_password"}
      modal={forgotPasswordModal}
      position={"center"}
    />
    <OpenModalLink
      classname={"forgot-pass-link"}
      label={"sign_up"}
      modal={regModal}
      position={"center"}
      text={"dont_have_an_account"}
      onClick={() => dataLayerRegModal("login_modal")}
    />
  </>
);
