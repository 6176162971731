import React, { Suspense, lazy } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { ApplicationState } from "../../../../system/reducers";
import { isMobile } from "react-device-detect";
import SportLinksContainer from "../../../sportbook/components/SportLinksContainer";
import RecommendedGames from "../../../casino/components/RecommendedGames";
import Loader from "../../../../components/atoms/Loader";
import { useTrans } from "../../../../system/translations/hooks";

const UserProgress = lazy(
  () => import("../../../../components/molecules/UserProgress")
);

type AfterLoginModalProps = {
  username: string;
  levelStatus: string;
  progressValue: number;
  leftToNextLevel: number;
};

const AfterLoginModal: React.FC<AfterLoginModalProps> = (props) => {
  const { _t } = useTrans();

  const { info } = useSelector(
    (state: ApplicationState) => state.user,
    shallowEqual
  );
  const {
    username,
    balance: { currentVIPLevelTrans, totalBalance, currency },
    loyaltyPointsStatus: {
      totalPointsForNextLevel,
      remainingPointsForNextLevel,
    },
  } = info.user;

  return (
    <div className="user-after-login-container">
      <div className="user-after-login-top-wrap">
        <div className="user-welcome">
          <span className="user-welcome__username">
            {_t("after_login_modal_hello") + ", " + username + "!"}
          </span>
          <span className="user-welcome__welcome-back">
            {_t("after_login_modal_welcome")}
          </span>
        </div>
        <div className="user-current-progress">
          <Suspense fallback={<Loader center fullHeight minHeight={200} />}>
            <UserProgress
              username={username}
              levelStatus={currentVIPLevelTrans}
              leftToNextLevel={remainingPointsForNextLevel}
              totalPointsToNextLevel={totalPointsForNextLevel}
              userTotalBalanceValue={totalBalance}
              userTotalBalanceCurrency={currency}
              progressBarRadius={isMobile ? 35 : 52}
              showTotalBalance
              enableToNextLvlDisplay
              enableLevelStatus
              //TEMPORARY HIDE BONUS REQUEST FUNCTIONALITY
              // showBonusRequestBtn={false}
            />
          </Suspense>
        </div>
      </div>
      <div className="go-play-games">
        <div className="go-play-games__recommends">
          <RecommendedGames
            variant={"after-login"}
            title={_t("after_login_modal_recommends")}
          />
        </div>
        <SportLinksContainer />
      </div>
    </div>
  );
};

export default AfterLoginModal;
