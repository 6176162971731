import React, { Suspense, lazy } from "react";
import { initialValuesType } from "../forms/ResetPasswordForm";
import { resetPasswordRequestKeys } from "../../constants";
import { useResetPassword, useGetResetPasswordForm } from "../../api/apiHooks";
import { apiErrors } from "../../../../system/helpers/apiErrorHelper";
import Loader from "../../../../components/atoms/Loader";

const ResetPasswordForm = lazy(() => import("../forms/ResetPasswordForm"));

type ResetPasswordModalProps = {
  email: string;
  confirmationCode: string;
};

const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({
  email,
  confirmationCode: resetPasswordKey,
}) => {
  useGetResetPasswordForm(email, resetPasswordKey);
  const { isLoading, error, mutate: resetPasswordHandler } = useResetPassword();
  const responseError = apiErrors(error);

  const onSubmit = (values: initialValuesType) => {
    const formData = new FormData();
    formData.append(resetPasswordRequestKeys.email, email);
    formData.append(
      resetPasswordRequestKeys.resetPasswordKey,
      resetPasswordKey
    );
    formData.append(resetPasswordRequestKeys.password, values.password);
    formData.append(
      resetPasswordRequestKeys.repeatPassword,
      values.repeatPassword
    );
    resetPasswordHandler(formData);
  };

  return (
    <Suspense fallback={<Loader center fullHeight minHeight={200} />}>
      <ResetPasswordForm
        responseError={responseError}
        onSubmit={onSubmit}
        isLoading={isLoading}
      />
    </Suspense>
  );
};

export default ResetPasswordModal;
