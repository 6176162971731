import React from "react";
import { useTrans } from "../../../system/translations/hooks";

const PlaceholderSlide = () => {
  const { _t } = useTrans();

  return (
    <div className={"placeholder-slide"}>
      <h2 className={"placeholder-slide__title"}>{_t("slides_not_loaded")}</h2>
    </div>
  );
};

export default PlaceholderSlide;
