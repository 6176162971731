import { full_width_games } from "./full_width_games";
import prodConfig from "./prod";
import stageConfig from "./stage";
const CONFIG_ENV = process.env.REACT_APP_CONFIG_ENV || "";

const config = {
  sportbook: {},
  gtm_id: "GTM-PC26PD4",
  licence: {
    antillephone_id: "04a96a19-7d16-4edf-b3bd-9253bdecafd6",
  },
  recaptcha_site_key: "6LerfrsUAAAAAOoT6E-4CJYc86xurT5BfMan4KLn",
  phone: {
    defaultMask: "+90 599 999 99 99",
    defaultCode: "+90 5",
  },
  user: {
    updateBalanceDelay: 15000, //in miliseconds
    redirectAfterLogin: null,
    tfaResendCodeInterval: 10, //in sec
  },
  pwa: {
    defaultDomain: "www.bahsegel-spa.com",
    getPwaUrl: "https://kv.dogt.xyz/pwa/bahsegel-spa",
  },
  sentry_dsn: "https://d206c9fb9ae74a95bc2541474a1dcbb6@sentry.dogt.xyz/14",
  country_to_locale: {
    JP: "ja",
    PT: "pt",
    IT: "it",
    ES: "es",
    SE: "sv",
    FI: "fi",
    TR: "tr",
    DE: "de",
    PL: "en",
    IN: "hi",
    CA: "en",
  },
  country_to_currency: {
    PT: "EUR",
    JP: "USD",
    IT: "EUR",
    ES: "EUR",
    SE: "SEK",
    FI: "EUR",
    TR: "TRY",
    IN: "INR",
    BR: "BRL",
    NO: "NOK",
    MX: "USD",
    DE: "EUR",
    CZ: "EUR",
    PL: "EUR",
    HU: "EUR",
    CA: "CAD",
  },
  currenciesSign: { TRY: "₺" },
  cryptopay: {
    mainCurreny: "BTC",
    defaultRate: "BTCEUR",
  },
  support_email: "support@bahsegel.com",
  chat: {
    prod: {
      default: {
        name: "livechat",
        license: 8252841,
      },
      zendesk: {
        name: "zendesk",
        license: "e4c35a69-70e7-4b3e-bd12-b0babb4200cd",
      },
      chaport: {
        name: "chaport",
        license: "623da62c159ddd51add8efc8",
      },
    },
  },
  public_chat_id: "l2UKXadUo",
  full_width_games: full_width_games,
  bet_games: {
    partner: "Bahsegel",
    server: "https://game2.betgames.tv",
    src: "https://game2.betgames.tv/design/client/js/betgames.js",
  },
  csvpush_server: "pushup.go-prod.dogt.xyz",
  requests_to_csv: {
    key: "jwikxr",
    interval: 5000,
  },
  defaultLang: "tr",
  redirectAfterLogout: "/#login",
  euro2020: {
    spreadsheetId: "1bhP3Rm5-PHemcyXQQocf1PNXF1C40ibDS0_sjXlJ8BI",
    accessToken: "AIzaSyDYP6y_FtOKGnAiRH0WkkzSu73vLwiro3g",
  },
  availableLangs: [
    {
      code: "tr",
      name: "T\u00fcrk\u00e7e",
    },
    {
      code: "en",
      name: "English",
    },
  ],
  exchangeRates: {
    loyaltyPoints: 1000,
    cashAmount: 10,
    minAmount: 1000,
  },
  google_optimize_id: "OPT-NQ3Q9PZ",
  zing: {
    interval: 5000,
  },
  appcues: {
    apiKey: "3a64552a-abc8-4654-8212-cff3dce3c017",
    src: "https://fast.appcues.com/94615.js",
  },
};

const configByEnv = CONFIG_ENV === "stage" ? stageConfig : prodConfig;

export default { ...config, ...configByEnv };
