import React, { useState } from "react";
import classNames from "classnames";
import { useHideModal } from "../../../modules/modals/hooks";
import LazyImg from "../../atoms/LazyImg";
import Btn from "../../atoms/Btn";
import NotificationBlock from "../NotificationBlock";
import CircleCreditCardImg from "../../../images/icons/circle-credit-card.svg";
import SafariImg from "../../../images/icons/safari.svg";
import ChromeImg from "../../../images/icons/chrome.svg";
import IosSettingsImg from "../../../images/icons/ios-settings.svg";
import IosToggleImg from "../../../images/icons/ios-toggle.svg";
import IosChromeSettingsImg from "../../../images/icons/ios-chrome-settings.svg";
import IosChromePopupImg from "../../../images/icons/ios-chrome-popup.svg";
import AndroidChromeSettingsImg from "../../../images/icons/android-chrome-settings.svg";
import AndroidChromePopupImg from "../../../images/icons/android-chrome-popup.svg";
import { useTrans } from "../../../system/translations/hooks";

type tabType = "ios" | "android";
type tutorialType = "safari" | "chrome_ios" | "chrome_android" | "";
type DisablePopupBlockerModalProps = {
  link: string;
};

const DisablePopupBlockerModal: React.FC<DisablePopupBlockerModalProps> = ({
  link,
}) => {
  const { _t } = useTrans();

  const [activeTab, setTab] = useState<tabType>("ios");
  const [activeTutorial, setTutorial] = useState<tutorialType>("");
  const hideModal = useHideModal();
  const onContinueClick = (e) => {
    e.preventDefault();
    window.open(link, "_blank");
    hideModal();
  };

  return (
    <div className={"disable-popup-blocker"}>
      {activeTutorial === "" && (
        <div className={"disable-popup-blocker__tabs"}>
          <NotificationBlock
            icon={CircleCreditCardImg}
            titleKey={"disable_popup_blocker_modal_hero_title"}
            btnType={"secondary"}
            linkAfterBtn={
              <a
                href={link}
                className={"disable-popup-blocker__btn-link"}
                onClick={onContinueClick}
              >
                {_t("disable_popup_blocker_modal_hero_btn")}
              </a>
            }
            btnBlock
          />
          <h3 className={"disable-popup-blocker__title"}>
            {_t("disable_popup_blocker_modal_title")}
          </h3>
          <p className={"disable-popup-blocker__desc"}>
            {_t("disable_popup_blocker_modal_desc")}
          </p>
          <div className={"disable-popup-blocker-tabs"}>
            <span
              className={classNames("disable-popup-blocker-tabs__item", {
                "is-active": activeTab === "ios",
              })}
              onClick={() => setTab("ios")}
            >
              {_t("disable_popup_blocker_modal_ios")}
            </span>
            <span
              className={classNames("disable-popup-blocker-tabs__item", {
                "is-active": activeTab === "android",
              })}
              onClick={() => setTab("android")}
            >
              {_t("disable_popup_blocker_modal_android")}
            </span>
          </div>
          {activeTab === "android" && (
            <div
              className={"disable-popup-blocker-nav-item"}
              onClick={() => setTutorial("chrome_android")}
            >
              <LazyImg
                className={"disable-popup-blocker-nav-item__img"}
                imgSrc={ChromeImg}
              />
              <span className={"disable-popup-blocker-nav-item__text"}>
                {_t("disable_popup_blocker_modal_chrome")}
              </span>
            </div>
          )}
          {activeTab === "ios" && (
            <div
              className={"disable-popup-blocker-nav-item"}
              onClick={() => setTutorial("chrome_ios")}
            >
              <LazyImg
                className={"disable-popup-blocker-nav-item__img"}
                imgSrc={ChromeImg}
              />
              <span className={"disable-popup-blocker-nav-item__text"}>
                {_t("disable_popup_blocker_modal_chrome")}
              </span>
            </div>
          )}
          {activeTab === "ios" && (
            <div
              className={"disable-popup-blocker-nav-item"}
              onClick={() => setTutorial("safari")}
            >
              <LazyImg
                className={"disable-popup-blocker-nav-item__img"}
                imgSrc={SafariImg}
              />
              <span className={"disable-popup-blocker-nav-item__text"}>
                {_t("disable_popup_blocker_modal_safari")}
              </span>
            </div>
          )}
        </div>
      )}
      {activeTutorial === "safari" && (
        <div className={"disable-popup-blocker-tutorial"}>
          <p className={"disable-popup-blocker-tutorial-item"}>
            <span className={"disable-popup-blocker-tutorial-item__img"}>
              <LazyImg imgSrc={SafariImg} />
            </span>
            <span className={"disable-popup-blocker-tutorial-item__text"}>
              {_t("disable_popup_blocker_modal_tutorial_safari_title")}
            </span>
          </p>
          <p className={"disable-popup-blocker-tutorial-item"}>
            <span className={"disable-popup-blocker-tutorial-item__img"}>
              <LazyImg imgSrc={IosSettingsImg} />
            </span>
            <span className={"disable-popup-blocker-tutorial-item__text"}>
              {_t("disable_popup_blocker_modal_tutorial_safari_step_1")}
            </span>
          </p>
          <p className={"disable-popup-blocker-tutorial-item"}>
            <span className={"disable-popup-blocker-tutorial-item__img"}>
              <LazyImg imgSrc={SafariImg} style={{ width: "30px" }} />
            </span>
            <span className={"disable-popup-blocker-tutorial-item__text"}>
              {_t("disable_popup_blocker_modal_tutorial_safari_step_2")}
            </span>
          </p>
          <p className={"disable-popup-blocker-tutorial-item"}>
            <span className={"disable-popup-blocker-tutorial-item__img"}>
              <LazyImg imgSrc={IosToggleImg} />
            </span>
            <span className={"disable-popup-blocker-tutorial-item__text"}>
              {_t("disable_popup_blocker_modal_tutorial_safari_step_3")}
            </span>
          </p>
        </div>
      )}
      {activeTutorial === "chrome_ios" && (
        <div className={"disable-popup-blocker-tutorial"}>
          <p className={"disable-popup-blocker-tutorial-item"}>
            <span className={"disable-popup-blocker-tutorial-item__img"}>
              <LazyImg imgSrc={ChromeImg} />
            </span>
            <span className={"disable-popup-blocker-tutorial-item__text"}>
              {_t("disable_popup_blocker_modal_tutorial_chrome_ios_title")}
            </span>
          </p>
          <p className={"disable-popup-blocker-tutorial-item"}>
            <span className={"disable-popup-blocker-tutorial-item__img"}>
              <LazyImg imgSrc={ChromeImg} style={{ width: "30px" }} />
            </span>
            <span className={"disable-popup-blocker-tutorial-item__text"}>
              {_t("disable_popup_blocker_modal_tutorial_chrome_ios_step_1")}
            </span>
          </p>
          <p className={"disable-popup-blocker-tutorial-item"}>
            <span className={"disable-popup-blocker-tutorial-item__img"}>
              <LazyImg imgSrc={IosChromeSettingsImg} />
            </span>
            <span className={"disable-popup-blocker-tutorial-item__text"}>
              {_t("disable_popup_blocker_modal_tutorial_chrome_ios_step_2")}
            </span>
          </p>
          <p className={"disable-popup-blocker-tutorial-item"}>
            <span className={"disable-popup-blocker-tutorial-item__img"}>
              <LazyImg imgSrc={IosChromePopupImg} />
            </span>
            <span className={"disable-popup-blocker-tutorial-item__text"}>
              {_t("disable_popup_blocker_modal_tutorial_chrome_ios_step_3")}
            </span>
          </p>
        </div>
      )}
      {activeTutorial === "chrome_android" && (
        <div className={"disable-popup-blocker-tutorial"}>
          <p className={"disable-popup-blocker-tutorial-item"}>
            <span className={"disable-popup-blocker-tutorial-item__img"}>
              <LazyImg imgSrc={ChromeImg} />
            </span>
            <span className={"disable-popup-blocker-tutorial-item__text"}>
              {_t("disable_popup_blocker_modal_tutorial_chrome_android_title")}
            </span>
          </p>
          <p className={"disable-popup-blocker-tutorial-item"}>
            <span className={"disable-popup-blocker-tutorial-item__img"}>
              <LazyImg imgSrc={ChromeImg} style={{ width: "30px" }} />
            </span>
            <span className={"disable-popup-blocker-tutorial-item__text"}>
              {_t("disable_popup_blocker_modal_tutorial_chrome_android_step_1")}
            </span>
          </p>
          <p className={"disable-popup-blocker-tutorial-item"}>
            <span className={"disable-popup-blocker-tutorial-item__img"}>
              <LazyImg imgSrc={AndroidChromeSettingsImg} />
            </span>
            <span className={"disable-popup-blocker-tutorial-item__text"}>
              {_t("disable_popup_blocker_modal_tutorial_chrome_android_step_2")}
            </span>
          </p>
          <p className={"disable-popup-blocker-tutorial-item"}>
            <span className={"disable-popup-blocker-tutorial-item__img"}>
              <LazyImg imgSrc={AndroidChromePopupImg} />
            </span>
            <span className={"disable-popup-blocker-tutorial-item__text"}>
              {_t("disable_popup_blocker_modal_tutorial_chrome_android_step_3")}
            </span>
          </p>
        </div>
      )}
      {activeTutorial !== "" && (
        <div className={"disable-popup-blocker__footer"}>
          <Btn
            type={"transparent"}
            label={_t("back")}
            onClick={() => setTutorial("")}
          />
          <Btn
            type={"transparent-bordered"}
            label={_t("ok")}
            onClick={hideModal}
          />
        </div>
      )}
    </div>
  );
};

export default DisablePopupBlockerModal;
