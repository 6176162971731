import React, { Suspense, lazy } from "react";
import { useTfaProcess } from "../../api/apiHooks";
import { tfaProcessRequestKeys } from "../../constants";
import config from "../../../../system/config";
import Loader from "../../../../components/atoms/Loader";

const TfaProcessForm = lazy(() => import("../forms/TfaProcessForm"));

const TfaProcessModal = () => {
  const { isLoading, mutate: tfaProcessHandler } = useTfaProcess(
    config.user.redirectAfterLogin
  );
  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append(tfaProcessRequestKeys.code, values.code);
    tfaProcessHandler(formData);
  };

  return (
    <Suspense fallback={<Loader center fullHeight minHeight={200} />}>
      <TfaProcessForm onSubmit={onSubmit} isLoading={isLoading} />
    </Suspense>
  );
};

export default TfaProcessModal;
