import { useQuery } from "react-query";
import { getLanguage } from "../../../system/translations/helper";
import { useApiRequest } from "../../../system/hooks/useApiRequest";
import {
  linksPayloadData,
  HEADER_LINKS_URL,
  HEADER_NAV_MOB_LINKS_URL,
  MENU_LINKS_URL,
} from "../constants";
import { generatePath } from "react-router";

export const useHeaderInfoLinks = () => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();

  return useQuery(
    ["header_links", lang],
    (): linksPayloadData =>
      apiRequest({
        type: "GET_HEADER_LINKS",
        request: {
          url: HEADER_LINKS_URL(getLanguage()),
          method: "GET",
          withCredentials: false,
          transformRequest: (data: any, headers: object) => {
            for (let key in headers) {
              delete headers[key];
            }
          },
        },
      })
  );
};

export const useHeaderNavMobLinks = () => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();
  return useQuery(
    ["header_nav_mob_links", lang],
    (): linksPayloadData =>
      apiRequest({
        type: "GET_HEADER_LINKS",
        request: {
          url: HEADER_NAV_MOB_LINKS_URL(getLanguage()),
          method: "GET",
          withCredentials: false,
          transformRequest: (data: any, headers: object) => {
            for (let key in headers) {
              delete headers[key];
            }
          },
        },
      })
  );
};

export const useMenuLinks = (slug: string) => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();
  return useQuery(
    ["menu_links", slug, lang],
    (): linksPayloadData =>
      apiRequest({
        type: "GET_MENU_LINKS",
        request: {
          url: generatePath(MENU_LINKS_URL, { lang, slug }),
          method: "GET",
          withCredentials: false,
          transformRequest: (data: any, headers: object) => {
            for (let key in headers) {
              delete headers[key];
            }
          },
        },
      })
  );
};
