import { osName } from "react-device-detect";
import {
  DataLayerEvent,
  DataLayerLoginSuccessEvent,
  DataLayerCashboxMethodEvent,
  DataLayerCashboxAmountEvent,
  DataLayerPwaPromptEvent,
  DataLayerAppLaunchEvent,
  DataLayerRequestEvent,
} from "./types";

export const pushToDataLayer = (obj: DataLayerEvent) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(obj);
};

export const pushLocationChange = (location) => {
  pushToDataLayer({
    event: "url_path_changed",
    locationPath: location,
  });
};

export const dataLayerHeaderLogo = () =>
  pushToDataLayer({
    event: "header_click_changeDL",
    clickId: "header_logo",
  });

export const dataLayerHeaderMenu = (linkTo: string) =>
  pushToDataLayer({
    event: "header_click_changeDL",
    clickId: "header_nav_item",
    eventData: {
      action: linkTo,
    },
  });

export const dataLayerFooterNavPanel = (linkTo: string) =>
  pushToDataLayer({
    event: "footer_nav_panel_click_changeDL",
    clickId: "footer_nav_panel_nav_item",
    eventData: {
      action: linkTo,
    },
  });

export const dataLayerFooterMenu = (linkTo: string) =>
  pushToDataLayer({
    event: "footer_menu_click_changeDL",
    clickId: "footer_menu_nav_item",
    eventData: {
      action: linkTo,
    },
  });

export const dataLayerRegModal = (
  origin:
    | "header"
    | "login_modal"
    | "game_card"
    | "sidebar"
    | "sportbook"
    | "hash"
) =>
  pushToDataLayer({
    event: "reg_popup_changeDL",
    origin,
  });

export const dataLayerRegSuccess = () =>
  pushToDataLayer({
    event: "reg_attempted_changeDL",
  });

export const dataLayerRegCreateAccount = () =>
  pushToDataLayer({
    event: "reg_сreate_account",
  });

export const dataLayerLoginModal = (
  origin:
    | "header"
    | "reg_modal"
    | "game_page"
    | "sidebar"
    | "sportbook"
    | "hash"
) =>
  pushToDataLayer({
    event: "auth_popup_changeDL",
    origin,
  });

export const dataLayerLoginAuthClick = () =>
  pushToDataLayer({
    event: "auth_click_login",
  });

export const dataLayerLoginSuccess = (username: string) => {
  const obj: DataLayerLoginSuccessEvent = {
    event: "authorization_changeDL",
    username,
  };
  pushToDataLayer(obj);
};

export const dataLayerDepositModal = (
  origin: "header" | "sportbook" | "after_login_modal" | "mobile_game_header"
) =>
  pushToDataLayer({
    event: "deposit_modal_changeDL",
    origin,
  });

export const dataLayerCasinoAllGames = () =>
  pushToDataLayer({
    event: "casino_all_games_click_changeDL",
    clickId: "casino_all_games_click_button",
  });

export const dataLayerCasinoCategory = (slug: string) =>
  pushToDataLayer({
    event: "casino_category_click_changeDL",
    clickId: "casino_category_click_item",
    eventData: {
      action: slug,
    },
  });

export const dataLayerCasinoProvider = (providerCode: string) =>
  pushToDataLayer({
    event: "casino_provider_click_changeDL",
    clickId: "casino_provider_click_item",
    eventData: {
      action: providerCode,
    },
  });

export const dataLayerCasinoGameTag = (slug: string) =>
  pushToDataLayer({
    event: "casino_game_tag_click_changeDL",
    clickId: "casino_game_tag_click_item",
    eventData: {
      action: slug,
    },
  });

export const dataLayerUserNav = (linkTo: string) =>
  pushToDataLayer({
    event: "user_nav_click_changeDL",
    clickId: "user_nav_click_item",
    eventData: {
      action: linkTo,
    },
  });

export const dataLayerUserTab = (linkTo: string) =>
  pushToDataLayer({
    event: "user_tab_click_changeDL",
    clickId: "user_tab_click_item",
    eventData: {
      action: linkTo,
    },
  });

export const dataLayerCashboxMethod = (
  paymentMethod: string,
  paymentType: string
) => {
  const obj: DataLayerCashboxMethodEvent = {
    event: "cashbox_method_click_changeDL",
    clickId: "cashbox_method_click_item",
    paymentMethod,
    paymentType,
  };
  pushToDataLayer(obj);
};

export const dataLayerCashboxAmout = (
  amount: string,
  paymentMethod: string,
  paymentType: string
) => {
  const obj: DataLayerCashboxAmountEvent = {
    event: "cashbox_amount_click_changeDL",
    clickId: "cashbox_amount_click_item",
    amount,
    paymentMethod,
    paymentType,
  };
  pushToDataLayer(obj);
};

export const dataLayerDepositOpen = () =>
  pushToDataLayer({
    event: "deposit_open",
  });

export const dataLayerDepositStep = (step: number) =>
  pushToDataLayer({
    event: `deposit_step_${step}`,
  });

export const dataLayerDepositResponse = () =>
  pushToDataLayer({
    event: "deposit_response",
  });

export const dataLayerDepositMethod = (id: string) =>
  pushToDataLayer({
    deposit_method_id: id,
  });

export const dataLayerDepositFillOut = () =>
  pushToDataLayer({
    event: "deposit_fill_out",
  });

export const dataLayerWithdrawalOpen = () =>
  pushToDataLayer({
    event: "withdrawal_open",
  });

export const dataLayerWithdrawalMethod = (id: string) =>
  pushToDataLayer({
    withdrawal_method_id: id,
  });

export const dataLayerWithdrawalResponse = () =>
  pushToDataLayer({
    event: "withdrawal_response",
  });

export const dataLayerWithdrawalFillOut = () =>
  pushToDataLayer({
    event: "withdrawal_fill_out",
  });

export const dataLayerPwaPrompt = (
  action: "clicked" | "accepted" | "dismissed"
) => {
  const obj: DataLayerPwaPromptEvent = {
    event: `pwa_prompt_${action}`,
    clickId: `pwa_prompt_${action}_btn`,
    action,
    osName,
  };
  pushToDataLayer(obj);
};
export const dataLayerRequestData = (data: {
  apiUrl: string;
  responseTime: number;
}) => {
  const obj: DataLayerRequestEvent = {
    event: `request_data`,
    apiUrl: data.apiUrl,
    responseTime: data.responseTime,
  };
  pushToDataLayer(obj);
};

export const dataLayerAppLaunch = ({ eventData }) => {
  const obj: DataLayerAppLaunchEvent = {
    event: "app_launch_changeDL",
    ...eventData,
  };
  pushToDataLayer(obj);
};

export const dataLayerUserId = (id: string) =>
  pushToDataLayer({
    user_id: id,
  });
