import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { isIOS } from "react-device-detect";
import { ApplicationState } from "../../../system/reducers";
import { isLoggedIn } from "../../../system/helpers/userHelper";
import { useShowModal } from "../../modals/hooks";
import { regModalWithRedirect } from "../../modals";
import { getPwaLanding } from "../helpers";
import { useGetPwaDomain } from "../../general/api/apiHooks";

const Context = React.createContext(null);

const InstallAppContextProvider = ({ children }) => {
  const [showPanel, setShowPanel] = useState(false);
  const { data: pwaDomain } = useGetPwaDomain();
  const isUser = isLoggedIn();
  const pwaLandingUrl = getPwaLanding(pwaDomain);
  const showRegModal = useShowModal(
    regModalWithRedirect({
      redirectTo: pwaLandingUrl,
    })
  );

  const { sessionKey } = useSelector(
    (state: ApplicationState) => state.user.info.user,
    shallowEqual
  );

  const getApp = () => {
    if (isIOS && !isUser) {
      showRegModal();
    } else {
      window.location.assign(pwaLandingUrl);
    }
  };

  useEffect(() => {
    let timer;
    if (!isLoggedIn() && !sessionKey) {
      timer = setTimeout(() => {
        setShowPanel(true);
      }, 5000);
    }

    return () => clearTimeout(timer);
  }, [sessionKey]);

  return (
    <Context.Provider value={{ showPanel, setShowPanel, getApp }}>
      {children}
    </Context.Provider>
  );
};

export default Context;
export { InstallAppContextProvider };
