import React, { ReactElement } from "react";
import Btn from "../../atoms/Btn";
import LazyImg from "../../atoms/LazyImg";

type DescriptionModalProps = {
  text: string;
  img: string;
  linkTo?: string;
  btnText: string;
  btnIcon?: ReactElement;
  handleClick: any;
  title?: string;
};

const DescriptionModal: React.FC<DescriptionModalProps> = ({
  text,
  img,
  linkTo,
  btnText,
  btnIcon,
  handleClick,
  title,
}) => (
  <div className={"description-modal"}>
    {title && <div className={"description-modal__title"}>{title}</div>}
    <div className={"description-modal__img-wrap"}>
      {img && (
        <LazyImg imgSrc={img} alt={""} className={"description-modal__img"} />
      )}
      {btnText && (
        <div className={"description-modal__btn-desktop"}>
          <Btn
            icon={btnIcon}
            label={btnText}
            size={"lg"}
            type={"action"}
            linkTo={linkTo}
            onClick={handleClick}
            block
          />
        </div>
      )}
    </div>
    <div className={"description-modal__content-wrap"}>
      {text && (
        <div
          className={"description-modal__text"}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      )}
      {btnText && (
        <div className={"description-modal__btn-mobile"}>
          <Btn
            icon={btnIcon}
            label={btnText}
            size={"lg"}
            type={"action"}
            linkTo={linkTo}
            onClick={handleClick}
            block
          />
        </div>
      )}
    </div>
  </div>
);

export default DescriptionModal;
