import React, { Suspense, useEffect, lazy } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { ApplicationState } from "../../../../system/reducers";
import { fillOutProfileKeys } from "../../constants";
import { useFillOutProfile, useGetUserInfo } from "../../api/apiHooks";
import Loader from "../../../../components/atoms/Loader";
import subYears from "date-fns/subYears";
const FillOutProfileForm = lazy(() => import("../forms/FillOutProfileForm"));
type FillOutProfileModalProps = {
  isFullForm?: boolean;
  inPaymentAccess?: boolean;
};

const FillOutProfileModal: React.FC<FillOutProfileModalProps> = ({
  isFullForm,
  inPaymentAccess,
}) => {
  const { isLoading, mutate: fillOutProfileHandler } = useFillOutProfile();
  const { data, mutate: getUserInfo } = useGetUserInfo();
  const { info } = useSelector(
    (state: ApplicationState) => state.user,
    shallowEqual
  );
  useEffect(() => {
    getUserInfo();
  }, []);
  const onSubmit = (values: any) => {
    const defaultBDay = subYears(new Date(), 19);
    const day = defaultBDay.getDate();
    const month = defaultBDay.getMonth() + 1;
    const year = defaultBDay.getFullYear();
    const dayVal = !isFullForm ? day : values.birthDateDay.value;
    const monthVal = !isFullForm ? month : values.birthDateMonth.value;
    const yearVal = !isFullForm ? year : values.birthDateYear.value;
    const formData = new FormData();
    formData.append(fillOutProfileKeys.firstName, values.firstName);
    formData.append(fillOutProfileKeys.lastName, values.lastName);
    formData.append(fillOutProfileKeys.birthDateDay, dayVal);
    formData.append(fillOutProfileKeys.birthDateMonth, monthVal);
    formData.append(fillOutProfileKeys.birthDateYear, yearVal);
    if (isFullForm) {
      // formData.append(fillOutProfileKeys.mobilePhone, values.mobilePhone);
      formData.append(fillOutProfileKeys.gender, values.gender);
      formData.append(fillOutProfileKeys.country, values.country);
      formData.append(fillOutProfileKeys.city, values.city);
      formData.append(fillOutProfileKeys.postalCode, values.postalCode);
      formData.append(fillOutProfileKeys.address, values.address);
    }
    formData.append("_method", "PATCH");
    fillOutProfileHandler(formData);
  };

  return (
    <Suspense fallback={<Loader center fullHeight minHeight={200} />}>
      <FillOutProfileForm
        isFullForm={isFullForm}
        onSubmit={onSubmit}
        isLoading={isLoading || info.isLoading}
        formData={data?.user}
        inPaymentAccess={inPaymentAccess}
      />
    </Suspense>
  );
};

FillOutProfileModal.defaultProps = {
  isFullForm: false,
};

export default FillOutProfileModal;
