import { useParams } from "react-router-dom";
import { ParamsKey, TransKey, UseTransResult } from "./types";
import { useContext } from "react";
import { TransContext } from "./InjectTransContext";
import { modifyTrans } from "./helper";

export const useSetLanguage = () => {
  const { lang } = useParams();
  localStorage.setItem("lang", lang);
  return [lang];
};

export const useTrans = (): UseTransResult => {
  const { messages = {} } = useContext(TransContext);
  const dictionary = Object.keys(messages).length ? messages : {};

  const _t = (key: TransKey, params: ParamsKey = {}): string => {
    return dictionary[key]
      ? modifyTrans(dictionary[key], params)
      : `#${String(key)}#`;
  };
  return { _t };
};
