export const DATE_FORMAT = "yyyy-MM-dd";
export const DISPLAY_DATE_FORMAT = "dd/MM/yyyy";

export const DEFAULT_CURRENCY = "TRY";

export const LOGIN_URL = "/:lang/login_check";
export const REG_URL = "/:lang/b-register-make";
export const LOGOUT_URL = "/:lang/logout";
export const USER_INFO_URL = "/:lang/profile/show.json";
export const KYC_URL = "/:lang/profile/kyc/form.json";
export const BONUSES_URL = "/:lang/profile/bonus/index.json";
export const SEND_BONUS_CODE_URL = "/:lang/profile/bonus/redeem";
export const SEND_CASH_IN_LOYALTY_URL = "/:lang/profile/cash-in-loyalty.json";
export const FILL_OUT_PROFILE_URL =
  "/:lang/profile/quick-signup-finalize/submit";
export const PLAY_HISTORY_URL = "/:lang/profile/history/play/select.json";
export const PAYMENT_HISTORY_URL = "/:lang/profile/history/payment/select.json";
export const PENDING_WITHDRAWALS_URL = "/:lang/profile/withdrawal/pending";
export const CANCEL_WITHDRAWAL_URL =
  "/:lang/profile/withdrawal/:paymentId/cancel.json";
export const BET_DETAILS_URL =
  "/ps/ips/getGameRoundHistory?platformCode=:platformCode&gameTranId=:gameTranId&land=:lang";
export const FORGOT_PASSWORD_URL = "/:lang/forgotPassword";
export const FORGOT_PASSWORD_FORM_URL = "/:lang/forgotPassword.json";
export const RESET_PASSWORD_URL = "/api/v3/:lang/reset-password";
export const RESET_PASSWORD_FORM_URL =
  "/api/v3/:lang/reset-password/:email/:resetCode";
export const VERIFICATION_PHONE_URL = `/:lang/users/phone/confirm`;
export const RESEND_PHONE_VERIFICATION_CODE_URL = `/:lang/users/phone/request`;
export const CHANGE_PASSWORD_URL = `:lang/profile/password/submit`;
export const PROFILE_EDIT_URL = "/:lang/profile/editing/submit";
export const PROFILE_EDIT_FORM_URL = "/:lang/profile/editing/edit.json";
export const UPDATE_BALANCE_URL = "/:lang/profile/update-balance";
export const TFA_URL = "/:lang/profile/preferences/two-factor-auth";
export const TFA_FORM_URL = "/:lang/profile/preferences/two-factor-auth.json";
export const TFA_PROCESS_URL = "/:lang/profile/process-two-fa";
export const TFA_TOKEN_URL = "/:lang/profile/two-fa-token";
export const TFA_RESEND_CODE_URL = "/:lang/profile/resend-two-fa-code";
export const USER_CLAIMS_URL = "/share/claim";
export const USER_CLAIMS_UPDATE_URL = "/share/claim/:uuid";

export type gameItem = {
  desktop: number;
  desktopIdentifier: string;
  gameTags: [];
  imageBackgroundUrl: string;
  imagePlatformUrl: string;
  imageUrl: string;
  jackpotAmount: string;
  mobile: number;
  mobileIdentifier: string;
  name: string;
  platformCode: string;
  platformName: string;
  playForFunText: string;
  playForFunUrl: string;
  playForFunUrlMobile: string;
  playForRealText: string;
  playForRealUrl: string;
  playForRealUrlMobile: string;
  popularIndex: number;
  ratio: string;
  seoDescription: string;
  seoText: string;
  showPlatformImage: number;
  status: string;
};

export type seoLinkTypes = {
  seoTitle: string;
  seoDescription: string;
  seoText: string;
  imageUrl: string;
};

export type gamesPayloadData = {
  casino: gameItem[];
  form: string;
  seoLinkGlobal: seoLinkTypes;
  status: string;
};

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const USER_INFO_START = "USER_INFO_START";
export const USER_INFO_SUCCESS = "USER_INFO_SUCCESS";
export const USER_INFO_ERROR = "USER_INFO_ERROR";

export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";

export const UPDATE_BALANCE_START = "UPDATE_BALANCE_START";
export const UPDATE_BALANCE_SUCCESS = "UPDATE_BALANCE_SUCCESS";
export const UPDATE_BALANCE_ERROR = "UPDATE_BALANCE_ERROR";

export const UPDATE_TFA_METHODS = "UPDATE_TFA_METHODS";
export const USER_CLAIMS_LIST = "user_claims_list";

export const USER_CLAIM_BONUS_TYPE = "manual_bonus";
export const USER_CLAIM_CANCEL_STATUS = "cancel";

export const userClaimBonusOptions = (t) => [
  {
    value: "cashback",
    label: t("bonus_request__option_cashback"),
  },
  // {
  //   value: "freebet",
  //   label: t("bonus_request__option_freebet"),
  // },
  // {
  //   value: "freespin",
  //   label: t("bonus_request__option_freespin"),
  // },
];

export type userBalanceType = {
  bonusBalance: string | number;
  currency: string;
  currentVIPLevel: string;
  currentVIPLevelTrans: string;
  loyaltyPoint: string;
  totalBalance: string | number;
  withdrawableBalance: string;
};
export type loyaltyPointsStatusType = {
  accumulatedLoyaltyPoint: string;
  currentLoyaltyPoint: string;
  currentVIPLevel: string;
  currentVIPLevelTrans: string;
  nextVIPLevel: string;
  nextVIPLevelTrans: string;
  remainingPointsForNextLevel: string;
  totalPointsForNextLevel: string;
};

export type userInfoType = {
  firstName: string;
  isFirstLogin: string | boolean;
  lastLogin: string;
  lastName: string;
  partyId: string;
  quickSignupComplete: boolean;
  registrationStatus: string;
  sessionKey: string;
  termsUpdatedAt: string;
  username: string;
  balance: userBalanceType;
  address: string;
  balanceNeedToRefresh: boolean;
  birthDate: string;
  bonusCode: string;
  city: string;
  country: string;
  currency: string;
  currencySymbol: string;
  depositStorage: any;
  email: string;
  failedAttempts: number;
  kycStatus: string;
  language: string;
  loyaltyPointsStatusNeedToRefresh: boolean;
  mobilePhone: string;
  nickname: string;
  password: any;
  pendingLimitConfirmation: string;
  pendingWithdrawals: [];
  phone: string;
  postalCode: string;
  province: string;
  receiveEmail: string;
  regDate: string;
  repeatPassword: any;
  signupStatusNeedToRefresh: boolean;
  subscribeNeedToRefresh: boolean;
  subscribed: boolean;
  twoFactorAuthCode: string;
  twoFactorAuthPassed: boolean;
  twoFactorAuthMethods: {
    none: boolean;
    sms: boolean;
    email: boolean;
  };
  userNeedToRefresh: boolean;
  vipStatus: string;
  withdrawalStorage: any;
  withdrawalsNeedToRefresh: boolean;
  loyaltyPointsStatus: loyaltyPointsStatusType;
  isFullProfile: boolean;
  isFullProfileWithdrawal: boolean;
};

export type userInfoData = {
  isLoading: boolean;
  form: string;
  status: string;
  user: userInfoType;
  twoFactorAuth?: boolean;
  lastUpdated?: number;
  data: {
    PHPSESSID: string;
  };
};

export type userReducerType = {
  info: userInfoData;
};

export type playHistoryItemType = {
  id: string;
  date: string;
  tranType: string;
  platformCode: string;
  gameId: number;
  gameName: string;
  gameTranId: number;
  amount: string;
  postBalance: string;
  amountReal: string | number;
  postBalanceReal: string;
  amountBonus: string;
  postBalanceBonus: string;
  action: string;
  actionId: number;
  status: string;
};

export type paymentHistoryItemType = {
  amount: string;
  action: string;
  actionId: string;
  status: string;
  requestDate: string;
  processDate: string;
  actionType: string;
  imageUrl: string;
  statusCode: string;
  method: string;
  balance: string;
  paymentId?: string;
};

export type ChangePasswordTypes = {
  oldPassword: string;
  password: string;
  repeatPassword: string;
};

export type bonusItem = {
  statusCode: string;
  id: string;
  bonusPlanName: string;
  status: string;
  triggerDate: string;
  expiryDate: string;
  releaseDate: string;
  amount: string;
  wagerRequirement: string;
  amountWagered: string;
};

export type claimBonusItem = {
  comment: string | null;
  created_at: string;
  updated_at: string;
  type: string;
  url: string | null;
  uuid: string;
  status: string;
  subject: string;
};

export type FillOutProfileStepProps = {
  currentStep: number;
  formik: any;
  isLoading: boolean;
  isCropped?: boolean;
  setStep: (step) => void;
};

export type FillOutProfileFormTypes = {
  onSubmit: (values: any) => any;
  isLoading?: boolean;
  formData?: any;
  isCropped?: boolean;
};

export type bonusesPayloadData = {
  bonuses: bonusItem[];
  form: string;
  seoLinkGlobal: seoLinkTypes;
  status: string;
};

export type betDetailsPayloadData = {
  history: {
    description: string;
    orders: string[];
    responseCode: string;
    signature: string;
    timestamp: string;
  };
  form: string;
  seoLinkGlobal: seoLinkTypes;
  status: string;
};

export type cashInLoyaltyData = {
  form: string;
  seoLinkGlobal: seoLinkTypes;
  status: string;
};

export const regRequestKeys = {
  username: "gamingtec_quick_signup[username]",
  phone: "gamingtec_quick_signup[mobilePhone]",
  email: "gamingtec_quick_signup[email]",
  password: "gamingtec_quick_signup[password]",
  country: "gamingtec_quick_signup[country]",
  currency: "gamingtec_quick_signup[currency]",
  receiveEmail: "gamingtec_quick_signup[receiveEmail]",
  agreeTermAndConditions: "gamingtec_quick_signup[agreeTermAndConditions]",
  recaptcha: "g-recaptcha-response",
};

export const loginRequestKeys = {
  username: "login[_username]",
  password: "login[_password]",
  fingerprintId: "login[_fpid]",
};

export const phoneVerifyKeys = {
  code: "code",
};

export const resendPhoneVerifyCode = {
  phone: "phone",
};

export const changePasswordKeys = {
  oldPassword: "gamingtec_password[oldPassword]",
  password: "gamingtec_password[password]",
  repeatPassword: "gamingtec_password[repeatPassword]",
};

export const fillOutProfileKeys = {
  firstName: "gamingtec_quick_signup_finalize[firstName]",
  lastName: "gamingtec_quick_signup_finalize[lastName]",
  mobilePhone: "gamingtec_quick_signup_finalize[mobilePhone]",
  gender: "gamingtec_quick_signup_finalize[gender]",
  birthDateDay: "gamingtec_quick_signup_finalize[birthDate][day]",
  birthDateMonth: "gamingtec_quick_signup_finalize[birthDate][month]",
  birthDateYear: "gamingtec_quick_signup_finalize[birthDate][year]",
  country: "gamingtec_quick_signup_finalize[country]",
  city: "gamingtec_quick_signup_finalize[city]",
  postalCode: "gamingtec_quick_signup_finalize[postalCode]",
  address: "gamingtec_quick_signup_finalize[address]",
  tokenCompletion: "gamingtec_signup_completion[_token]",
};

export const forgotPasswordRequestKeys = {
  email: "gamingtec_forgot_password_step_1_and_2[email]",
};

export const resetPasswordRequestKeys = {
  email: "gamingtec_forgot_password_step_3[email]",
  resetPasswordKey: "gamingtec_forgot_password_step_3[resetPasswordKey]",
  password: "gamingtec_forgot_password_step_3[password]",
  repeatPassword: "gamingtec_forgot_password_step_3[repeatPassword]",
};

export const kycRequestKeys = {
  frontSideCreditCardScan: "gamingtec_kyc[frontSideCreditCardScan]",
  backSideCreditCardScan: "gamingtec_kyc[backSideCreditCardScan]",
  idType: "gamingtec_kyc[idType]",
  idTypeScan: "gamingtec_kyc[idTypeScan]",
  documentType: "gamingtec_kyc[documentType]",
  documentTypeScan: "gamingtec_kyc[documentTypeScan]",
};

export const profileEditRequestKeys = {
  username: "gamingtec_full_update[username]",
  firstName: "gamingtec_full_update[firstName]",
  lastName: "gamingtec_full_update[lastName]",
  email: "gamingtec_full_update[email]",
  mobilePhone: "gamingtec_full_update[mobilePhone]",
  birthDate: {
    day: "gamingtec_full_update[birthDate][day]",
    month: "gamingtec_full_update[birthDate][month]",
    year: "gamingtec_full_update[birthDate][year]",
  },
  address: "gamingtec_full_update[address]",
  city: "gamingtec_full_update[city]",
  country: "gamingtec_full_update[country]",
  postalCode: "gamingtec_full_update[postalCode]",
  subscribe: "gamingtec_full_update[subscribe]",
  method: "_method",
};

export const playHistoryRequestKeys = {
  date: "gamingtec_history_play[date]",
  hour: "gamingtec_history_play[hour]",
};

export const paymentHistoryRequestKeys = {
  startDate: "gamingtec_history_payment[startDate]",
  endDate: "gamingtec_history_payment[endDate]",
};

export const tfaRequestKeys = {
  methods: "gamingtec_two_fa_methods[methods][]",
  token: "gamingtec_two_fa_methods[_token]",
};

export const tfaProcessRequestKeys = {
  code: "gamingtec_two_fa[code]",
};

export const cashInLoyaltyRequestKeys = {
  convertAmount: "gamingtec_deposit_cash_in_loyalty[convertAmount]",
};

export type updateTfaMethodPayload = {
  none: boolean;
  sms: boolean;
  email: boolean;
};

export type tfaMethodsEnums = "none" | "sms" | "email";

export const excludedCountriesList = ["US"];
