import React, { Suspense, lazy } from "react";
import Loader from "../../components/atoms/Loader";
import { _t, makeKey } from "../../system/translations/InjectTransContext";
import { Helmet } from "react-helmet";
import { pageTitleKey } from "../../system/helpers/urlHelper";
const ErrorPageTemplate = lazy(() => import("./ErrorPageTemplate"));

const NotFound404 = () => {
  return (
    <>
      <Helmet>
        <title>{_t(makeKey("error_404_page_title", pageTitleKey))}</title>
        <meta name="description" content={_t("error_404_page_description")} />
      </Helmet>
      <Suspense fallback={<Loader center fullHeight />}>
        <ErrorPageTemplate
          statusCode={_t("error_404_page_status_code")}
          errorText={_t("error_404_page_text")}
          errorDescription={_t("error_404_page_description")}
          errorBtnText={_t("error_404_btn_text")}
        />
      </Suspense>
    </>
  );
};

export default NotFound404;
