import enFlag from "../../images/icons/flags/GB.svg";
import deFlag from "../../images/icons/flags/DE.svg";
import esFlag from "../../images/icons/flags/ES.svg";
import hiFlag from "../../images/icons/flags/HI.svg";
import itFlag from "../../images/icons/flags/IT.svg";
import jaFlag from "../../images/icons/flags/JA.svg";
import noFlag from "../../images/icons/flags/NO.svg";
import ptFlag from "../../images/icons/flags/PT.svg";
import fiFlag from "../../images/icons/flags/SU.svg";
import svFlag from "../../images/icons/flags/SV.svg";
import trFlag from "../../images/icons/flags/TR.svg";

export const LanguagesList = "languages-list";
export const PwaDomain = "pwa-domain";
export const CountryInfoList = "country-info-list";

const dayTimeStampt = Math.trunc(new Date().getTime() / 1000 / 60 / 60 / 24);

export const TRANSLATION_URL = (locale) => `${locale}/translation`;
export const COUNTRY_INFO_URL = `/ping.json?${dayTimeStampt}`;
export const AVAILABLE_LANGUAGES_URL = "languages.json";

export type translationData = {
  [key: string]: {
    [key: string]: string;
  };
};

export type translationResponse = {
  translations: translationData;
};

export type languagesData = {
  code: string;
  imageUrl?: string;
  name: string;
  url?: string;
};

export type languagesResponse = {
  languages: languagesData[];
};

export type currencyInfo = {
  isDefault?: boolean | null;
  isoCode?: string;
  name?: string;
  numberDecimalPoint?: number | null;
  numericCode?: number | null;
  symbol?: string;
  symbolCode?: number | null;
};

export type countryInfo = {
  iso2Code?: string;
  name?: string;
  phonePrefix?: string;
};

export type countryInfoResponse = {
  countryInfo: countryInfo;
  currencyInfo: currencyInfo;
};

export type requestStackDataType = {
  domain: string;
  apiUrl: string;
  dateTime: string;
  responseTime: number;
  responseStatus: number;
  userAgent: string;
};

export type generalReducerType = {
  selectedLang: string;
  languages: languagesData[];
  availableLangs: string[];
  countryInfo: countryInfo;
  currencyInfo: currencyInfo;
  pwaDomain: string;
  requestStack: requestStackDataType[];
};

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const GET_COUNTRY_INFO_SUCCESS = "GET_COUNTRY_INFO_SUCCESS";
export const GET_COUNTRY_INFO_ERROR = "GET_COUNTRY_INFO_ERROR";
export const GET_AVAILABLE_LANGUAGES_SUCCESS =
  "GET_AVAILABLE_LANGUAGES_SUCCESS";
export const GET_PWA_DOMAIN_SUCCESS = "GET_PWA_DOMAIN_SUCCESS";
export const GET_PWA_DOMAIN_ERROR = "GET_PWA_DOMAIN_ERROR";
export const ADD_REQUEST_TO_STACK = "ADD_REQUEST_TO_STACK";
export const CLEAR_REQUEST_STACK = "CLEAR_REQUEST_STACK";

export const flags = {
  en: enFlag,
  de: deFlag,
  es: esFlag,
  hi: hiFlag,
  it: itFlag,
  ja: jaFlag,
  no: noFlag,
  pt: ptFlag,
  fi: fiFlag,
  sv: svFlag,
  tr: trFlag,
};
