export const BANNER_URL = `/:lang/banner/:banner/9999/9999/bxslider.json`;
export const BannerList = "banners";

export type bannerTypes =
  | "main_slider"
  | "landing_slider"
  | "landing_slider_m"
  | "landing_afterslider_link"
  | "landing_main_nav"
  | "casino_afterslider_link"
  | "sign_up_img"
  | "pwa_promo_modal_img"
  | "footer_psp"
  | "footer_providers"
  | "mainPageRegForm"
  | "tournament_page";

export type bannersItem = {
  bannerUrls: [];
  buttonName: string;
  buttonTitle: string;
  buttonUrl: string;
  desktop: number;
  evolutionGameId: string;
  imageUrl: string;
  link: string;
  mobile: number;
  name: string;
  showOnAllPages: number;
  target: string;
  teaser: string;
  teaserPosition: number;
};

export type seoLinkTypes = {
  seoTitle: string;
  seoDescription: string;
  seoText: string;
  imageUrl: string;
};

export type bannersPayloadData = {
  banners: bannersItem[];
  form: string;
  seoLinkGlobal: seoLinkTypes;
  status: string;
};
