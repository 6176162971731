import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { ModalParams } from "../../components/organisms/Modal";
import { ApplicationState } from "../../system/reducers";
import { hideModalAction, showModalAction } from "./actions";
import { modalReducerType } from "./constants";
import { routes } from "./routes";
import { hashHelper } from "./helpers";

export const useModal = (): modalReducerType =>
  useSelector((state: ApplicationState) => state.modal, shallowEqual);

export const useShowModal = (
  props: ModalParams,
  withParams: boolean = false,
  callback: () => void = null
): any => {
  const dispatch = useDispatch();
  const show = useCallback(() => {
    dispatch(showModalAction({ ...props }));
    callback && callback();
  }, [dispatch, props, callback]);
  const modalWithParams = (params) =>
    dispatch(showModalAction({ ...props, ...params }));
  if (!withParams) {
    return show;
  } else {
    return { modalWithParams };
  }
};

export const useHideModal = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(hideModalAction());
  }, [dispatch]);
};

export const useCheckModal = () => {
  const dispatch = useDispatch();
  return useCallback((location) => {
    if (routes[location.hash]) {
      dispatch(showModalAction(routes[location.hash]));
      hashHelper(location.hash);
    }
  }, []);
};
