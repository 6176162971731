import { linkItem, seoLinkTypes } from "../header/constants";

export const SPORT_LINKS = "sport_links";
export const SPORT_LINKS_URL = "/:lang/menu/combined/sb_quick_links.json";

export interface BTRendererArgs {
  frameUrl?: string;
  widgetName?: string;
  widgetParams?: any;
  brand_id: string;
  lang: string;
  themeName: string;
  target: HTMLElement;
  minFrameHeight: number;
  key?: string;
  fontFamilies?: string[];
  cssUrls?: string[];
  onRouteChange?: () => void;
  onLogin: () => void;
  onRegister: () => void;
  onSessionRefresh: () => void;
  onRecharge: () => void;
  betSlipOffsetBottom?: number | string;
}

interface BTRenderer {
  initialize: (BTRendererArgs) => void;
}

export interface BTRendererConstructor {
  new (): BTRenderer;
}

export type sportLinksPayloadData = {
  links: linkItem[];
  classMenu: string;
  currentPath: string;
  form: string;
  seoLinkGlobal: seoLinkTypes;
  status: string;
};
