import { useCallback, useMemo, MutableRefObject } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { ApplicationState } from "../../../system/reducers";
import { FeedbackAction, FeedbackFilters } from "./constants";
import { gameItem } from "../constants";
import { useSendGamesFeedback } from "./api/apiHooks";

export const useSortGamesByScore = (games: gameItem[]) => {
  return useMemo<gameItem[]>(
    () => games?.sort((a, b) => b?.zingScore - a?.zingScore),
    [games]
  );
};

export const useGameFeedback = (
  ref: MutableRefObject<HTMLDivElement>,
  blockUid: string,
  item: gameItem,
  category: string,
  position: number,
  filters: FeedbackFilters
) => {
  const { sessionKey, partyId } = useSelector(
    (state: ApplicationState) => state.user.info.user,
    shallowEqual
  );
  const { mutate: sendGamesFeedback } = useSendGamesFeedback();
  const onGameOpen = useCallback(() => {
    if (sessionKey) {
      sendGamesFeedback([
        {
          action: FeedbackAction.GameOpen,
          uri: {
            recommendationId: item?.zingRecommendationId || null,
          },
          parameters: {
            gameId: isMobile ? item?.mobileGameId : item?.desktopGameId,
            gameProviderId: item?.platformCode,
            position: position,
            category: category,
            sessionKey: sessionKey,
            playerId: partyId,
            timestamp: new Date().getTime(),
          },
        },
      ]);
    }
  }, [item, category]);

  return [onGameOpen] as const;
};
