import React from "react";
import classNames from "classnames";
import Btn from "../../atoms/Btn";
import { useHideModal } from "../../../modules/modals/hooks";
import LazyImg from "../../atoms/LazyImg";
import { useTrans } from "../../../system/translations/hooks";

const className = "notification-block";
export type notificationBlockProps = {
  icon?: string;
  titleKey?: string;
  titleMod?: string;
  textKey?: string;
  textMod?: string;
  italicKey?: string;
  textDescription?: string;
  btnLabelKey?: string;
  btnType?: string;
  btnBlock?: boolean;
  link?: string;
  showChat?: boolean;
  openSupportChat?: () => void;
  linkAfterBtn?: React.ReactElement;
  refNum?: string | number;
  withOutTrans?: boolean;
};

const NotificationBlock: React.FC<notificationBlockProps> = ({
  icon,
  titleKey,
  titleMod,
  textKey,
  textMod,
  italicKey,
  btnLabelKey,
  btnType,
  btnBlock,
  link,
  showChat,
  openSupportChat,
  linkAfterBtn,
  refNum,
  withOutTrans,
  textDescription,
}) => {
  const { _t } = useTrans();
  const hideModal = useHideModal();
  return (
    <div className={`${className}__wrap`}>
      {icon && (
        <div className={`${className}__icon-wrap`}>
          <LazyImg imgSrc={icon} alt={"checkmark"} />
        </div>
      )}
      {titleKey && (
        <h3
          className={classNames(`${className}__title`, {
            [`${className}__title--${titleMod}`]: titleMod,
          })}
        >
          {withOutTrans ? titleKey : _t(titleKey)}
        </h3>
      )}
      {textKey && (
        <div
          className={classNames(`${className}__text`, {
            [`${className}__text--${textMod}`]: textMod,
          })}
        >
          {refNum
            ? `${withOutTrans ? textKey : _t(textKey)} Ref# ${refNum}`
            : withOutTrans
            ? textKey
            : _t(textKey)}
        </div>
      )}
      {textDescription && (
        <div className={`${className}__description`}>
          {withOutTrans ? textDescription : _t(textDescription)}
        </div>
      )}
      {italicKey && (
        <div className={`${className}__italic`}>{_t(italicKey)}</div>
      )}
      {btnLabelKey && (
        <Btn
          loading={openSupportChat && !showChat}
          disabled={openSupportChat && !showChat}
          className={`${className}__btn`}
          label={withOutTrans ? btnLabelKey : _t(btnLabelKey)}
          type={btnType}
          onClick={openSupportChat || (!link && hideModal)}
          linkTo={link}
          block={btnBlock}
        />
      )}
      {!!linkAfterBtn && linkAfterBtn}
    </div>
  );
};

export default NotificationBlock;
