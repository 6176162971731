import React, { Suspense, useEffect, lazy } from "react";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useCheckModal } from "../../modules/modals/hooks";
import classNames from "classnames";
import { SeoPanelProps } from "../../components/atoms/SeoPanel";
import HeaderWrap from "../../modules/header/components";
import { useCheckResetPassword } from "../../modules/user/hooks";
import Loader from "../../components/atoms/Loader";
import { pushLocationChange } from "../../system/dataLayer";

const InstallApp = lazy(
  () => import("../../modules/pwa/components/InstallApp")
);

const FooterWrap = lazy(
  () =>
    new Promise((resolve, reject) => {
      return setTimeout(
        () =>
          import("../../modules/footer/components").then((res: any) => {
            resolve(res);
          }),
        3000
      );
    })
);
const SeoPanel = lazy(() => import("../../components/atoms/SeoPanel"));

type MainTemplateProps = {
  withoutHeader?: boolean;
  children: React.ReactNode;
  withoutFooter?: boolean;
  withoutSeoPanel?: boolean;
  withoutInstallApp?: boolean;
  withRegForm?: boolean;
  prefixClass?: string;
  seoPanelProps?: SeoPanelProps;
  fixedHeader?: boolean;
};

declare global {
  interface Window {
    ga: any;
  }
}

const MainTemplate: React.FC<MainTemplateProps> = ({
  withoutHeader,
  children,
  withoutFooter,
  withoutInstallApp,
  prefixClass,
  withoutSeoPanel,
  seoPanelProps,
  fixedHeader,
}) => {
  const location = useLocation();
  const checkModal = useCheckModal();

  useEffect(() => {
    window.scrollTo(0, 0);
    checkModal(location);
    if (!!window.ga) {
      window.ga("set", "page", `${location.pathname}.html`);
      window.ga("send", "pageview");
    }
    pushLocationChange(location.pathname);
  }, [location.pathname]);

  useCheckResetPassword();

  return (
    <div
      className={classNames("main-template", prefixClass, {
        "main-template--fixed-header": fixedHeader,
      })}
    >
      {!withoutHeader && (
        <div className={"main-template__header"}>
          <HeaderWrap />
          {!withoutInstallApp && (
            <Suspense fallback={<div />}>
              <InstallApp />
            </Suspense>
          )}
        </div>
      )}
      <div
        className="main-template__container"
        style={
          fixedHeader
            ? isMobile
              ? {
                  paddingTop: "52px",
                }
              : {
                  paddingTop: "68px",
                }
            : null
        }
      >
        {children}
      </div>
      {!withoutSeoPanel && (
        <Suspense fallback={<Loader center minHeight={300} fullHeight />}>
          <SeoPanel {...seoPanelProps} />
        </Suspense>
      )}
      {!withoutFooter && (
        <div className={"main-template__footer"}>
          <Suspense
            fallback={
              <Loader center fullHeight minHeight={isMobile ? 568 : 667} />
            }
          >
            <FooterWrap />
          </Suspense>
        </div>
      )}
    </div>
  );
};

MainTemplate.defaultProps = {
  withoutHeader: false,
  withoutFooter: false,
  withoutInstallApp: true,
  withoutSeoPanel: false,
  fixedHeader: true,
};

export default MainTemplate;
