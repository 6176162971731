import React, { Suspense, useMemo, lazy, useEffect } from "react";
import { cashboxPaymentType } from "../constants";
import { useGetMethods } from "../api/apiHooks";
import { isDeposit } from "../helper";
import { useCashboxVersion } from "../hooks";
import Loader from "../../../components/atoms/Loader";
import {
  dataLayerDepositOpen,
  dataLayerWithdrawalOpen,
} from "../../../system/dataLayer";
const CashboxPanel = lazy(() => import("./CashboxPanel"));

type CashboxTypes = {
  paymentType?: cashboxPaymentType;
  compressedCashbox?: boolean;
};

const Cashbox: React.FC<CashboxTypes> = ({
  paymentType,
  compressedCashbox,
}) => {
  const { data, isLoading } = useGetMethods(paymentType);
  const cashboxVersion = useCashboxVersion();
  const methods = isDeposit(paymentType)
    ? data?.depositMethods
    : data?.withdrawalMethods;
  const methodsList = useMemo(
    () => methods?.sort((a, b) => a.position - b.position),
    [methods]
  );

  useEffect(() => {
    if (isDeposit(paymentType)) {
      dataLayerDepositOpen();
    } else {
      dataLayerWithdrawalOpen();
    }
  }, []);

  if (isLoading) {
    return <Loader center />;
  }

  return (
    <Suspense fallback={<Loader center fullHeight />}>
      <CashboxPanel
        paymentsResponce={data}
        paymentType={paymentType}
        methods={methodsList}
        compressedCashbox={compressedCashbox}
        version={cashboxVersion}
      />
    </Suspense>
  );
};

Cashbox.defaultProps = {
  paymentType: "deposit",
};

export default Cashbox;
