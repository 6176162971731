import axios from "axios";
import backendUrl from "./apiUrl";
import { pwaDetector } from "../../modules/pwa/helpers";

const isPWA = pwaDetector();

const axiosClient = (withPrefix: boolean = true) =>
  axios.create({
    baseURL: withPrefix ? backendUrl : backendUrl.split("/backend")[1],
    responseType: "json",
    withCredentials: true,
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      "X-Platform": isPWA ? "PWA" : "Default",
    },
  });

export default axiosClient;
