import React, { useState, useMemo, lazy, Suspense } from "react";
import MainLogo from "../../atoms/MainLogo";
import {
  footerNavItemsMobile,
  mobNavbarDepositItem,
  headerNavItems,
} from "./constants";
import { NavItemPropsType } from "../../atoms/NavItem";
import { ReactComponent as HamburgerLogo } from "../../../images/elements/hamburger-menu.svg";
import ChatIcon from "../../../images/icons/navbar/chat.svg";
import { isMobile } from "react-device-detect";
import { isLoggedIn } from "../../../system/helpers/userHelper";
import { path } from "../../../system/helpers/pathHelper";
import {
  dataLayerHeaderLogo,
  dataLayerHeaderMenu,
  dataLayerFooterNavPanel,
} from "../../../system/dataLayer";
import Btn from "../../atoms/Btn";
import { useLiveChat } from "../../../modules/chat/hooks";
import classNames from "classnames";
import { useHistory, useLocation } from "react-router-dom";
import { linkClick } from "../../../system/helpers/urlHelper";
import { useTrans } from "../../../system/translations/hooks";

const UserInfo = lazy(() => import("../../molecules/UserInfo"));
const NotLoggedInfo = lazy(() => import("./NotLoggedInfo"));
const NavBar = lazy(() => import("../../molecules/NavBar"));
const SidebarWrap = lazy(() => import("../../../modules/sidebar/components"));

type HeaderProps = {
  links?: NavItemPropsType[];
  linksLoaded?: boolean;
};

const Header: React.FC<HeaderProps> = ({ links, linksLoaded }) => {
  const { _t } = useTrans();
  const location = useLocation();
  const history = useHistory();

  const [openSidebar, setToggleSidebar] = useState(false);
  const isUser = isLoggedIn();
  const { showChat, supportBtnClick } = useLiveChat();
  let navbarItems;

  const desktopLinks = useMemo(
    () =>
      links?.map((link) => ({
        ...link,
        ...headerNavItems[link.classLink],
      })),
    [links, headerNavItems]
  );

  const navLinks = isMobile ? links : desktopLinks;
  const headerNavLinksWithIcons = navLinks?.map((link) => ({
    ...link,
    ...footerNavItemsMobile[link.classLink],
  }));

  if (isMobile) {
    headerNavLinksWithIcons.push({
      customComponent: (
        <Btn
          label={_t("footer_nav_support")}
          icon={<img src={ChatIcon} alt="chat" />}
          className={"nav-item nav-item--with-icon nav-item--chat-btn"}
          onClick={supportBtnClick}
          hidden={!showChat}
        />
      ),
    });
  }
  if (isMobile && isUser) {
    headerNavLinksWithIcons.splice(2, 0, {
      ...mobNavbarDepositItem,
      name: _t("user_info_deposit"),
    });
  }
  navbarItems = () => headerNavLinksWithIcons;

  const isSportsBookOrInPlayRoute =
    location.pathname.includes("sportsbook") ||
    location.pathname.includes("in-play");

  const handleDesktopLinkClick = (item, e) => {
    const { url } = item;

    dataLayerHeaderMenu(url);

    linkClick(
      url,
      e,
      history,
      "sportsbook_link",
      url.includes("sportsbook") || url.includes("in-play")
    );
  };

  return (
    <div className="header">
      {isMobile && (
        <div className="hamburger-menu" onClick={() => setToggleSidebar(true)}>
          <HamburgerLogo />
        </div>
      )}
      <SidebarWrap
        handleSidebarChange={setToggleSidebar}
        openSidebar={openSidebar}
      />
      <MainLogo linkTo={path("/")} onClick={() => dataLayerHeaderLogo()} />
      <Suspense fallback={<div />}>
        <NavBar
          routeShouldMatch
          items={navbarItems()}
          className={
            isMobile
              ? classNames("footer-navigation-panel", {
                  hidden: isSportsBookOrInPlayRoute,
                })
              : "header-navigation-panel"
          }
          withTrans={!linksLoaded}
          onClickItem={(item, e) =>
            isMobile
              ? dataLayerFooterNavPanel(item.url)
              : handleDesktopLinkClick(item, e)
          }
          addLocaleToLink
          showIcons={isMobile}
        />
      </Suspense>
      {isUser ? (
        <Suspense fallback={<div />}>
          <UserInfo showChat={showChat} openSupportChat={supportBtnClick} />
        </Suspense>
      ) : (
        <Suspense fallback={<div />}>
          <NotLoggedInfo
            showChat={showChat}
            openSupportChat={supportBtnClick}
          />
        </Suspense>
      )}
    </div>
  );
};

Header.defaultProps = {
  links: [],
};

export default Header;
