import { seoLinkTypes } from "../casino/constants";
import { userInfoType } from "../user/constants";
import * as Yup from "yup";
import { isValidIBANNumber } from "./helper";
import { shallowEqual, useSelector } from "react-redux";
import { ApplicationState } from "../../system/reducers";
import ListCrypto from "../../components/molecules/ListCrypto";
import React from "react";

export const GET_CASHBOX_METHODS_URL = `/api/v3/:lang/profile/:type/methods.json`;
export const GET_METHOD_AMOUNT_DETAILS =
  "/api/v3/:lang/profile/:type/:method/amount.json";
export const POST_METHOD_AMOUNT_DETAILS = `/api/v3/:lang/profile/:type/:method/amount`;
export const GET_METHOD_BONUSES = `/api/v3/:lang/profile/deposit/:method/bonus`;
export const POST_METHOD_BONUSES = `/api/v3/:lang/profile/deposit/:method/bonus/select/:bonusId`;
export const GET_WITHDRAWAL_PROCESS = `/api/v3/:lang/profile/withdrawal/process/:method`;
export const POST_WITHDRAWAL_PROCESS = `/api/v3/:lang/profile/withdrawal/process/:method`;

export enum CashboxVersion {
  V1 = "v1",
  V2 = "v2",
}

export type methodItem = {
  assetUrl: string;
  code: string;
  conversionRequired: boolean;
  depositAmounts: [];
  feeRate: string;
  frontendInvocation: string;
  isAllowQuickSignUp: boolean;
  isVoucher: boolean;
  maximumDeposit?: string;
  maximumWithdrawal?: string;
  methodRoute: string;
  minimumDeposit?: string;
  minimumWithdrawal?: string;
  name: string;
  paymentMethod: string;
  position: number;
};

export type methodsPayloadData = {
  depositMethods?: methodItem[];
  withdrawalMethods?: methodItem[];
  methodCollection: {
    depositMethods: methodItem[];
  };
  activeBonuses: [];
  form: string;
  seoLinkGlobal: seoLinkTypes;
  status: string;
  user: userInfoType;
};

export type fieldsSettingsItemType = {
  [key: string]: {
    disabled?: boolean;
    value?: any;
    hidden?: boolean;
  };
};

export type fieldsSettingsType = {
  [key: string]: fieldsSettingsItemType;
};

export const amountRequestKeys = {
  amout: "gamingtec_deposit_amount[amount]",
  amountBlackbox: "gamingtec_deposit_amount[blackbox]",
  token: "gamingtec_deposit_amount[_token]",
};

export type bonusType = {
  bonusId: string;
  bonusUrl?: string;
  description?: string;
  bonusType?: string;
  bonusTitle?: string;
  imageUrl?: string;
  teaser?: string;
};

export type propertiesItems = {
  attr: { [key: string]: string };
  autocomplete: string;
  propertyOrder: number;
  title: string;
  type: string;
  enum?: string[];
  enum_titles?: string[];
};

export type processFormSchemaProperties = {
  [key: string]: propertiesItems;
};

export type processFormSchema = {
  properties: processFormSchemaProperties;
  required: string[];
  title: string;
  type: string;
  attr?: { [key: string]: string | boolean };
};

export type processDetailsPayload = {
  form: string;
  formSchema: processFormSchema;
  formType: string;
  method: string;
  status: string;
  template: string;
  submitUrl: string;
};

export type getAmountPayload = {
  conversionRate: string | number;
  depositMethod: methodItem;
  currencies: { amountToSelect: string; amountToAccount: string };
  depositInfo: null | string;
  form: string;
  formSchema: processFormSchema;
  formType: string;
  method: string;
  status: string;
  processUrl: string;
};

export type methodProcessConfirmation = {
  paymentId: string;
  continueUrl: string;
  dateLimit: null | string;
  depositFields: [];
  entity: null;
  reference: null;
};

export type methodProcessPayload = {
  confirmation: methodProcessConfirmation;
  confirmationUrl: string;
  form: string;
  status: string;
  systemMessages: {
    errors: [{ field: string; message: string }];
  };
};

export type methodWithdrawalProcessPayload = {
  continueUrl: string;
  paymentId: string;
  confirmation?: methodProcessConfirmation;
  confirmationUrl: string;
  form: string;
  status: string;
  systemMessages: {
    errors: [{ field: string; message: string }];
  };
};

export type postMethodAmountPayload = {
  conversionRate: number;
  depositInfo: null;
  depositMethod: methodItem;
  form: string;
  formSchema: processFormSchema;
  method: string;
  processUrl: string;
  status: string;
};

export type bonusesPayloadData = {
  bonuses: bonusType[];
};

export type cashboxPaymentType = "deposit" | "withdrawal";

export const exceptionInDetailsWithdrawal = ["amount", "blackbox"];

export const methodsWithCompletedModal: string[] = [
  "TRPAY_BANKWIRE",
  "TRPAY_BANKWIRE2",
];

export const depositFieldsValidation = {
  MONEYBOOKERS: (_t) =>
    Yup.object().shape({
      email: Yup.string()
        .email(_t("invalid_email"))
        .matches(/^[A-z+0-9+@+.]+$/, _t("invalid_email"))
        .required(_t("required")),
    }),
  NETELLER_V2: (_t) =>
    Yup.object().shape({
      accountId: Yup.string()
        .email(_t("invalid_email"))
        .matches(/^[A-z+0-9+@+.]+$/, _t("invalid_email"))
        .required(_t("required")),
    }),
  TRPAY_BANKWIRE: (_t) =>
    Yup.object().shape({
      bankName: Yup.string().required(_t("required")),
    }),
  TRPAY_BANKWIRE2: (_t) =>
    Yup.object().shape({
      bankName: Yup.string().required(_t("required")),
    }),
};

export const withdrawalFieldsValidation = {
  MONEYBOOKERS: (_t) =>
    Yup.object().shape({
      skrillAccountEmail: Yup.string()
        .email(_t("invalid_email"))
        .matches(/^[A-z+0-9+@+.]+$/, _t("invalid_email"))
        .required(_t("required")),
    }),
  INPAY: (_t) =>
    Yup.object().shape({
      bankAddress: Yup.string()
        .min(4, _t("error_bankAddress_to_short"))
        .max(100, _t("error_bankAddress_to_long"))
        /*        .matches(/^[A-z+0-9+@+.]+$/, _t("invalid_email"))*/
        .required(_t("required")),
      bankName: Yup.string()
        .min(4, _t("error_bankName_to_short"))
        .max(30, _t("error_bankName_to_long"))
        .required(_t("required")),
      branchCode: Yup.number()
        .typeError("error_branchCode_must_be_number")
        .required(_t("required")),
      swift: Yup.string()
        .matches(
          /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/,
          _t("error_swift_no_match_pattern")
        )
        .required(_t("required")),
      iban: Yup.string()
        .required(_t("required"))
        .test("validIban", _t("error_iban_invalid"), (val) =>
          isValidIBANNumber(val)
        ),
    }),
};

export const useWithdrawalFieldsSettings = (): fieldsSettingsType => {
  const userInfo = useSelector(
    (state: ApplicationState) => state.user.info.user,
    shallowEqual
  );
  return {
    "withdrawal.TRPAY_BANKWIRE": {
      cellPhone: {
        value: "0000000000",
      },
      tcIdentNumber: {
        value: "00000000000",
      },
      ibanNumber: {
        value: "0000000000",
      },
    },
    "withdrawal.TRPAY_BANKWIRE2": {
      cellPhone: {
        value: "0000000000",
      },
      tcIdentNumber: {
        value: "00000000000",
      },
      ibanNumber: {
        value: "0000000000",
      },
    },
    "withdrawal.MONEYBOOKERS": {
      skrillAccountEmail: {
        disabled: true,
        value: userInfo.email,
        hidden: false,
      },
    },
  };
};
export const useDepositFieldsSettings = () => {
  const userInfo = useSelector(
    (state: ApplicationState) => state.user.info.user,
    shallowEqual
  );
  return {
    TRPAY_CEPBANK: {
      bankCustomerNumber: {
        disabled: true,
        value: "",
        hidden: true,
      },
      bankKeyNumber: {
        disabled: true,
        value: "",
        hidden: true,
      },
      bankPassword: {
        disabled: true,
        value: "",
        hidden: true,
      },
      turkishIdExpiryDate: {
        disabled: true,
        value: "",
        hidden: true,
      },
      dateOfBirth: {
        disabled: false,
        value: userInfo.birthDate.split(" ")[0],
        hidden: false,
      },
    },
  };
};
export const withdrawalKYCAmount: number = 5000;
export const wdStatuses = {
  completed: "COMPLETED",
  pending: "PENDING",
  cancelled: "CANCELLED",
  rejected: "REJECTED",
};

export const OPEN_IN_IFRAME = [];

export type paymentCryptopayInfo = {
  IS_CRYPTOPAY_BTC: {
    topComponent: React.FC;
  };
};

export const paymentAdditionalInfo: paymentCryptopayInfo = {
  IS_CRYPTOPAY_BTC: {
    topComponent: ListCrypto,
  },
};

export const EMPTY_TRANS_KEY_REGEX = /\#(.*?)\#/;
