import { formatLanguages } from "./helper";
import {
  CHANGE_LANGUAGE,
  GET_AVAILABLE_LANGUAGES_SUCCESS,
  GET_COUNTRY_INFO_SUCCESS,
  GET_COUNTRY_INFO_ERROR,
  GET_PWA_DOMAIN_SUCCESS,
  GET_PWA_DOMAIN_ERROR,
  ADD_REQUEST_TO_STACK,
  CLEAR_REQUEST_STACK,
  generalReducerType,
} from "./constants";
import config from "../../system/config";
import { getLanguage } from "../../system/translations/helper";

const defaultCurrencyInfo = {
  isDefault: null,
  isoCode: "",
  name: "",
  numberDecimalPoint: null,
  numericCode: null,
  symbol: "",
  symbolCode: null,
};

const defaultCountryInfo = {
  iso2Code: "",
  name: "",
  phonePrefix: "",
};

const initialState: generalReducerType = {
  selectedLang: getLanguage() || config.defaultLang,
  languages: config.availableLangs,
  availableLangs: [],
  countryInfo: defaultCountryInfo,
  currencyInfo: defaultCurrencyInfo,
  pwaDomain: "",
  requestStack: [],
};

const reducer = (state: generalReducerType = initialState, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        selectedLang: action.val,
      };
    case GET_AVAILABLE_LANGUAGES_SUCCESS:
      return {
        ...state,
        languages: action.payload?.languages,
        availableLangs: formatLanguages(action.payload),
      };
    case GET_COUNTRY_INFO_SUCCESS:
    case GET_COUNTRY_INFO_ERROR:
      const resGeo = action?.resHeaders
        ? action?.resHeaders["x-geo-info"]
        : null;
      const countryCode = resGeo ? resGeo.split(" ")[1] : "TR";
      const { country_to_locale, country_to_currency } = config;
      return {
        ...state,
        countryInfo: {
          iso2Code: countryCode,
        },
        currencyInfo: {
          isoCode: country_to_locale[countryCode],
        },
      };
    case GET_PWA_DOMAIN_SUCCESS:
      return {
        ...state,
        pwaDomain: action.payload,
      };
    case GET_PWA_DOMAIN_ERROR:
      return {
        ...state,
        pwaDomain: config.pwa.defaultDomain,
      };
    case ADD_REQUEST_TO_STACK:
      return {
        ...state,
        requestStack: [...state.requestStack, action.payload],
      };
    case CLEAR_REQUEST_STACK:
      return {
        ...state,
        requestStack: initialState.requestStack,
      };
    default:
      return state;
  }
};

export default reducer;
