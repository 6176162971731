import React, { Suspense, lazy } from "react";
import { useCashInLoyaltyPoints } from "../../api/apiHooks";
import { cashInLoyaltyRequestKeys } from "../../constants";
import Loader from "../../../../components/atoms/Loader";
import { initialValuesTypes } from "../forms/BonusExchangeForm";
import { useTrans } from "../../../../system/translations/hooks";

const BonusExchangeForm = lazy(() => import("../forms/BonusExchangeForm"));

export type ExchangeRateType = {
  loyaltyPoints: number;
  cashAmount: number;
  minAmount: number;
};

export type BonusExchangeModalProps = {
  loyaltyPoint: number;
  exchangeRate: ExchangeRateType;
  currencySign: string;
};

const BonusExchangeModal: React.FC<BonusExchangeModalProps> = ({
  loyaltyPoint,
  exchangeRate,
  currencySign,
}) => {
  const { _t } = useTrans();

  const { isLoading, mutate: cashInLoyaltyHandler } = useCashInLoyaltyPoints();
  const onSubmit = (values: initialValuesTypes) => {
    const formData = new FormData();
    formData.append(
      cashInLoyaltyRequestKeys.convertAmount,
      `${values.convertAmount}`
    );
    cashInLoyaltyHandler(formData);
  };

  return (
    <Suspense fallback={<Loader center fullHeight minHeight={200} />}>
      <div className={"bonus-exchange-info"}>
        <div className={"bonus-exchange-info__item"}>
          <span className={"bonus-exchange-info__value"}>
            {loyaltyPoint} LP
          </span>
          <span className={"bonus-exchange-info__label"}>
            {_t("bonuses_available_for_exchange")}
          </span>
        </div>
        <div className={"bonus-exchange-info__divider"} />
        <div className={"bonus-exchange-info__item"}>
          <span className={"bonus-exchange-info__value"}>
            {`${exchangeRate.loyaltyPoints} LP - ${exchangeRate.cashAmount} ${currencySign}`}
          </span>
          <span className={"bonus-exchange-info__label"}>
            {_t("exchange_rate")}
          </span>
        </div>
      </div>
      <BonusExchangeForm
        loyaltyPoint={loyaltyPoint}
        exchangeRate={exchangeRate}
        currencySign={currencySign}
        onSubmit={onSubmit}
        isLoading={isLoading}
      />
      <div className={"bonus-exchange-footer"}>
        {_t("exchange_form_footer_{minAmount}_message", {
          minAmount: exchangeRate.minAmount,
        })}
      </div>
    </Suspense>
  );
};

export default BonusExchangeModal;
