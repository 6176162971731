export const HEADER_LINKS_URL = (lang) =>
  `/${lang}/menu/combined/desc-header-menu.json`;
export const HEADER_NAV_MOB_LINKS_URL = (lang) =>
  `/${lang}/menu/combined/mobile_bottom_bar.json`;
export const MENU_LINKS_URL = "/:lang/menu/combined/:slug.json";

export type linkItem = {
  classLink: string;
  desktop: boolean;
  mobile: boolean;
  name: string;
  seoDescription: string;
  seoText: string;
  seoTitle: string;
  target: string;
  url: string;
  imageName?: string;
  imageActiveName?: string;
  position?: number;
};

export type seoLinkTypes = {
  seoTitle: string;
  seoDescription: string;
  seoText: string;
  imageUrl: string;
};

export type linksPayloadData = {
  links: linkItem[];
  classMenu: string;
  currentPath: string;
  form: string;
  seoLinkGlobal: seoLinkTypes;
  status: string;
};
