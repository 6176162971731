import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useLoadScript } from "../../system/helpers/loadScriptHook";

type useLiveChatTypes = {
  showChat: boolean;
  supportBtnClick: () => any;
};

export const useLiveChat = (): useLiveChatTypes => {
  const [showChat, setShowChat] = useState(false);
  const LC = window?.LC_API;
  const scriptData = {
    id: "livechat",
    src: "https://cdn.livechatinc.com/tracking.js",
  };

  useLoadScript(
    scriptData,
    () => {
      setShowChat(true);
    },
    6000
  );

  const LCLoaded =
    !!LC && typeof LC?.is_loaded == "function" && LC?.is_loaded();
  useEffect(() => {
    if (LCLoaded) {
      setShowChat(true);
    }
  }, [LCLoaded]);
  const supportBtnClick = () => {
    const LC = window?.LC_API;
    if (LC?.chat_window_maximized) {
      const lcWindowOpen = LC.chat_window_maximized();

      if (lcWindowOpen) {
        LC.minimize_chat_window();
        return;
      }

      isMobile ? LC.open_mobile_window() : LC.open_chat_window();
      return;
    }

    if (window.zendeskChat) {
      window.zendeskChat.activate();
      return;
    }

    if (window.chaport) {
      window.chaport.q("open");
      return;
    }
  };

  return { showChat, supportBtnClick };
};
