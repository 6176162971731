import { addToast } from "./toast";

export const apiErrors = (responseError, responseFormat = "") => {
  const errData = responseError?.error?.data?.systemMessages?.errors;
  if (responseFormat === "notify" && !!errData) {
    errData.forEach((error) =>
      addToast(error.message, {
        type: "error",
      })
    );
  }

  return errData;
};
