import React, { Suspense, lazy } from "react";
import MainTemplate from "../../templates/Main";
import Loader from "../../components/atoms/Loader";
import { _t, makeKey } from "../../system/translations/InjectTransContext";
import { pageTitleKey } from "../../system/helpers/urlHelper";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
const InfoTemplate = lazy(() => import("./InfoTemplate"));

const InfoPage = () => {
  const { id: pageId } = useParams();

  return (
    <>
      <Helmet>
        <title>{_t(makeKey("info_page_title", pageId, pageTitleKey))}</title>
        <meta
          name="description"
          content={_t(makeKey("info_page_description", pageId))}
        />
      </Helmet>
      <MainTemplate>
        <Suspense fallback={<Loader center fullHeight minHeight={1000} />}>
          <InfoTemplate />
        </Suspense>
      </MainTemplate>
    </>
  );
};

export default InfoPage;
