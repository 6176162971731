import React, { FunctionComponent } from "react";
import classNames from "classnames";
import CryptoItem from "../../atoms/CryptoItem";
import { listCrypto } from "./constants";
import { useTrans } from "../../../system/translations/hooks";

const ListCrypto: FunctionComponent = (props) => {
  const { _t } = useTrans();

  return (
    <>
      <div className={"cryptopay-btc__list-head"}>
        <span>{_t("supported_cryptocurrencies")}</span>
      </div>
      <div className={classNames("cryptopay-btc__list-wrap")}>
        {listCrypto.map((item) => {
          return <CryptoItem item={item} />;
        })}
      </div>
    </>
  );
};

export default ListCrypto;
