const config = {
  sportbook: {
    url: "https://bahsegel-sportsbook-prod.gtdevteam.net",
    license: "",
    licenseImage: "",
    platformCode: "BETTECH",
  },
};

export default config;
