import { getQueryParams } from "../../system/helpers/urlHelper";
import { useRouteMatch, useLocation } from "react-router-dom";
import { APP_ROUTES } from "../../system/router/constants";
import { history } from "../../system/store";
import { useShowModal } from "../modals/hooks";
import { resetPasswordModal } from "../modals";
import { useEffect } from "react";

type ResetPasswordRouteParams = {
  email: string;
  confirmationCode: string;
};

export const useCheckResetPassword = () => {
  const location = useLocation();
  const params = getQueryParams(location.search);
  const match = useRouteMatch<ResetPasswordRouteParams>(
    APP_ROUTES.resetPassword
  );
  const beforeClose = () => {
    history.push("/");
  };
  const showResetPasswordModal = useShowModal(resetPasswordModal({
      email: params.get("email"),
      confirmationCode: params.get("confirmationCode"),
      beforeClose,
  }));
  useEffect(() => {
    if (match?.isExact || location.hash === "#reset-password") {
      showResetPasswordModal();
    }
  }, [match, location.hash]);
};
