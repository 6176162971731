import React from "react";
import { isMobile } from "react-device-detect";
import { _t } from "../../system/translations/InjectTransContext";
import { ModalParams } from "../../components/organisms/Modal";
import LoginModal from "../user/components/modals/LoginModal";
import TfaProcessModal from "../user/components/modals/TfaProcessModal";
import checkmark from "../../images/icons/approved_checkmark_modal.svg";
import exclamation from "../../images/icons/warning_exclamation_modal.svg";
import AfterLoginModal from "../user/components/modals/AfterLoginModal";
import NotificationBlock from "../../components/molecules/NotificationBlock";
import RegModal from "../user/components/modals/RegModal";
import ChangePassword from "../user/components/ChangePassword";
import ForgotPasswordModal from "../user/components/modals/ForgotPasswordModal";
import ResetPasswordModal from "../user/components/modals/ResetPasswordModal";
import FillOutProfileModal from "../user/components/modals/FillOutProfileModal";
import PwaAddToHomeModal from "../landing/components/modals/PwaAddToHomeModal";
import PwaQrModal from "../landing/components/modals/PwaQrModal";
import PwaPromoModal from "../pwa/components/PwaPromoModal";
import ConfirmModal from "../../components/molecules/ConfirmModal";
import DepositModal from "../cashbox/components/modals/DepositModal";
import SignUpModalBanner from "../banners/components/SignUpModalBanner";
import SuccessModal from "../user/components/modals/SuccessModal";
import DescriptionModal from "../../components/organisms/Modal/DescriptionModal";
import ComplatedPaymentModal from "../../components/molecules/ComplatedPaymentModal";
import DisablePopupBlockerModal from "../../components/molecules/DisablePopupBlockerModal";
import {
  AlreadyHaveAnAccountModalLink,
  ForgotYourPasswordModalLink,
} from "./links";
import ProcessModal from "../cashbox/components/modals/ProcessModal";
import OldSiteInfoModal from "../../components/organisms/Modal/OldSiteInfoModal";
import BonusExchangeModal from "../user/components/modals/BonusExchangeModal";

export const forgotPasswordModal: ModalParams = {
  className: "forgot-password-modal",
  component: ForgotPasswordModal,
};

export const forgotPasswordModalSuccess = ({ handleClick }): ModalParams => ({
  className: "forgot-password-modal",
  component: SuccessModal,
  componentProps: {
    modalClassName: "forgot-password-modal",
    title: _t("modal_forgot_password_reset_link_send"),
    content: _t("modal_forgot_password_content"),
    btnLabel: _t("modal_forgot_password_success_ok_btn"),
    btnType: "transparent-bordered",
    handleClick: handleClick,
  },
  modalSize: "sm",
});

export const resetPasswordModal = ({
  email,
  confirmationCode,
  beforeClose,
}): ModalParams => ({
  title: "modal_reset_password_title",
  desc: "",
  component: ResetPasswordModal,
  beforeClose: beforeClose,
  componentProps: {
    email,
    confirmationCode,
  },
});

export const resetPasswordSuccessModal = ({ handleClick }): ModalParams => ({
  className: "reset-password-success-modal",
  component: SuccessModal,
  componentProps: {
    modalClassName: "reset-password-success-modal",
    title: _t("modal_reset_success"),
    content: _t("modal_reset_success_content"),
    btnLabel: _t("modal_reset_success_btn"),
    btnType: "transparent-bordered",
    handleClick: handleClick,
  },
  modalSize: "sm",
});

export const afterLoginModal: ModalParams = {
  className: "after-login-modal",
  component: AfterLoginModal,
  modalSize: "lg",
};

export const regModalWithRedirect = ({ redirectTo }): ModalParams => ({
  ...regModal,
  componentProps: { redirectTo },
});

export const loginModalWithRedirect = (params: {}): ModalParams => ({
  ...loginModal,
  componentProps: {
    ...params,
  },
});

export const changePasswordModal: ModalParams = {
  className: "change-password-modal",
  title: "modal_change_password",
  component: ChangePassword,
};

export const changePasswordModalSuccess = ({ handleClick }): ModalParams => ({
  className: "change-password-modal",
  component: SuccessModal,
  componentProps: {
    modalClassName: "change-password-modal",
    title: _t("change_password_form_success_password_updated"),
    content: _t("change_password_form_success_content"),
    btnLabel: _t("change_password_form_success_ok_btn"),
    btnType: "transparent-bordered",
    handleClick: handleClick,
  },
  modalSize: "sm",
});

export const loginModal = (params = {}): ModalParams => ({
  className: "login-modal",
  title: "modal_login_title",
  desc: "login_modal_welcome",
  component: LoginModal,
  modalFooter: <ForgotYourPasswordModalLink />,
  componentProps: {
    ...params,
  },
});

export const tfaProcessModal: ModalParams = {
  className: "tfa-process-modal",
  title: "modal_tfa_process_title",
  desc: "modal_tfa_process_desc",
  component: TfaProcessModal,
};

export const regModal: ModalParams = {
  title: "modal_registration_title",
  className: "sign-up-modal",
  component: RegModal,
  showBanner: true,
  bannerPosition: isMobile ? "top" : "left",
  bannerComponent: <SignUpModalBanner />,
  modalSize: "lg",
  modalFooter: <AlreadyHaveAnAccountModalLink />,
};

export const pwaInstallError: ModalParams = {
  className: "notification-modal",
  component: NotificationBlock,
  componentProps: {
    icon: exclamation,
    titleMod: "yellow",
    titleKey: "install_pwa_error_title",
    textKey: "install_pwa_error_text",
    italicKey: "install_pwa_error_bottom_text",
    btnLabelKey: "install_pwa_error_btn_text",
    btnType: "primary",
  },
};

export const paymentCompletedModal = (params = {}): ModalParams => {
  return {
    className: "notification-modal",
    component: ComplatedPaymentModal,
    componentProps: {
      modalSize: "sm",
      ...params,
    },
  };
};

export const processIframeModal = (params = {}): ModalParams => {
  return {
    modalSize: "md",
    className: "process-modal",
    component: ProcessModal,
    componentProps: params,
  };
};

export const depositSuccessfulModal: ModalParams = {
  className: "notification-modal",
  component: NotificationBlock,
  componentProps: {
    icon: checkmark,
    titleKey: "deposit_successful",
    textKey: "transaction_successfully_completed",
    textMod: "dim",
    btnLabelKey: "deposit_successful_btn_ok",
    btnType: "primary",
    btnBlock: true,
    //todo: link: "casino" ?
  },
};

export const depositCompletedModal = (params = {}): ModalParams => ({
  className: "notification-modal",
  component: ComplatedPaymentModal,
  componentProps: {
    modalSize: "sm",
    ...params,
  },
});
export const withdrawalSuccessfulModal: ModalParams = {
  className: "notification-modal",
  component: NotificationBlock,
  componentProps: {
    icon: checkmark,
    titleKey: "withdrawal_successful_title",
    textKey: "withdrawal_successfully_completed",
    textMod: "dim",
    btnLabelKey: "withdrawal_successful_btn_ok",
    btnType: "primary",
    btnBlock: true,
  },
};

export const confirmModal = ({
  title,
  desc,
  onConfirm,
  onCancel,
}): ModalParams => ({
  className: "confirmation-modal",
  component: ConfirmModal,
  componentProps: {
    title,
    desc,
    onConfirm,
    onCancel,
  },
});

export const depositModal: ModalParams = {
  modalSize: "lg",
  className: "deposit-modal",
  title: "modal_deposit_title",
  component: DepositModal,
};

export const fillOutProfileModal: ModalParams = {
  className: "fill-out-profile-modal",
  component: FillOutProfileModal,
  componentProps: {},
  modalSize: "sm",
};

export const fillOutProfileModalSuccess = ({ handleClick }): ModalParams => ({
  className: "fill-out-profile-modal",
  component: SuccessModal,
  componentProps: {
    modalClassName: "fill-out-profile-modal",
    title: _t("modal_fill_out_profile_profile_completed"),
    content: _t("modal_fill_out_profile_info_saved"),
    btnLabel: _t("modal_fill_out_profile_make_deposit_btn"),
    btnType: "action",
    handleClick: handleClick,
  },
  modalSize: "md",
});

export const pwaAddToHomeModal: ModalParams = {
  className: "pwa-add-to-home-modal",
  component: PwaAddToHomeModal,
};

export const pwaQrModal = (qrValue: string): ModalParams => ({
  className: "pwa-qr-modal",
  title: "scan_qr",
  desc: "use_camera",
  component: PwaQrModal,
  componentProps: { qrValue },
});

export const pwaPromoModal: ModalParams = {
  className: "pwa-promo-modal",
  component: PwaPromoModal,
};

export const descriptionModal = ({
  className,
  img,
  description,
  linkTo,
  btnText,
  handleClick,
  title,
  modalSize,
  handleOnClose,
}: ModalParams & {
  img?: string;
  description?: string;
  linkTo?: string;
  btnText?: string;
  handleClick?: () => any;
}): ModalParams => ({
  className: className,
  component: DescriptionModal,
  componentProps: {
    img: img,
    title: title,
    text: description,
    linkTo: linkTo,
    btnText: btnText,
    handleClick: handleClick,
  },
  beforeClose: handleOnClose,
  modalSize: modalSize || "md",
});

export const oldSiteInfoModal: ModalParams = {
  className: "old-site-info-modal",
  modalSize: "sm",
  component: OldSiteInfoModal,
};

export const disablePopupBlockerModal = ({ link }): ModalParams => ({
  className: "disable-popup-blocker-modal",
  component: DisablePopupBlockerModal,
  componentProps: {
    link,
  },
});

export const bonusExchangeModal = ({
  loyaltyPoint,
  exchangeRate,
  currencySign,
}): ModalParams => ({
  modalSize: "sm",
  className: "bonus-exchange-modal",
  title: "modal_bonus_exchange_title",
  component: BonusExchangeModal,
  componentProps: {
    loyaltyPoint,
    exchangeRate,
    currencySign,
  },
});
