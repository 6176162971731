import { useQuery } from "react-query";
import { getLanguage } from "../../../system/translations/helper";
import {
  SPORT_LINKS,
  sportLinksPayloadData,
  SPORT_LINKS_URL,
} from "../constants";
import { useApiRequest } from "../../../system/hooks/useApiRequest";
import { generatePath } from "react-router";

export const useGetSportLinks = () => {
  const [apiRequest] = useApiRequest();
  const lang = getLanguage();

  return useQuery(
    [SPORT_LINKS, lang],
    (): sportLinksPayloadData =>
      apiRequest({
        type: "GET_SPORT_LINKS",
        request: {
          url: generatePath(SPORT_LINKS_URL, { lang }),
          method: "GET",
        },
      })
  );
};
