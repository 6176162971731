export const GAMES_PER_STEP = 20;
export const SLIDER_GAMES_LIMIT = 20;
export const SEARCH_MIN_CHAR_LENGTH = 2;
export const SEARCH_DEBOUNCE_DELAY = 300;
export const ALL_GAMES_SLUG = "all";
export const FAVORITES_SLUG = "favorites";
export const RECOMMENDED_SLUG = "recommended";

export const GamesList = "games";
export const MostPopularGamesList = "most-popular-games";
export const SearchGamesList = "search_games";
export const ProvidersList = "providers";
export const CategoriesList = "categories";
export const GametagCategoriesList = "gametag_categories";
export const TagGamesList = "tag_games";
export const RecentlyPlayedGamesList = "recently_played_games";
export const LandingGameProviders = "landing_game_providers";
export const RecommendedGames = "recommended_games";

export const GET_GAMES = "GET_GAMES";
export const GET_MORE_POPULAR_GAMES = "GET_MORE_POPULAR_GAMES";
export const GET_PROVIDERS = "GET_PROVIDERS";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_SEARCH_GAMES = "GET_SEARCH_GAMES";
export const GET_GAMETAG_CATEGORIES = "GET_GAMETAG_CATEGORIES";
export const GET_TAG_GAMES = "GET_TAG_GAMES";
export const GET_RECENTLY_PLAYED_GAMES = "GET_RECENTLY_PLAYED_GAMES";
export const GET_LANDING_GAME_PROVIDERS = "GET_LANDING_GAME_PROVIDERS";
export const RECOMMENDED_GAMES = "RECOMMENDED_GAMES";

export const GET_GAMES_URL =
  "/api/v3/:lang/games/by/category/:categorySlug.json?filter[limit]=:limit&filter[offset]=:offset&filter[country]=:country";
export const GET_MOST_POPULAR_GAMES_URL =
  "/:lang/casino/stat/hottest?size=:size&days=:days";
export const GET_SEARCH_GAMES_URL =
  "/:lang/games/search.json?filter[country]=:country&filter[name]=:name&filter[limit]=9999";
export const GET_GAMETAG_CATEGORIES_URL = "/:lang/casino/gametag/categories";
export const GET_TAG_GAMES_URL = "/:lang/games/tag/:gameTag.json";
export const GET_PROVIDERS_URL = "/:lang/casino/category-platforms/list.json";
export const GET_CATEGORIES_URL = "/:lang/games/category/list.json";
export const GET_RECENTLY_PLAYED_GAMES_URL =
  "/:lang/profile/recently-played.json";
export const GET_LANDING_GAME_PROVIDERS_URL =
  "/:lang/casino/platforms/list.json";
export const GET_RECOMMENDED_GAMES = "/:lang/games/recommended.json";

export type gameItem = {
  desktop: number;
  desktopGameId?: string;
  desktopGameIdentifierCore?: string;
  desktopIdentifier: string;
  gameIdentifierCore?: string;
  gameTags: [];
  imageBackgroundUrl: string;
  imagePlatformUrl: string;
  imageUrl: string;
  jackpotAmount: string;
  mobile: number;
  mobileGameId?: string;
  mobileIdentifier: string;
  name: string;
  platformCode: string;
  platformName: string;
  playForFunText: string;
  playForFunUrl: string;
  playForFunUrlMobile: string;
  playForRealText: string;
  playForRealUrl: string;
  playForRealUrlMobile: string;
  popularIndex: number;
  ratio: "wide" | "square";
  seoDescription: string;
  seoText: string;
  showPlatformImage: number;
  status: string;
  v3: {
    links: {
      real: string;
      fun: string;
      template: string;
    };
    game: {
      slug: string;
      platform: {
        code: string;
        name: string;
        gameLaunchPlatformCode: string;
        filterPlatformCode: string;
      };
    };
  };
  zingRecommendationId: string | null;
  zingScore: number | null;
};

export type providerItem = {
  name: string;
  code: string;
  imageName: string;
  providerCode: string;
  gamesCount: number;
  position: number;
};

export type categoryItem = {
  feedUrl: string;
  pageUrl: string;
  image: {
    normal: string;
    active: string;
  };
  slug: string;
  name: string;
};

export type gameTagItem = {
  id: string | number;
  name: string;
  slug: string;
  position: string | number;
  imageName: string;
};

export type gameTagCategory = {
  id: string | number;
  name: string;
  slug: string;
  position: string | number;
  imageName: string;
  gameTags: gameTagItem[];
};

export type gameTagCategoriesPayloadData = {
  gameTagCategories: gameTagCategory[];
  form: string;
  seoLinkGlobal: seoLinkTypes;
  status: string;
};

export type seoLinkTypes = {
  seoTitle: string;
  seoDescription: string;
  seoText: string;
  imageUrl: string;
};

export type gamesPayloadData = {
  casino: gameItem[];
  form: string;
  seoLinkGlobal: seoLinkTypes;
  status: string;
};

export type categoryGamesPayloadData = {
  category: string;
  platforms: providerItem[];
  games: gameItem[];
  form: string;
  seoLinkGlobal: seoLinkTypes;
  status: string;
};

export type categoryMorePopularGamesPayloadData = {
  platforms: providerItem[];
  hottestGames: gameItem[];
  form: string;
  seoLinkGlobal: seoLinkTypes;
  status: string;
};

export type profileGamesPayloadData = {
  games: gameItem[];
  form: string;
  seoLinkGlobal: seoLinkTypes;
  status: string;
};

export type providersPayloadData = {
  platforms: providerItem[];
  form: string;
  seoLinkGlobal: seoLinkTypes;
  status: string;
};

export type categoriesPayloadData = {
  categories: categoryItem[];
  form: string;
  seoLinkGlobal: seoLinkTypes;
  status: string;
};

export type RouteParams = {
  lang?: string;
  categorySlug?: string;
  slug?: string;
  gameTagSlug?: string;
};

export type GameProviderType = {
  name: string;
  code: string;
  imageName: string;
  providerCode: string;
  gamesCount: number;
};

export type landingGameProvidersData = {
  platforms: GameProviderType[];
};
