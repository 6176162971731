import React, { Suspense, lazy } from "react";
import "normalize.css";
import "react-toastify/dist/ReactToastify.css";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import configureStore from "./system/store";
import routes from "./system/router/routes";
import { history } from "./system/store";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query-devtools";
import "./styles/main.scss";
import Loader from "./components/atoms/Loader";
import { useDetectDomain } from "./system/hooks/useDetectDomain";
const SystemTemplate = lazy(() => import("./templates/System"));

const store = configureStore();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      staleTime: 10 * 60 * 1000,
    },
  },
});

const App: React.FC = () => {
  const [isLoading] = useDetectDomain();
  if (isLoading) {
    return <Loader withImg center fullHeight />;
  }

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ConnectedRouter history={history}>
          <Suspense fallback={<Loader center withImg fullHeight />}>
            <SystemTemplate>{routes}</SystemTemplate>
          </Suspense>
        </ConnectedRouter>
      </QueryClientProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </Provider>
  );
};

export default App;
