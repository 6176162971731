import { gameItem } from "../casino/constants";
import { gameLink } from "../casino/helpers";
import { PROVIDERS_REAL_ONLY } from "./constants";

export const hasFun = (game: gameItem) => {
  return (
    game &&
    !!gameLink(game).fun &&
    !PROVIDERS_REAL_ONLY.includes(game?.v3?.game?.platform?.code)
  );
};

export const gameResizer = (
  portWidth: number,
  portHeight: number,
  gameRatio: number
) => {
  const resultSize = { height: null, width: null };
  if (portWidth / portHeight >= gameRatio) {
    // if port ratio is larger than game ratio, height of game port will be equal to port height
    resultSize.height = portHeight;
    resultSize.width = resultSize.height * gameRatio;
    return resultSize;
  }

  // if port ratio is smaller than game ratio, width of game port will be equal to port width
  resultSize.width = portWidth;
  resultSize.height = resultSize.width / gameRatio;
  return resultSize;
};
